import React, { forwardRef, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import styled from 'styled-components';
import axios from 'axios';
import { config } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Calendar from '../assets/calendar-gray.png';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Pagination from '../components/Pagination/Pagination';
import "react-datepicker/dist/react-datepicker.css";
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import { format_currency, validate_session } from '../utils';
import CloseModal from '../assets/close-modal.png';
import Modal from '../components/Modal';
import GoogleMap from '../components/Maps';

const TimeTracking = (props) => {
    const module = 'Time Tracking';
    const [beaches, setBeaches] = useState([]);
    const [timetracking, setTimetracking] = useState([]);
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(7, 'days')), new Date()]);
    const [startDate, endDate] = dateRange;
    const [beach, setBeach] = useState([]);
    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);
    const [show, setShow] = useState(false);
    const [location, setLocation] = useState({});
    const [collects, setCollects] = useState([]);

    const handlerModal = () => setShow(!show);

    const [modalCollect, setModalCollect] = useState(false);
    const handlerModalCollect = () => setModalCollect(!modalCollect);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(timetracking.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, timetracking, PageSize]);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    // const refreshData = async () => {
    //     try{
    //         const response = await axios.get(`${config.URI}/time-tracking`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
    //         if(response.statusText === 'OK'){
    //             setTimetracking(response.data.data);
    //             setReply(response.data.data);
    //         }
    //     }catch(e){
    //         console.log(e);
    //         messageError();
    //     }
    // }

    const refreshBeaches = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        //refreshData();
        refreshBeaches();
    },[])

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(timetracking.length){
                timetracking.map(tt => {
                    data.push({ 'USER': tt.user.name, 'BEACH': tt.user.beach ? tt.user.beach.name : tt.user.beaches && tt.user.beaches.length > 0 ? tt.user.beaches.map(b => b.name).join(', ') : '', 'DATE': moment(tt.timetracking.dateOpen).format('MMM DD, YYYY'), 'TIME': `${moment(tt.timetracking.dateOpen).format('hh:mm A')} - ${moment(tt.timetracking.dateClose).format('hh:mm A')}`, 'DURATION': convert_time_human(tt.timetracking.dateOpen, tt.timetracking.dateClose), 'SALES': format_currency(tt.sales), 'COMMISSION': `${tt.user.beach && tt.user.beach.commissions === 'General' ? tt.user.beach.percentage : tt.user.commission}% ${tt.user.beach ? '- '+tt.user.beach.commissions : ''}`, 'SALARY': format_currency(tt.salary), 'TOTAL': format_currency((tt.commission + tt.salary)), 'AV. PER HOUR': format_currency((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))) });
                });
            }
            data.push({ 'USER': '', 'BEACH': '', 'DATE': '', 'TIME': 'TOTAL', 'DURATION': sum_total_hours(timetracking), 'SALES': format_currency(timetracking.reduce((reduce, tt) => reduce + tt.sales, 0)), 'COMMISSION': format_currency(timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)), 'SALARY': format_currency(timetracking.reduce((reduce, tt) => reduce + tt.salary, 0)), 'TOTAL': format_currency(timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)), 'AV. PER HOUR': format_currency(timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0)) })
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Time Tracking PDF";
            const headers = [['USER', 'BEACH', 'DATE', 'TIME', 'DURATION', 'SALES', 'COMMISSION', 'SALARY', 'TOTAL', 'AV. PER HOUR']];
        
            const data = timetracking.map(tt => [tt.user.name, tt.user.beach ? tt.user.beach.name : tt.user.beaches && tt.user.beaches.length > 0 ? tt.user.beaches.map(b => b.name).join(', ') : '', moment(tt.timetracking.dateOpen).format('MMM DD, YYYY'), `${moment(tt.timetracking.dateOpen).format('hh:mm A')} - ${moment(tt.timetracking.dateClose).format('hh:mm A')}`, convert_time_human(tt.timetracking.dateOpen, tt.timetracking.dateClose), format_currency(tt.sales), `${tt.user.beach && tt.user.beach.commissions === 'General' ? tt.user.beach.percentage : tt.user.commission}% ${tt.user.beach ? '- '+tt.user.beach.commissions : ''}`, format_currency(tt.salary), format_currency((tt.commission + tt.salary)), format_currency((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose)))]);
        
            data.push(['', '', '', 'TOTAL', sum_total_hours(timetracking), format_currency(timetracking.reduce((reduce, tt) => reduce + tt.sales, 0)), format_currency(timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)), format_currency(timetracking.reduce((reduce, tt) => reduce + tt.salary, 0)), format_currency(timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)), format_currency(timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0))]);
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("time-tracking-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `time-tracking-estebans-place` + fileExtension);
    };

    const DatePickerInputRange = forwardRef(({ value, onClick }, ref) => (
    <>
        <Form.Control onClick={onClick} value={value ? `${moment(moment(value.split('-')[0].replace(' ', '')).isValid() ? value.split('-')[0].replace(' ', '') : new Date() ).format('DD MMM')} - ${moment(moment(value.split('-')[1].replace(' ', '')).isValid() ? value.split('-')[1].replace(' ', '') : new Date() ).format('DD MMM YYYY')}` : ''} ref={ref} style={{fontSize: 14.4, fontFamily: 'Roboto', color: '#1A1A1A', borderColor: '#BDBDBD', borderRadius: 10, height: 40, width: 200}} />
        <img src={Calendar} style={{ width: 18, height: 20, position: 'absolute', right: 12, top: 9 }} />
    </>
    ));

    const convert_time_human = (open, close) => {
        const end = moment(close).format();
        const start = moment(open).format();
        const duration = moment.duration(moment(end).diff(start));
        const seconds = duration.asSeconds();
        const h = Math.floor(seconds / 3600);
        const m = Math.floor(seconds % 3600 / 60);

        const hDisplay = h > 0 ? h + (h == 1 ? " Hour - " : " Hours - ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " Minute" : " Minutes") : "";
        return (mDisplay === '' ? hDisplay : hDisplay) + mDisplay;
    }

    const time_number_format = (open, close) => {
        const end = moment(close).format();
        const start = moment(open).format();
        const duration = moment.duration(moment(end).diff(start));
        const minutes = duration.asMinutes();
        return (minutes/60);
    }

    const sum_total_hours = (times = []) => {
        let seconds = 0;
        for(let i = 0; i < times.length; i++){
            const end = moment(times[i].timetracking.dateClose).format();
            const start = moment(times[i].timetracking.dateOpen).format();
            const duration = moment.duration(moment(end).diff(start));
            seconds += duration.asSeconds();
        }
        const h = Math.floor(seconds / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const hDisplay = h > 0 ? h + (h == 1 ? " Hour" : " Hours") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " Minute" : " Minutes") : "";
        return (mDisplay === '' ? hDisplay : hDisplay + ' - ') + mDisplay;
    }

    useEffect(() => {
        filterBackend(beach, dateRange, search)
    },[beach, dateRange, search])

    const filterBackend = async (beach, daterange, name) => {
        console.log(beach, daterange, name)
        try{
            const response = await axios.get(`${config.URI}/time-tracking?date1=${daterange[0]}&date2=${daterange[1]}&beach=${beach}&name=${name}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                console.log(response.data.data)
                setTimetracking(response.data.data);
                setReply(response.data.data);
                setCurrentPage(1);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    // useEffect(() => {
    //     if(beach.length > 0){
    //         let tt_filter = [];
    //         if(search.length > 0){
    //             tt_filter = timetracking.filter(item => (beach.indexOf(item.user.beach && item.user.beach._id) !== -1) || ((item.user.beaches && item.user.beaches.length > 0) && item.user.beaches.filter(b => beach.indexOf(b._id) !== -1).length > 0));
    //         }else{
    //             tt_filter = reply.filter(item => (beach.indexOf(item.user.beach && item.user.beach._id) !== -1) || ((item.user.beaches && item.user.beaches.length > 0) && item.user.beaches.filter(b => beach.indexOf(b._id) !== -1).length > 0));
    //         }
    //         setTimetracking(tt_filter);
    //         setCurrentPage(1);
    //     }else{
    //         if(beach.length > 0 && (dateRange.length >= 2 || search.length > 0)){}else{
    //             refreshData();
    //         }
    //     }
    // },[beach]);

    // useEffect(() => {
    //     if(dateRange.length === 2 && reply.length){
    //         filter_date(reply);
    //     }else{
    //         if(dateRange.length >= 2 || search.length > 0 || beach.length > 0){}else{
    //             refreshData();
    //         }
    //     }
    // },[dateRange]);

    // const filter_date = (reply) => {
    //     const start = dateRange[0];
    //     const end = dateRange[1];
    //     let tt_filter = [];
    //     if((dateRange.length >= 2 && (search.length > 1 || beach.length > 0)) && timetracking.length > 0){
    //         tt_filter = timetracking.filter(item => (new Date(item.timetracking.dateOpen).getTime() >= new Date(start).getTime() &&  new Date(item.timetracking.dateOpen).getTime() <= new Date(end).getTime()) || (moment(item.timetracking.dateOpen, 'YYYY-MM-DD').isSame(moment(start, 'YYYY-MM-DD')) || moment(item.timetracking.dateOpen, 'YYYY-MM-DD').isSame(moment(end, 'YYYY-MM-DD'))));
    //     }else{
    //         tt_filter = reply.filter(item => (new Date(item.timetracking.dateOpen).getTime() >= new Date(start).getTime() &&  new Date(item.timetracking.dateOpen).getTime() <= new Date(end).getTime()) || (moment(item.timetracking.dateOpen, 'YYYY-MM-DD').isSame(moment(start, 'YYYY-MM-DD')) || moment(item.timetracking.dateOpen, 'YYYY-MM-DD').isSame(moment(end, 'YYYY-MM-DD'))));
    //     }
    //     setTimetracking(tt_filter);
    //     setCurrentPage(1);
    // }

    // const onSearch = (value) => {
    //     setSearch(value);
    //     if(value !== ''){
    //         let tt_search = [];
    //         if(beach.length > 0){
    //             tt_search = timetracking.filter(item => item.user.name.toLowerCase().search(value.toLowerCase()) !== -1);
    //         }else{
    //             tt_search = reply.filter(item => item.user.name.toLowerCase().search(value.toLowerCase()) !== -1);
    //         }
    //         setTimetracking(tt_search);
    //         setCurrentPage(1);
    //     }else{
    //         if(value !== '' && (dateRange.length == 2 || beach.length > 0)){}else{
    //             refreshData();
    //         }
    //     }
    // }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    return(
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Time Tracking</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => setSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                    <div className="d-flex justify-content-between" style={{marginLeft: 10}}>
                        <div style={{width: 200, marginRight: 10}}>
                            <Select options={beaches ? beaches.map(b => { return { value: b._id, label: b.name } }) : []} onChange={(tags) => setBeach(tags.map(t => t.value))} placeholder="All Beaches" isMulti closeMenuOnSelect={false} isClearable={false} styles={{ control: (styles) => ({ ...styles, minHeight: 40, borderRadius: 10, marginRight: 5, width: 200 }), multiValue: (styles) => ({ ...styles, color: '#fff', background: '#4FB4E6', borderRadius: 10, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }), multiValueLabel: (styles) => ({ ...styles, fontSize: 9, color: '#fff', borderRadius: 10 }) }} />
                        </div>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            customInput={<DatePickerInputRange />}
                        />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'User'}, {name: 'Beach'}, {name: 'Date'}, {name: 'Time'}, {name: 'Duration'}, {name: 'Sales'}, {name: 'Remove cash'}, {name: 'Commission'}, {name: 'Salary'}, {name: 'Total'}, {name: 'Av. Per Hour'}, { name: 'Actions'}]} />
                        {timetracking.length > 0 && (
                            <>
                                {currentTableData.map((tt, i) => {
                                    return (
                                        <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                            <Column className="rowBorderStart">{tt.user.name}</Column>
                                            <Column className="rowBorderMiddle">{tt.user.beach ? tt.user.beach.name : tt.user.beaches && tt.user.beaches.length > 0 ? tt.user.beaches.map(b => b.name).join(', ') : '' }</Column>
                                            <Column className="rowBorderMiddle">{moment(tt.timetracking.dateOpen).format('MMM DD, YYYY')}</Column>
                                            <Column className="rowBorderMiddle">{`${moment(tt.timetracking.dateOpen).format('hh:mm A')} - ${moment(tt.timetracking.dateClose).format('hh:mm A')}`}</Column>
                                            <Column className="rowBorderMiddle">{convert_time_human(tt.timetracking.dateOpen, tt.timetracking.dateClose) ? convert_time_human(tt.timetracking.dateOpen, tt.timetracking.dateClose) : '0'}</Column>
                                            <Column className="rowBorderMiddle">{format_currency(tt.sales)}</Column>
                                            <Column className='rowBorderMiddle'><TextAction onClick={() => {if(tt.collect.length > 0){setCollects(tt.collect);handlerModalCollect()}}}>{tt.collect.length > 0 ? format_currency(tt.collect.reduce((p, c) => p + c.value, 0)) : '$0.00'}</TextAction></Column>
                                            <Column className="rowBorderMiddle">{format_currency(tt.commission)} ({tt.user.beach && tt.user.beach.commissions === 'General' ? tt.user.beach.percentage : tt.user.commission}% {tt.user.beach ? '- '+tt.user.beach.commissions : ''})</Column>
                                            <Column className="rowBorderMiddle">{format_currency(tt.salary)}</Column>
                                            <Column className="rowBorderMiddle">{format_currency((tt.commission + tt.salary))}</Column>
                                            <Column className="rowBorderMiddle">{format_currency((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose)))}</Column>
                                            <Column className="rowBorderEnd">
                                                <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                    <TextAction onClick={() => {setLocation(tt.timetracking.location);handlerModal();}}>View Location&nbsp;</TextAction>
                                                </div>
                                            </Column>
                                        </ContentBody>
                                    )
                                })}
                            </>
                        )}
                    </Table>
                    {timetracking.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {timetracking.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={timetracking.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                    <Table className="table-curved">
                        <ContentBody key={1} backgroundColor={'#1A1A1A'} className="text-center">
                            <Column className="rowBorderStart" style={{minWidth: 140}}></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFEB11', fontWeight: 'bold'}}></Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>DURATION</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>SALES</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>COMMISSION</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>SALARY</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>TOTAL</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>AV. PER HOUR</Column>
                            <Column className="rowBorderEnd" style={{minWidth: 40}}></Column>
                        </ContentBody>
                        <ContentBody key={1} backgroundColor={'#1A1A1A'} className="text-center">
                            <Column className="rowBorderStart" style={{minWidth: 140}}></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFEB11', fontWeight: 'bold'}}>TOTAL</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{sum_total_hours(timetracking) ? sum_total_hours(timetracking) : '0.00'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(timetracking.reduce((reduce, tt) => reduce + tt.sales, 0)) ? format_currency(timetracking.reduce((reduce, tt) => reduce + tt.sales, 0)) : '0.00'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)) ? format_currency(timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)) : '0.00'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(timetracking.reduce((reduce, tt) => reduce + tt.salary, 0)) ? format_currency(timetracking.reduce((reduce, tt) => reduce + tt.salary, 0)) : '0.00'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)) ? format_currency(timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)) : '0.00'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0)) ? format_currency(timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0)) : '0.00'}</Column>
                            <Column className="rowBorderEnd" style={{minWidth: 40}}></Column>
                        </ContentBody>
                        <ContentBody key={1} backgroundColor={'#FFF'} className="text-center">
                            <Column className="rowBorderStart" style={{minWidth: 140}}></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle"></Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A', fontWeight: 'bold'}}>AVERAGE</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{Math.round(timetracking.reduce((reduce, tt) => reduce + time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose), 0) / timetracking.length) ? Math.round(timetracking.reduce((reduce, tt) => reduce + time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose), 0) / timetracking.length) : '0'} Hours Per Session</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{format_currency((timetracking.reduce((reduce, tt) => reduce + tt.sales, 0) / timetracking.length)) ? format_currency((timetracking.reduce((reduce, tt) => reduce + tt.sales, 0) / timetracking.length)) : '0'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{format_currency((timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)  / timetracking.length)) ? format_currency((timetracking.reduce((reduce, tt) => reduce + tt.commission, 0)  / timetracking.length)) : '0'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{format_currency((timetracking.reduce((reduce, tt) => reduce + tt.salary, 0)  / timetracking.length))}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{format_currency((timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)  / timetracking.length)) ? format_currency((timetracking.reduce((reduce, tt) => reduce + (tt.commission + tt.salary), 0)  / timetracking.length)) : '0'}</Column>
                            <Column className="rowBorderMiddle" style={{color: '#1A1A1A'}}>{format_currency((timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0)  / timetracking.length)) ? format_currency((timetracking.reduce((reduce, tt) => reduce + ((tt.sales / time_number_format(tt.timetracking.dateOpen, tt.timetracking.dateClose))), 0)  / timetracking.length)) : '0'}</Column>
                            <Column className="rowBorderEnd" style={{minWidth: 40}}></Column>
                        </ContentBody>
                    </Table>
                </div>
            </div>
            <Modal show={show} onHide={handlerModal} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => handlerModal()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 60 }}>
                    <GoogleMap nameMarket={location.full_address ? location.full_address : ''} latitude={location.latitude ? location.latitude : 0} longitude={location.longitude ? location.longitude : 0} />
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => handlerModal()} width={'149px'}>Close</Button>
                    </div>
                </div>
            </Modal>

            <Modal show={modalCollect} onHide={handlerModalCollect} dialogClassName="modal-620w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => handlerModalCollect()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 60, marginLeft: 10, marginRight: 10 }}>
                    <div className="table-responsive">
                        <Table className="table-curved">
                            <HeaderTable header={[{name: 'Date & Time'}, {name: 'Admin'}, {name: 'Beach Attendant'}, {name: 'Amount'}]} />
                            {collects.map((c, i) => (
                                <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                    <Column className="rowBorderStart">{moment(c.date).format('YYYY-MM-DD hh:mm a')}</Column>
                                    <Column className="rowBorderMiddle">{c.admin.name}</Column>
                                    <Column className="rowBorderMiddle">{c.user.name}</Column>
                                    <Column className="rowBorderEnd">{format_currency(c.value)}</Column>
                                </ContentBody>
                            ))}
                        </Table>
                    </div>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => handlerModalCollect()} width={'149px'}>Close</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 10px;
margin-bottom: 18px;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 240px;
margin-left: 10px;
padding-left: 35px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(TimeTracking);