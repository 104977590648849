import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';

const Auth = (props) => {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if(prop.screen === '/sales'){
                return (
                    <Route 
                        path={prop.screen + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }else{
                return null;
            }
        });
    }

    return (
        <>
        {/** aqui van los componentes de header o footer */}
            <Switch>
                {getRoutes(routes)}
            </Switch>
        </>
    )
}

export default Auth;