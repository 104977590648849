import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import axios from 'axios';
import Modal from '../components/Modal';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { config } from '../config';
import { ExcelRenderer } from 'react-excel-renderer';
import CloseModal from '../assets/close-modal.png';
import Dropzone from '../assets/upload-icon-white.png';
import { useDropzone } from 'react-dropzone';
import { Link, useParams } from "react-router-dom";
import DownloadIcon from '../assets/download-icon.png';
import moment from 'moment';
import { upload, validate_session } from '../utils';
import Pagination from '../components/Pagination/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faEye } from '@fortawesome/free-solid-svg-icons';

const PartnershipsUpload = (props) => {
    const module = 'Partnerships';
    const params = useParams();
    const [partner, setParnert] = useState({});
    const [activeGuests, setActiveGuests] = useState([]);
    const [file, setFile] = useState({});
    const [showDelete, setShowDelete] = useState(false);
    const [id, setId] = useState('');

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    useEffect(() => {
        validate_session(props.token);
    },[])

    const onShowDelete = () => setShowDelete(!showDelete);

    const [document, setDocument] = useState(true);

    const [importError, setImportError] = useState(0);
    const [importSuccess, setImportSuccess] = useState(0);
    const [confirmGuests, setConfirmGuests] = useState([]);

    const [filterError, setFilterError] = useState(false);
    const [filterSuccess, setFilterSuccess] = useState(false);
    const [sizeFile, setSizeFile] = useState(0);

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(activeGuests.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, activeGuests, PageSize]);

    const [PageSizeXlS, setPageSizeXlS] = useState(10);
    const [currentPageXlS, setCurrentPageXlS] = useState(1);
    const [currentTableDataXlS, setCurrentTableDataXlS] = useState([]);

    useEffect(() => {
        const firstPageIndex = (currentPageXlS - 1) * PageSizeXlS;
        const lastPageIndex = firstPageIndex + PageSizeXlS;
        setCurrentTableDataXlS(confirmGuests.slice(firstPageIndex, lastPageIndex));
    }, [currentPageXlS, confirmGuests, PageSizeXlS]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/partners/${params.id}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setParnert(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    const refreshActiveGuests = async () => {
        try{
            const response = await axios.get(`${config.URI}/partnerships/${params.id}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setActiveGuests(response.data.data);
                setSizeFile(response.data.size);
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        refreshData();
        refreshActiveGuests();
    }, []);

    useEffect(() => {
        if(file.name && file.name.indexOf('.pdf') === -1){
            ExcelRenderer(file, async (err, resp) => {
                if(err){
                    console.log(err, 'error');
                }
                const content = resp.rows.filter((row, i) => i !== 0);
                if(content.length > 0){
                    setConfirmGuests(content);
                    setDocument(false);
                }
            });
        }else{
            if(file.name && file.name.indexOf('.pdf') !== -1){
                onSubmitUpload('pdf');
            }
        }
    },[file]);

    useEffect(() => {
        let errors = 0;
        let success = 0;
        for(let i = 0; i < confirmGuests.length; i++){
            const validate = confirmGuests.filter(item => item[0] === confirmGuests[i][0] && item[1] === confirmGuests[i][1] && item[2] === confirmGuests[i][2] && item[3] === confirmGuests[i][3] && item[4] === confirmGuests[i][4]);
            const arrival = moment(confirmGuests[i][3]).isValid();
            const departure = moment(confirmGuests[i][4]).isValid();
            if(validate.length > 1 || !arrival || !departure){
                errors += 1;
            }else{
                success += 1;
            }
        }
        setImportError(errors);
        setImportSuccess(success);
    },[confirmGuests]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: '.xlsx, xls, .pdf',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const onDeleteArray = (index) => {
       const guests = confirmGuests.filter((item, i) => i !== index);
       setConfirmGuests(guests);
    }

    const onSubmitUpload = async (type) => {
        if(permissions() && permissions().actionCreate){
            if(type !== 'pdf'){
                //pendiente de la subida de archivos pdf solo es enviarlo pero en back se debe gestionar lo de guest
                const toastId = toast.loading('Loading...');
                const guests = [];
                for(let i = 0; i < confirmGuests.length; i++){
                    const validate = guests.filter(item => item.name === confirmGuests[i][0] && item.lastName === confirmGuests[i][1] && item.unit === confirmGuests[i][2] && item.arrival === moment(moment(confirmGuests[i][3]).format('MMM DD, YYYY hh:mm A'), 'MMM DD, YYYY hh:mm A').toISOString() && item.departure === moment(moment(confirmGuests[i][4]).format('MMM DD, YYYY hh:mm A'), 'MMM DD, YYYY hh:mm A').toISOString());
                    const arrival = moment(confirmGuests[i][3]).isValid();
                    const departure = moment(confirmGuests[i][4]).isValid();
                    if(validate.length === 0 && arrival && departure){
                        guests.push({ name: confirmGuests[i][0], lastName: confirmGuests[i][1], unit: confirmGuests[i][2], arrival: moment(moment(confirmGuests[i][3]).format('MMM DD, YYYY hh:mm A'), 'MMM DD, YYYY hh:mm A').toISOString(), departure: moment(moment(confirmGuests[i][4]).format('MMM DD, YYYY hh:mm A'), 'MMM DD, YYYY hh:mm A').toISOString()});
                    }
                }
                try{
                    const filename = await upload(file, props.token);
                    const response = await axios.post(`${config.URI}/partnerships`, { guests, file: filename, user: params.id }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
                    if(response.statusText === 'OK'){
                        toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                        refreshActiveGuests();
                        setFile({});
                        setDocument(true);
                    }
                }catch(e){
                    console.log(e);
                    messageError();
                }
                toast.dismiss(toastId);
            }else{
                const toastId = toast.loading('Loading...');
                try{
                    const filename = await upload(file, props.token);
                    const response = await axios.post(`${config.URI}/partnerships`, { guests: [], file: filename, user: params.id }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
                    if(response.statusText === 'OK'){
                        toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                        refreshActiveGuests();
                        setFile({});
                        setDocument(true);
                    }
                }catch(e){
                    console.log(e);
                    messageError();
                }
                toast.dismiss(toastId);
            }
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const humanFileSize = (bytes, si=true, dp=1) => {
        const thresh = si ? 1000 : 1024;
      
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B';
        }
      
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
      
        do {
          bytes /= thresh;
          ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
      
      
        return bytes.toFixed(dp) + ' ' + units[u];
    }

    const onRemoveActiveGuest = async () => {
        if(permissions() && permissions().actionDelete){
            onShowDelete();
            const toastId = toast.loading('Loading...');
            const response = await axios.delete(`${config.URI}/partnerships/${id}`, { headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                refreshActiveGuests();
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const onDelete = (id) => {
        setId(id);
        onShowDelete();
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    return(
        <Layout>
            {document ?
            <>
                <div className="d-md-flex mb-sm-10">
                    <Title>Partnerships</Title>
                </div>
                <div className="d-md-flex align-items-center" style={{marginTop: 21}}>
                    <Subtitle>{partner.name ? partner.name : ''}</Subtitle>
                    <Button width="256px" borderColor="#4FB4E6" onClick={() => { if(permissions() && permissions().actionExport){window.open('https://estebans-place-bucket.s3.us-east-2.amazonaws.com/partnerships-EP-format.xlsx', '_blank')}else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); } }} backgroundColor="#4FB4E6" color="#FFF">Download Sample File &nbsp;&nbsp;&nbsp;<img src={DownloadIcon} style={{ height: 13, width: 10, verticalAlign: 'top' }} /></Button>
                </div>
                <div className="cards mt-24" style={{ paddingRight: 21 }}>
                    <div {...getRootProps({style})}>
                        <input {...getInputProps()} />
                        <img src={Dropzone} height="43px" style={{ marginTop: 23 }} alt=""/>
                        <h5 style={{ fontSize: '18px', fontFamily: 'Roboto', color: '#000', marginTop: '15px', marginBottom: 0 }}>Click or Drag-n-Drop a document here</h5>
                        <p style={{ fontSize: '16px', color: '#000', fontFamily: 'Roboto', marginBottom: 20 }}>Only Excel® file format Or PDF</p>
                    </div>
                    <div className="dropzone-style">
                        <div style={{marginTop: 28, marginBottom: 23, marginLeft: 31, maxWidth: '90%'}}>
                            <p style={{ fontSize: '12px', color: '#000', fontFamily: 'Roboto', marginBottom: 0 }}>LATEST DOCUMENT UPLOADED</p>
                            <div className={(activeGuests.length > 0 && activeGuests[activeGuests.length - 1].attachment.indexOf('.pdf') !== -1) ? 'd-flex align-items-center justify-content-between mx-0' : '' }>
                                <h5 style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: '700', color: '#000', marginTop: '15px', marginBottom: 0, width: (activeGuests.length > 0 && activeGuests[activeGuests.length - 1].attachment.indexOf('.pdf') !== -1) ? '100%' : '80%' }} className="line-clamp">{activeGuests.length > 0 ? activeGuests[activeGuests.length - 1].attachment.substring(activeGuests[activeGuests.length - 1].attachment.lastIndexOf('/')+1) : ''}</h5>
                                {(activeGuests.length > 0 && activeGuests[activeGuests.length - 1].attachment.indexOf('.pdf') !== -1) &&
                                    <div className='d-flex align-items-center ml-5'>
                                        {/* <Link onClick={(e) => {e.preventDefault(); window.open(activeGuests[activeGuests.length - 1].attachment, '_blank')}} style={{ cursor: 'pointer', color: '#1A1A1A' }}><FontAwesomeIcon icon={faEye} size="lg" className='mr-3' /></Link> */}
                                        <Link onClick={(e) => {e.preventDefault(); window.open(activeGuests[activeGuests.length - 1].attachment, '_blank')}} style={{ cursor: 'pointer', color: '#1A1A1A' }}><FontAwesomeIcon icon={faCloudDownloadAlt} size="lg" /></Link>
                                    </div>
                                }
                            </div>
                            <p style={{ fontSize: '12px', color: '#000', fontFamily: 'Roboto', marginBottom: 0 }}>{activeGuests.length > 0 ? moment(activeGuests[activeGuests.length - 1].updateAt).format('MMM DD, YYYY - hh:mm A') + " - " + humanFileSize(sizeFile) : ''}</p>
                            <p style={{ fontSize: '12px', color: '#000', fontFamily: 'Roboto', marginBottom: 0 }}>{activeGuests.length > 0 ? 'Upload by '+activeGuests[activeGuests.length - 1].user.name : ''}</p>
                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: 21 }}>
                    {(activeGuests.length > 0 && activeGuests[activeGuests.length - 1].attachment.indexOf('.pdf') !== -1) ? 
                        <iframe src={activeGuests[activeGuests.length - 1].attachment} style={{width: '100%', marginTop: 30, height: 800, border: 'none'}}></iframe>
                    :
                    <div className="table-responsive">
                        <Table className="table-curved">
                            <h5 style={{fontFamily: 'Roboto', fontSize: 22, color: '#000', marginBottom: 0}}>Active Guests</h5>
                            <HeaderTable header={[{name: 'ID'}, {name: 'Name'}, {name: 'LAST NAME'}, {name: 'UNIT'}, {name: 'ARRIVAL'}, {name: 'DEPARTURE'}, { name: 'Actions'}]} />
                            {activeGuests.length > 0 && (
                                <>
                                    {currentTableData.map((guest, i) => {
                                        if(guest.attachment.indexOf('.pdf') === -1){
                                        return (    
                                            <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                                <Column className="rowBorderStart">{guest.code}</Column>
                                                <Column className="rowBorderMiddle">{guest.name}</Column>
                                                <Column className="rowBorderMiddle">{guest.lastName}</Column>
                                                <Column className="rowBorderMiddle">{guest.unit}</Column>
                                                <Column className="rowBorderMiddle">{moment(guest.arrival).format('MMM DD, YYYY hh:mm A')}</Column>
                                                <Column className="rowBorderMiddle">{moment(guest.departure).format('MMM DD, YYYY hh:mm A')}</Column>
                                                <Column className="rowBorderEnd">
                                                    <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                        <TextAction onClick={() => onDelete(guest._id)}>Delete</TextAction>
                                                    </div>
                                                </Column>
                                            </ContentBody>
                                            )
                                        }
                                    })}
                                </>
                            )}
                        </Table>
                        {activeGuests.length > 0 &&
                            <div className="d-flex w-100" style={{marginBottom: 30}}>
                                <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                    <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {activeGuests.length} items</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                    <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                    <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                                </div>
                                <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={activeGuests.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    }
                </div>
            </>
            :
            <>
                <div className="d-md-flex mb-sm-10">
                    <Title>Import Preview</Title>
                </div>
                <div style={{marginTop: 21}}>
                    <Subtitle>{partner.name ? partner.name : ''}</Subtitle>
                </div>
                <div className="d-md-flex justify-content-between align-items-center" style={{marginTop: 21, marginRight: 21}}>
                    <div className="cards">
                        <Card>
                            <TitleCard>Successful imported rows</TitleCard>
                            <div className="d-md-flex align-items-center">
                                <NumberCard>{importSuccess}</NumberCard>
                                <Button width="69px" backgroundColor={filterSuccess ? "#4FB4E6" : "#4F4F4F"} borderColor={filterSuccess ? "#4FB4E6" : "#4F4F4F"} color="#FFF" height="29px" onClick={() => { setFilterSuccess(!filterSuccess); setFilterError(false); }} style={{marginLeft: 20}}>View</Button>
                            </div>
                        </Card>
                        <Card>
                            <TitleCard>Rows with errors</TitleCard>
                            <div className="d-md-flex align-items-center">
                                <NumberCard>{importError}</NumberCard>
                                <Button width="69px" backgroundColor={filterError ? "#4FB4E6" : "#4F4F4F"} borderColor={filterError ? "#4FB4E6" : "#4F4F4F"} color="#FFF" height="29px" onClick={() => { setFilterError(!filterError); setFilterSuccess(false); }} style={{marginLeft: 20}}>View</Button>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Button onClick={() => { setDocument(true); setFile({}); }}>Cancel</Button>
                        <Button width="162px" backgroundColor="#4FB4E6" borderColor="#4FB4E6" color="#FFF" onClick={() => onSubmitUpload()}>Confirm and Save</Button>
                    </div>
                </div>
                <div style={{ paddingRight: 21 }}>
                    <div className="table-responsive">
                        <Table className="table-curved">
                            <h5 style={{fontFamily: 'Roboto', fontSize: 22, color: '#000', marginBottom: 0}}>Imported Rows</h5>
                            <HeaderTable header={[{name: 'Name'}, {name: 'LAST NAME'}, {name: 'UNIT'}, {name: 'ARRIVAL'}, {name: 'DEPARTURE'}, {name: 'IMPORT STATUS'}, { name: 'Actions'}]} />
                            {confirmGuests.length > 0 && (
                                <>
                                    {currentTableDataXlS.map((guest, i) => {
                                        const validate = confirmGuests.filter(item => item[0] === guest[0] && item[1] === guest[1] && item[2] === guest[2] && item[3] === guest[3] && item[4] === guest[4]);
                                        const arrival = moment(guest[3]).isValid();
                                        const departure = moment(guest[4]).isValid();
                                        let faile = false;
                                        let duplicated = false;
                                        if(validate.length > 1) duplicated = true;
                                        if(!arrival || !departure) faile = true;
                                        if((filterError && faile || filterError && duplicated) || (filterSuccess && !faile) || (!filterSuccess && !filterError))
                                            return(
                                                <ContentBody key={i} backgroundColor={ faile || duplicated ? 'rgba(255, 0, 0, 0.2)' : ((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                                    <Column className="rowBorderMiddle">{guest[0]}</Column>
                                                    <Column className="rowBorderMiddle">{guest[1]}</Column>
                                                    <Column className="rowBorderMiddle">{guest[2]}</Column>
                                                    <Column className="rowBorderMiddle">{moment(guest[3]).format('MMM DD, YYYY hh:mm A')}</Column>
                                                    <Column className="rowBorderMiddle">{moment(guest[4]).format('MMM DD, YYYY hh:mm A')}</Column>
                                                    <Column className="rowBorderMiddle">{duplicated ? 'Duplicated' : faile ? 'Failed' : 'Successful'}</Column>
                                                    <Column className="rowBorderEnd">
                                                        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                            <TextAction onClick={() => onDeleteArray(i)}>Delete</TextAction>
                                                        </div>
                                                    </Column>
                                                </ContentBody>
                                            )
                                    })}
                                </>
                            )}
                        </Table>
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPageXlS - 1) * PageSizeXlS === 0 ? 1 : (currentPageXlS - 1) * PageSizeXlS} - {((currentPageXlS - 1) * PageSizeXlS) + PageSizeXlS} out of {confirmGuests.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSizeXlS + ""} onChange={(e) => setPageSizeXlS(+e.target.value)} style={{maxWidth: 57, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPageXlS}
                                    totalCount={confirmGuests.length}
                                    pageSize={PageSizeXlS}
                                    onPageChange={page => setCurrentPageXlS(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            <Modal show={showDelete} onHide={onShowDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete Active Guest</TitleModal>
                    <TextModal className="mb-40 mt-30">Please confirm active guest deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div className="text-center mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => onShowDelete()}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} width={'149px'} onClick={() => onRemoveActiveGuest()} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const Card = styled.div`
margin-right: 20px !important;
margin-bottom: 20px;
border: 1px solid #BDBDBD;
border-radius: 10px;
padding: 20px 18px;
min-width: 245px;
min-height: 100px;
`;
const TitleCard = styled.h4`
font-family: 'Roboto';
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #000;
margin-bottom: 0 !important;
`;
const NumberCard = styled.h4`
font-family: 'Roboto';
font-weight: 700;
font-size: 46px;
line-height: 54px;
color: #000;
margin-bottom: 0 !important;
`;
const Subtitle = styled.h3`
font-family 'Roboto';
font-size: 22px;
color: #000;
line-height: 26px;
margin-bottom: 0 !important;
margin-right: 10px;
`;
const TextBody = styled.p`
font-family 'Roboto';
font-size: 14px;
color: ${({color}) => color ? color: '#1A1A1A'};
line-height: 16px;
text-align: center;
margin-bottom: 0 !important;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 5px;
height: ${({height}) => height ? height : '40px'};
min-width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;

const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 3,
    marginRight: '10px',
    width: '50%',
    borderRadius: 10,
    borderStyle: 'dotted',
    borderColor: '#BDBDBD',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
const activeStyle = {
    borderColor: '#2196f3'
};
const acceptStyle = {
    borderColor: '#00e676'
};
const rejectStyle = {
    borderColor: '#ff1744'
};

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(PartnershipsUpload);