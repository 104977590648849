import React, { createRef, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import GoogleMap from '../components/Maps';
import styled from 'styled-components';
import { Col, Form, Row } from 'react-bootstrap';
import HeaderTable from '../components/TableRounded/Header';
import TimePicker from '../components/Timepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { config } from '../config';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import Select from 'react-select';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import "@pathofdev/react-tag-input/build/index.css";
import Clock from '../assets/clock.png';
import CloseModal from '../assets/close-modal.png';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Pagination from '../components/Pagination/Pagination';
import { validate_session } from '../utils';

const options = [
    { value: 'cash', label: 'Cash' },
    { value: 'credit card', label: 'Credit Card' },
    { value: 'venmo', label: 'Venmo' },
    { value: 'check', label: 'Check' }
]

const Beaches = (props) => {
    const module = 'Beaches';
    const [commissionType, setCommissionType] = useState('');
    const [commissionMethod, setCommissionMethod] = useState([]);
    const [resultSearch, setResultSearch] = useState([]);
    const [address, setAddress] = useState({});
    const [name, setName] = useState('');
    const [percentage, setPercentage] = useState('');
    const [opensAt, setOpensAt] = useState('');
    const [closesAt, setClosesAt] = useState('');
    const [lastLetter, setLastLetter] = useState('');
    const [lastNumber, setLastNumber] = useState('');
    const [cabanas, setCabanas] = useState([]);
    const [tags, setTags] = useState([]);
    const [show, setShow] = useState(false);
    const showModal = () => {
        if(permissions() && permissions().actionCreate){
            setShow(!show)
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    };
    const [showModalDetails, setShowModalDetails] = useState(false);
    const hanlderModalDetails = () => setShowModalDetails(!showModalDetails);
    const [modalDelete, setModalDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const showModalEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const [remove, setRemove] = useState('');
    const [focus, setFocus] = useState(false);
    const [beaches, setBeaches] = useState([]);
    const [details, setDetails] = useState({});
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState('');

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(beaches.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, beaches, PageSize]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    useEffect(() => {
        if(lastLetter.length && lastNumber.length){
            const letras = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
            const tags = [];
            for(let k = 0; k < letras.length; k++){
                if(letras[k - 1] === lastLetter){
                    break;
                }
                for(let i = 0; i < lastNumber; i++){
                    tags.push(`${letras[k]}${i+1}`);
                }
            }
            setTags(tags);
        }
    },[lastLetter, lastNumber])

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(beaches.length){
                beaches.map(beach => {
                    data.push({ ID: beach.code, NAME: beach.name, ADDRESS: beach.address.full_address, N_OF_USERS: 0, COMMISSIONS: beach.commissions === 'General' ? `General (${beach.percentage}%)` : beach.commission + '%', OPENS_AT: moment(beach.opensAt).format('h:mm a').toUpperCase(), CLOSES_AT: moment(beach.closesAt).format('h:mm a').toUpperCase(), INDEX_LETTERS: `From A to ${beach.indexLetter}`, CABANAS: beach.cabanas.join(', ')});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Beaches PDF";
            const headers = [['ID', 'NAME', 'ADDRESS', 'N. OF USERS', 'COMMISSIONS', 'OPENS AT', 'CLOSES AT', 'INDEX LETTERS', 'CABANAS']];
        
            const data = beaches.map(beach => [beach.code, beach.name, beach.address.full_address, 0, beach.commissions === 'General' ? `General (${beach.percentage}%)` : beach.commission + '%', moment(beach.opensAt).format('h:mm a').toUpperCase(), moment(beach.closesAt).format('h:mm a').toUpperCase(), `From A to ${beach.indexLetter}`, beach.cabanas.join(', ')]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("beaches-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `beaches-estebans-place` + fileExtension);
    };

    useEffect(() => {
        refreshData();
    },[]);

    const searchGeocoding = async (query) => {
        setAddress({ full_address: query });
        try{
            const resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=AIzaSyDA1XAJFVIFxWko2uvjEjNQW2IWbjL9F2E`)
            const data = await resp.json();
            if(data){
                setResultSearch(data.results);
            }
        }catch(e){
            console.log(e)
        }
    }

    const onSubmitCreateBeach = async () => {
        if(validation_field()){
            showModal();
            const toastId = toast.loading('Loading...');
            const response = await axios.post(`${config.URI}/beaches`, { commissionPaymentMethod: commissionMethod, commissions: commissionType, name, address, percentage, opensAt: moment(opensAt.format('h:mm a'), 'h:mm a').toISOString(), closesAt: moment(closesAt.format('h:mm a'), 'h:mm a').toISOString(), indexLetterNumber: lastNumber, indexLetter: lastLetter, cabanas }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                setName('');
                setAddress({});
                setCommissionType('');
                setPercentage('');
                setOpensAt('')
                setClosesAt('');
                setLastLetter('')
                setLastNumber('');
                setCabanas([]);
                toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validateInputNumber = (evt) => {
        const match = /^[0-9]+$/;
        if(evt.target.value === ''){
            setLastNumber('');
        }
        if(evt.target.value.match(match)) {
            setLastNumber(evt.target.value)
        }
    }

    const validateInputPercentage = (evt) => {
        const match = /^[0-9]+$/;
        if(evt.target.value === ''){
            setPercentage('');
        }
        if(evt.target.value.match(match)) {
            setPercentage(evt.target.value)
        }
    }

    const validateInputText = (evt) => {
        if(evt.target.value === ''){
            setLastLetter('');
        }
        if(/^[a-zA-Z]*$/g.test(evt.target.value)) {
            setLastLetter(evt.target.value.toUpperCase())
        }
    }

    const onShowDetails = (item) => {
        setDetails(item);
        hanlderModalDetails();
    }

    const onSubmitDeleteBeach = async () => {
        onModalDelete();
        const toastId = toast.loading('Loading...');
        const response = await axios.delete(`${config.URI}/beaches/${remove}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
        if(response.statusText === 'OK'){
            refreshData();
            setRemove('');
            toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
        }else{
            messageError();
        }
        toast.dismiss(toastId);
    }

    const onModalDelete = () => {
        if(permissions() && permissions().actionDelete){
            setModalDelete(!modalDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const onShowDelete = (id) => {
        setRemove(id);
        onModalDelete();
    }

    const onShowEdit = (item) => {
        setName(item.name);
        setAddress(item.address);
        setCommissionType(item.commissions);
        setPercentage(item.percentage);
        setOpensAt(moment(item.opensAt));
        setClosesAt(moment(item.closesAt));
        setLastLetter(item.indexLetter)
        setLastNumber(item.indexLetterNumber);
        setCabanas(item.cabanas);
        setCommissionMethod(item.commissionPaymentMethod);
        setRemove(item._id);
        showModalEdit();
    }

    const onSubmitEditBeach = async () => {
        if(validation_field()){
            showModalEdit();
            const toastId = toast.loading('Loading...');
            const response = await axios.put(`${config.URI}/beaches/${remove}`, { commissionPaymentMethod: commissionMethod, commissions: commissionType, name, address, percentage, opensAt: moment(opensAt.format('h:mm a'), 'h:mm a').toISOString(), closesAt: moment(closesAt.format('h:mm a'), 'h:mm a').toISOString(), indexLetterNumber: lastNumber, indexLetter: lastLetter, cabanas }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                setName('');
                setAddress({});
                setCommissionType('');
                setPercentage('');
                setOpensAt('')
                setClosesAt('');
                setLastLetter('')
                setLastNumber('');
                setCabanas([]);
                setRemove('');
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const clean = () => {
        setName('');
        setAddress({});
        setCommissionType('');
        setPercentage('');
        setOpensAt('')
        setClosesAt('');
        setLastLetter('')
        setLastNumber('');
        setCabanas([]);
        setRemove('');
        setErrors({});
    }

    const validation_field = () => {
        if(name === ''){
            setErrors({ name: true });
            messageErrorMessage(`Invalid name input`);
        }else if(!address.full_address){
            setErrors({ address: true });
            messageErrorMessage(`Invalid address input`);
        }else if(commissionType === ''){
            setErrors({ commissionType: true });
            messageErrorMessage(`Invalid commission type input`);
        }else if(commissionType == 'General' && percentage ===''){
            setErrors({ percentage: true });
            messageErrorMessage(`Invalid percentage input`);
        }else if(commissionMethod.length === 0){
            setErrors({ commissionMethod: true });
            messageErrorMessage(`Don't select commission payment method`);
        }else if(opensAt === ''){
            setErrors({ opensAt: true });
            messageErrorMessage(`Invalid opens at input`);
        }else if(closesAt === ''){
            setErrors({ closesAt: true });
            messageErrorMessage(`Invalid closes at input`);
        }else if(moment(opensAt).isAfter(moment(closesAt))){
            messageErrorMessage(`Invalid opens and closes inputs`);
        }else if(lastLetter === ''){
            setErrors({ lastLetter: true });
            messageErrorMessage(`Invalid last index letter input`);
        }else if(lastNumber === ''){
            setErrors({ lastNumber: true });
            messageErrorMessage(`Invalid last index number input`);
        }else{
            setErrors({});
            return true;
        }
    }

    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const beaches_search = reply.filter(b => b.name.toLowerCase().search(value.toLowerCase()) !== -1 || b.address.full_address.toLowerCase().search(value.toLowerCase()) !== -1);
            setBeaches(beaches_search);
            setCurrentPage(1);
        }
        if(value === ''){
            refreshData();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Beaches</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => showModal()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'id'}, {name: 'name'}, {name: 'address'}, {name: 'n. of users'}, {name: 'commissions'}, {name: 'opens at'}, {name: 'closes at'}, {name: 'index letters'}, {name: 'cabanas'}, {name: 'actions'}]} />
                        {beaches.length > 0 &&
                            currentTableData.map((beach, i) =>(
                                <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                    <Column className="rowBorderStart">{beach.code}</Column>
                                    <Column className="rowBorderMiddle">{beach.name}</Column>
                                    <Column className="rowBorderMiddle">{beach.address.full_address}</Column>
                                    <Column className="rowBorderMiddle">{beach.users}</Column>
                                    <Column className="rowBorderMiddle">{beach.commissions === 'General' ? `${beach.commissions} (${beach.percentage}%)` : beach.commissions }</Column>
                                    <Column className="rowBorderMiddle">{moment(beach.opensAt).format('h:mm a').toUpperCase()}</Column>
                                    <Column className="rowBorderMiddle">{moment(beach.closesAt).format('h:mm a').toUpperCase()}</Column>
                                    <Column className="rowBorderMiddle">From A to {beach.indexLetter}</Column>
                                    <Column className="rowBorderMiddle">{beach.cabanas.join(', ')}</Column>
                                    <Column className="rowBorderEnd">
                                        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                            <TextAction onClick={() => onShowDetails(beach)}>Details&nbsp;</TextAction>-<TextAction onClick={() => onShowEdit(beach)}>&nbsp;Edit&nbsp;</TextAction>-<TextAction onClick={() => onShowDelete(beach._id)}>&nbsp;Delete</TextAction>
                                        </div>
                                    </Column>
                                </ContentBody>
                            ))
                        }
                    </Table>
                    {beaches.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {beaches.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={beaches.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={show} onHide={showModal} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {showModal(); clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Create Beach</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Address</Form.Label>
                        <Form.Control type="text" onFocus={() => setFocus(true)} onChange={(e) => searchGeocoding(e.target.value)} value={address.full_address && address.full_address} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.address ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10, borderBottomLeftRadius: resultSearch.length > 0 && focus ? 0 : 10, borderBottomRightRadius: resultSearch.length > 0 && focus ? 0 : 10}} />
                        {resultSearch.length > 0 && focus &&
                            <div style={{ position: 'absolute', width: '94.5%', background: '#FFF', zIndex: 9, maxHeight: 200, padding: 10, borderLeft: '1px solid #BDBDBD', borderRight: '1px solid #BDBDBD', borderBottom: '1px solid #BDBDBD', overflow: 'auto', borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
                                {
                                    resultSearch.map(search => {
                                        if(search){
                                            return  <p style={{ color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', cursor: 'pointer', borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: '#BDBDBD'}} onClick={() => {setAddress({ latitude: search.geometry.location.lat, longitude: search.geometry.location.lng, full_address: search.formatted_address }); setFocus(false)}}>{search.formatted_address}</p>
                                        }else{
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        }
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission type</Form.Label>
                                <Form.Control as="select" defaultValue={commissionType} onChange={(e) => setCommissionType(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.commissionType ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option> 
                                    <option value={'General'}>General</option>
                                    <option value={'Individual'}>Individual</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {commissionType === 'General' &&
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Percentage</Form.Label>
                                <div>
                                    <Form.Control type="text" onChange={(e) => validateInputPercentage(e)} value={percentage}  style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.percentage ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                                    <p style={{position: 'absolute', right: 30, top: 44, fontSize: 16, color: '#1A1A1A'}}>%</p>
                                </div>
                            </Form.Group>
                        </Col>
                        }
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission payment method</Form.Label>
                        <Select options={options} onChange={(tags) => setCommissionMethod(tags.map(t => t.value))} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Opens at</Form.Label>
                                <div>
                                    <TimePicker onChange={(e) => setOpensAt(e)} value={opensAt} />
                                    <img src={Clock} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Closes at</Form.Label>
                                <div>
                                    <TimePicker onChange={(e) => setClosesAt(e)} value={closesAt} />
                                    <img src={Clock} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Last index letter</Form.Label>
                                <Form.Control type="text" onChange={(e) => validateInputText(e)} value={lastLetter} maxLength="1" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.lastLetter ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10, textTransform: 'uppercase'}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Last index number</Form.Label>
                                <Form.Control type="text" onChange={(e) => validateInputNumber(e)} pattern="[0-9]+" value={lastNumber} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.lastNumber ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Cabana's positions (press Enter to add)</Form.Label>
                        <ReactTagInput 
                            tags={cabanas}
                            placeholder=" "
                            onChange={(newTags) => newTags.length > 0 ? setCabanas(tags.indexOf(newTags[newTags.length - 1].toUpperCase()) !== -1 ? newTags.map(tag => tag.toUpperCase()) : cabanas) : setCabanas([])}
                        />
                    </Form.Group>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => {showModal(); clean();}} paddingVertical={'0.5rem'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} width={'149px'} onClick={() => onSubmitCreateBeach()} paddingVertical={'0.5rem'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showModalDetails} onHide={hanlderModalDetails} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 15, marginTop: 16 }} onClick={() => hanlderModalDetails()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40 }}>
                    <TitleModal>Beach Details</TitleModal>
                </div>
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <tbody>
                        <tr>
                            <TitleDetails>ID</TitleDetails>
                            <td>{details._id ? details._id : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>NAME</TitleDetails>
                            <td>{details.name ? details.name : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>ADDRESS</TitleDetails>
                            <td>{details.address ? details.address.full_address : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>number of users</TitleDetails>
                            <td>{details.users ? details.users : '0'}</td>
                        </tr>
                        <tr>
                            <TitleDetails>commissions</TitleDetails>
                            <td>{details.commissions ? details.commissions === 'General' ? `${details.commissions} (${details.percentage}%)` : details.commissions : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>opens at</TitleDetails>
                            <td>{details.opensAt ? moment(details.opensAt).format('h:mm a').toUpperCase() : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>closes at</TitleDetails>
                            <td>{details.closesAt ? moment(details.closesAt).format('h:mm a').toUpperCase() : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>index letters</TitleDetails>
                            <td>From A to {details.indexLetter ? details.indexLetter : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>cabanas</TitleDetails>
                            <td>{details.cabanas ? details.cabanas.join(', ') : ''}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ maxHeight: 234 }}>
                    <GoogleMap nameMarket={details.address ? details.address.full_address : ''} latitude={details.address ? details.address.latitude : 0} longitude={details.address ? details.address.longitude : 0} />
                </div>
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width="149px" onClick={() => hanlderModalDetails()}>Close</Button>
                </div>
            </Modal>
            <Modal show={showEdit} onHide={showModalEdit} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginTop: 16, marginRight: 16, }} onClick={() => {showModalEdit(); clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Edit Beach</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Address</Form.Label>
                        <Form.Control type="text" onFocus={() => setFocus(true)} onChange={(e) => searchGeocoding(e.target.value)} value={address.full_address && address.full_address} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.address ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10, borderBottomLeftRadius: resultSearch.length > 0 && focus ? 0 : 10, borderBottomRightRadius: resultSearch.length > 0 && focus ? 0 : 10}} />
                        {resultSearch.length > 0 && focus &&
                            <div style={{ position: 'absolute', width: '94.5%', background: '#FFF', zIndex: 9, maxHeight: 200, padding: 10, borderLeft: '1px solid #BDBDBD', borderRight: '1px solid #BDBDBD', borderBottom: '1px solid #BDBDBD', overflow: 'auto', borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
                                {
                                    resultSearch.map(search => {
                                        if(search){
                                            return  <p style={{ color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', cursor: 'pointer', borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: '#BDBDBD'}} onClick={() => {setAddress({ latitude: search.geometry.location.lat, longitude: search.geometry.location.lng, full_address: search.formatted_address }); setFocus(false)}}>{search.formatted_address}</p>
                                        }else{
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        }
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission type</Form.Label>
                                <Form.Control as="select" defaultValue={commissionType} onChange={(e) => setCommissionType(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.commissionType ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option> 
                                    <option value={'General'}>General</option>
                                    <option value={'Individual'}>Individual</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {commissionType === 'General' &&
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Percentage</Form.Label>
                                <div>
                                    <Form.Control type="text" onChange={(e) => validateInputPercentage(e)} value={percentage}  style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.percentage ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                                    <p style={{position: 'absolute', right: 30, top: 44, fontSize: 16, color: '#1A1A1A'}}>%</p>
                                </div>
                            </Form.Group>
                        </Col>
                        }
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission payment method</Form.Label>
                        <Select options={options} defaultValue={commissionMethod.map(com => {return { value: com, label: com }})} onChange={(tags) => setCommissionMethod(tags.map(t => t.value))} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Opens at</Form.Label>
                                <div>
                                    <TimePicker onChange={(e) => setOpensAt(e)} value={opensAt} />
                                    <img src={Clock} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Closes at</Form.Label>
                                <div>
                                    <TimePicker onChange={(e) => setClosesAt(e)} value={closesAt} />
                                    <img src={Clock} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Last index letter</Form.Label>
                                <Form.Control type="text" onChange={(e) => validateInputText(e)} value={lastLetter} maxLength="1" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.lastLetter ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10, textTransform: 'uppercase'}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Last index number</Form.Label>
                                <Form.Control type="text" onChange={(e) => validateInputNumber(e)} pattern="[0-9]+" value={lastNumber} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.lastNumber ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Cabana's positions (press Enter to add)</Form.Label>
                        <ReactTagInput 
                            tags={cabanas}
                            placeholder=" "
                            onChange={(newTags) => newTags.length > 0 ? setCabanas(tags.indexOf(newTags[newTags.length - 1].toUpperCase()) !== -1 ? newTags.map(tag => tag.toUpperCase()) : cabanas) : setCabanas([])}
                        />
                    </Form.Group>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => {showModalEdit(); clean();}} paddingVertical={'0.5rem'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} width={'149px'} onClick={() => onSubmitEditBeach()} paddingVertical={'0.5rem'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalDelete} onHide={onModalDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginTop: 16, marginRight: 16, }} onClick={() => onModalDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal color="#FF0F00">Delete Beach</TitleModal>
                    <TextModal>Please confirm beach deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => onModalDelete()} paddingVertical={'0.5rem'}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} width={'149px'} onClick={() => onSubmitDeleteBeach()} paddingVertical={'0.5rem'} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;

const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;

const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
&:hover{
    background-color: rgba(255, 235, 17, 0.1)
}
`;

const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;

const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;

const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps, null)(Beaches);