import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUmbrellaBeach, faBoxOpen, faUsers, faCashRegister, faStopwatch, faUserFriends, faUserCircle, faHeadset, faCogs,faQrcode, faSignOutAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

export const SidebarData = [
    {
        title: 'MAIN MENU',
        navs: [
            {
                title: 'Dashboard',
                path: '/admin/index',
                icon: <FontAwesomeIcon icon={faHome} size="lg" />
              },
              {
                title: 'Beaches',
                path: '/admin/beaches',
                icon: <FontAwesomeIcon icon={faUmbrellaBeach} size="lg" />
              },
              {
                title: 'Products',
                path: '/admin/products',
                icon: <FontAwesomeIcon icon={faBoxOpen} size="lg" />,
              },
              {
                title: 'Partnerships',
                path: '/admin/partnerships',
                icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
                subNav: [
                  {
                      title: 'All Partners',
                      path: '/admin/partnerships',
                  },
                  {
                      title: 'Billing',
                      path: '/admin/partner-billing',
                  }
                ]
              },
              {
                title: 'Revenue',
                path: '/admin/revenue',
                icon: <FontAwesomeIcon icon={faCashRegister} size="lg" />
              },
              {
                title: 'Time Tracking',
                path: '/admin/timetracking',
                icon: <FontAwesomeIcon icon={faStopwatch} size="lg" />,
              },
              {
                  title: 'Memberships',
                  path: '/admin/memberships',
                  icon: <FontAwesomeIcon icon={faQrcode} size="lg" />,
            },
            {
                  title: 'Users',
                  path: '/admin/users',
                  icon: <FontAwesomeIcon icon={faUserFriends} size="lg" />,
            },
            {
                  title: 'My Profile',
                  path: '/profile',
                  icon: <FontAwesomeIcon icon={faUserCircle} size="lg" />,
                  action: 'profile'
            },
            {
                  title: 'Support',
                  path: '/support',
                  icon: <FontAwesomeIcon icon={faHeadset} size="lg" />,
                  action: 'support'
            },
            {
              title: 'Deleted Elements',
              path: '/admin/deleted-elements',
              icon: <FontAwesomeIcon icon={faTrash} size="lg" />
            },
            {
                  title: 'Settings',
                  path: '/settings',
                  icon: <FontAwesomeIcon icon={faCogs} size="lg" />,
                  subNav: [
                    {
                        title: 'Partners',
                        path: '/admin/settings/partners',
                    },
                    {
                        title: 'Roles',
                        path: '/admin/settings/roles',
                    },
                    {
                        title: 'Notification',
                        path: '/admin/settings/notification'
                    },
                    {
                      title: 'Membership Plans',
                      path: '/admin/settings/membership-plans'
                    }
                  ]
            },
        ]
    },
    {
        title: 'EXIT',
        navs: [
            {
                title: 'Log Out',
                path: '/admin/logout',
                icon: <FontAwesomeIcon icon={faSignOutAlt} size="lg"/>
            }
        ]
    }
];