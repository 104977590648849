import React from 'react';
import Icon from '../assets/icon.png';

const Loading = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100%', justifyContent: 'center' }}>
            <div>
                <img src={Icon} /><br/>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}

export default Loading;