import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import { config } from '../config';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/modal-action';
import { updateData } from '../redux/actions/auth-action';
import axios from 'axios';
import UploadIcon from '../assets/upload-icon.png';
import { Col, Form, Row } from 'react-bootstrap';
import { upload } from '../utils';
import CloseModal from '../assets/close-modal.png';
import toast from 'react-hot-toast';
import InputMask from "react-input-mask";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment';

const ModalProfile = (props) => {
    const uploadRef = useRef(null);
    const imageRef = useRef(null);
    const [file, setFile] = useState({});
    const [photo_url, setPhoto_url] = useState('');
    const [replyPhoto, setReplyPhoto] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [Id, setId] = useState('');

    const [showCrop, setShowCrop] = useState(false);

    const onShowCrop = () => setShowCrop(!showCrop);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const myProfile = async () => {
        const id = props.userData._id;
        try{
            const response = await axios.get(`${config.URI}/users/${id}`, { headers: { 'Accept': 'application/json', 'Authorization': props.token }});
            if(response.statusText === 'OK'){
                if(response.data){
                    const data = response.data.data;
                    setName(data.name);
                    setPhoto_url(data.profile_photo || '');
                    setEmail(data.email);
                    setPhone(data.phone);
                    setId(data._id);
                }
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        if(replyPhoto === '')
            myProfile();
    },[props.show]);

    const onSubmitUpdate = async () => {
        if(validation_field()){
            props.onHide();
            const toastId = toast.loading('Loading...');
            let filename = null;
            if(file.name){
                filename = await upload(file, props.token);
            }else{
                filename = photo_url;
            }

            const response = await axios.put(`${config.URI}/profile/${Id}`, { profile_photo: filename, name, phone: phone.replace('(', '').replace(')', '').replace('-','').replace(' ', ''), email, password: password !== '' ? password : '' }, { headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                props.updateData(Id, props.token);
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                setReplyPhoto('');
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validation_field = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(name === ''){
            setErrors({ name: true });
            messageErrorMessage(`Invalid name input`);
        }else if(email === '' || !re.test(email)){
            setErrors({ email: true });
            messageErrorMessage(`Invalid email input`);
        }else if(phone === ''){
            setErrors({ phone: true });
            messageErrorMessage(`Invalid phone input`);
        }else if(password !== confirmPassword){
            setErrors({ password: true });
            messageErrorMessage(`Your new password and confirm new password mismatch.`);
        }else{
            setErrors({});
            return true;
        }
    }

    const validation_field_success = () => {
        if(name !== ''){
            setErrors({ name: false });
        }else if(email !== ''){
            setErrors({ email: false });
        }else if(phone !== ''){
            setErrors({ phone: false });
        }else if(password === confirmPassword){
            setErrors({ password: false });
        }
    }

    useEffect(() => {
        validation_field_success();
    },[name, phone, email, password, confirmPassword])

    useEffect(() => {
        if(file.name){
            props.onHide();
            onShowCrop();
            setReplyPhoto(URL.createObjectURL(file));
        }
    },[file]);

    const [crop, setCrop] = useState({ unit: "%", width: 50, aspect: 1/1 });
    const previewCanvasRef = createRef(null);
    const [cropComplete, setCropComplete] = useState(null);
    
    const onLoad = useCallback((img) => {
        imageRef.current = img;
    }, []);
    
    useEffect(() => {
        if(!imageRef.current || !previewCanvasRef.current){
            return;
        }

        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const crop = cropComplete;
    
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
    }, [cropComplete]);

    const onCompleteCrop = (canvas, crop) => {
        if(!canvas || !crop){
            return;
        }
        const base64Image = canvas.toDataURL("image/jpeg", 1);
        canvas.toBlob(
            (blob) => {
                setFile(new File([blob], `${new Date()}profile.jpeg`, {type: "image/jpeg", lastModified: new Date()}));
                onShowCrop();
                props.onShow();
                setPhoto_url(base64Image);
            },
        'image/jpeg', 0.75);
        
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {setReplyPhoto('');props.onHide();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Update My Profile</TitleModal>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                        <img src={photo_url ? photo_url : 'https://c0.klipartz.com/pngpicture/11/510/sticker-png-computer-icons-colorado-state-university-user-profile-miscellaneous-service.png'} style={{ height: 92, width: 92, borderRadius: 50, cursor: 'pointer' }} alt="profile photo" onClick={() => uploadRef.current.click()} />
                        <div style={{ position: 'absolute', left: '54.5%', top: 5, background: '#179ADB', width: 25, borderRadius: 50, cursor: 'pointer' }} onClick={() => uploadRef.current.click()}>
                            <img src={UploadIcon} style={{ height: 20, width: 20, borderRadius: 50 }} />
                        </div>
                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={uploadRef} onChange={(e) => { setFile(e.target.files[0]); e.target.files = null; }} />
                    </div>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Email</Form.Label>
                        <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.email ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Phone</Form.Label>
                        <InputMask mask="(999) 999-9999" className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.phone ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} onChange={(e) => setPhone(e.target.value)} value={phone} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>New Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} defaultValue={password} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.password ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Confirm New Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)} defaultValue={confirmPassword} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.password ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {setReplyPhoto('');props.onHide();}} width="149px">Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitUpdate()} width="149px" color={'#FFF'}>Update</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showCrop} onHide={onShowCrop} backdrop dialogClassName="modal-600w" centered>
                <div className="text-center">
                    {replyPhoto && (
                        <>
                            <ReactCrop
                                src={replyPhoto}
                                crop={crop}
                                onImageLoaded={onLoad}
                                onComplete={(c) => setCropComplete(c)}
                                onChange={(c) => setCrop(c)}
                            /> 
                            <div className="d-none">
                                <canvas ref={previewCanvasRef} />
                            </div>
                            <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                                <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowCrop();props.onShow();}} width="149px">Cancel</Button>
                                <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onCompleteCrop(previewCanvasRef.current, cropComplete)} width="149px" color={'#FFF'}>Save</Button>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    )
}
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;

const mapStateToProps = state => ({
	show: state.modal.showprofile,
    token: state.auth.token,
    userData: state.auth.userData
})

const mapDispatchToProps = dispatch => ({
    onHide: () => dispatch(actions.hidenProfile()),
    onShow: () => dispatch(actions.showProfile()),
    updateData: (id, token) => dispatch(updateData(id, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalProfile);