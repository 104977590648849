const initState = {
    showprofile: false,
    showsupport: false
}

const modal = (state = initState, action) => {
    switch(action.type){
        case 'MODAL_PROFILE_SHOW':
            return{
                ...state,
                showprofile: true
            }
            break;
        case 'MODAL_PROFILE_HIDEN':
            return{
                ...state,
                showprofile: false
            }
            break;
        case 'MODAL_SUPPORT_SHOW':
            return{
                ...state,
                showsupport: true
            }
            break;
        case 'MODAL_SUPPORT_HIDEN':
            return{
                ...state,
                showsupport: false
            }
            break;
        default:
            return state;
    }
}

export default modal;