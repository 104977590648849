import React, { useEffect, useState } from 'react';
import SidebarLogo from './Sidebar/SidebarLogo';
import SubMenu from './Sidebar/SubMenu';
import { SidebarData } from './Sidebar/SidebarData';
import CardUser from './CardUser';
import styled from 'styled-components';
import '../styles.css';
import { Link } from 'react-router-dom';
import ModalProfile from './ModalProfile';
import ModalSupport from './ModalSupport';
import { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/auth-action';

const SidebarLink = styled(Link)`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  color: ${(({ open }) => open ? '#000' : '#FFF')};
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 5px !important;
  list-style: none;
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  background: ${(({ open }) => open ? '#FFEB11' : 'transparent')};
  &:hover {
    color: ${(({ open }) => open ? '#000' : '#FFF')};
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
`;
const NavIcon = styled(Link)`
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SidebarNav = styled.nav`
  background: #1A1A1A;
  overflow-y: auto;
  overflow-x: none;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SidebarWrap = styled.div`
  width: 100%;
`;
const Nav = styled.div`
  position: absolute;
  background: #15171c;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Layout = (props) => {
    let isMobile = Boolean(navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ))

    const [sidebar, setSidebar] = useState(isMobile ? false : true);
    const showSidebar = () => setSidebar(!sidebar);

    useEffect(() => {
      let time = 0;
      const removeRealodAction = () => time = 0;
      const timerIncrement = () => {
        time = time + 1;
        if (time > 240) {
          props.logout();
        }
      }
      
      const intervalId = setInterval(timerIncrement, 60000);
      document.body.addEventListener('mousemove', removeRealodAction, false)
      document.body.addEventListener('keypress', removeRealodAction, false)

      return () => {
        clearInterval(intervalId);
        document.body.removeEventListener('mousemove', removeRealodAction, false)
        document.body.removeEventListener('keypress', removeRealodAction, false)
      }
    },[])

    return (
        <div className="wrapper d-flex align-items-stretch">
            <Nav className="navbar-phone">
              <NavIcon to='#' onClick={(e) => {e.preventDefault();showSidebar()}}>
                <FontAwesomeIcon icon={faBars} size="lg" style={{marginLeft: 10}}  />
              </NavIcon>
            </Nav>
            <nav id="sidebar">
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to='/admin/index'>
                            <SidebarLogo />
                        </NavIcon>
                        {props.token ? 
                          <>
                            <CardUser />
                            {SidebarData.map((item, index) => (
                                <div key={index}>
                                    <p style={{ paddingLeft: 20, color: '#FFEB11', fontSize: 12,  fontFamily: 'Roboto', marginBottom: 5, marginTop: 20 }}>{item.title}</p>
                                    {item.navs.map((nav, i) => {
                                        return <SubMenu item={nav} key={i} />
                                    })}
                                </div>
                            ))}
                          </>
                        :
                          <SidebarLink onClick={(e) => {}} to="/sales/index" open style={{marginTop: 38}}>
                            <div>
                              <FontAwesomeIcon icon={faIdCard} size="lg" />
                              <SidebarLabel>Membership</SidebarLabel>
                            </div>
                          </SidebarLink>
                        }
                    </SidebarWrap>
                </SidebarNav>
            </nav>
            <div className="content" style={{ paddingLeft: 20, paddingTop: 21 }} onClick={() => isMobile && setSidebar(false)}>
                {props.children}
                <ModalProfile />
                <ModalSupport />
            </div>
            <Toaster 
                position="top-center"
                toastOptions={{
                    success: {
                      style: {
                        background: '#61d345',
                        color: '#FFF'
                      },
                    },
                    error: {
                      style: {
                        background: '#ff4b4b',
                        color: '#FFF'
                      },
                    },
                }}
            />
        </div>
    );
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  token: state.auth.token
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);