import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import CloseModal from '../assets/close-modal.png';
import Calendar from '../assets/calendar-icon.png';
import Card from '../assets/card-membership.png';
import toast from 'react-hot-toast';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { config } from '../config';
import axios from 'axios';
import Pagination from '../components/Pagination/Pagination';
import { format_currency, validate_session } from '../utils';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import Select from 'react-select';

const Memberships = (props) => {
    const module = 'Memberships';
    const [memberships, setMemberships] = useState([]);
    const [beaches, setBeaches] = useState([]);
    const [plans, setPlans] = useState([]);

    const [ID, setID] = useState('');
    const [owner, setOwner] = useState('');
    const [affiliates, setAffiliates] = useState([]);
    const [duration, setDuration] = useState('');
    const [affiliate, setAffiliate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [beach, setBeach] = useState('');
    const [plan, setPlan] = useState('');
    const [price, setPrice] = useState('');
    const [errors, setErrors] = useState({});
    const [products, setProducts] = useState([]);
    const [id, setId] = useState('');
    const [details, setDetails] = useState({});
    const [showCreate, setShowCreate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [productsOptions, setProductsOptions] = useState([]);
    const [status, setStatus] = useState('ACTIVE');

    const onShowCreate = () => {
        if(permissions() && permissions().actionCreate){
            getCodeSonsecutive();
            setShowCreate(!showCreate);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDelete = () => {
        if(permissions() && permissions().actionDelete){
            setShowDelete(!showDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDetails = () => setShowDetails(!showDetails);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(memberships.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, memberships, PageSize]);

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(memberships.length){
                memberships.map(mb => {
                    data.push({ ID: mb.code, OWNER: mb.owner, AFFILIATES: mb.affiliates.length, START_DATE: moment(mb.startDate).format('MMM DD, YYYY'), END_DATE: moment(mb.endDate).format('MMM DD, YYYY'), PLAN: mb.plan.name, DURATION: mb.plan.duration, PRICE: format_currency(mb.plan.price) });
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Memberships PDF";
            const headers = [['ID', 'OWNER', 'AFFILIATES', 'START DATE', 'END DATE', 'PLAN', 'DURATION', 'PRICE']];
        
            const data = memberships.map(mb => [mb.code, mb.owner, mb.affiliates.length, moment(mb.startDate).format('MMM DD, YYYY'), moment(mb.endDate).format('MMM DD, YYYY'), mb.plan.name, mb.plan.duration, format_currency(mb.plan.price)]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("memberships-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `memberships-estebans-place` + fileExtension);
    };

    const refreshProducts = async () => {
        try{
            const response = await axios.get(`${config.URI}/products`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                const data = response.data.data;
                if(data.length){
                    setProductsOptions(data.map(item => { return { 'value': item._id, 'label': item.name } }));
                }
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/memberships`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setMemberships(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    const refreshBeaches = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    const refreshPlans = async () => {
        try{
            const response = await axios.get(`${config.URI}/membership-plans`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setPlans(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    useEffect(() => {
        refreshData();
        refreshProducts();
        refreshPlans();
    },[]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const mb_search = reply.filter(item => item.owner.toLowerCase().search(value.toLowerCase()) !== -1 || item.plan.name.toLowerCase().search(value.toLowerCase()) !== -1);
            setMemberships(mb_search);
            setCurrentPage(1);
        }
        if(value === ''){
            refreshData();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    const [productsDefault, setProductDefault] = useState([]);

    const onModalEdit = (item) => {
        setOwner(item.owner);
        setID(item.code);
        setStartDate(new Date(item.startDate));
        setEndDate(new Date(item.endDate));
        setAffiliates(item.affiliates);
        if(item.products){
            setProductDefault(item.products.map(pt => { return { value: pt._id, label: pt.name } }));
            setProducts(item.products.map(pt => pt._id));
        }
        // setBeach(item.beach._id);
        setPlan(item.plan._id);
        setId(item._id);
        onShowEdit();
    }

    const onDetailView = (item) => {
        setDetails(item);
        onShowDetails();
    }

    const clean = () => {
        setOwner('');
        setStartDate('');
        setEndDate('');
        setAffiliates([]);
        setAffiliate('');
        setPlan('');
        setBeach('');
        setPrice('');
        setId('');
    }

    const onSubmitCreateMembership = async () => {
        if(validate_field()){
            onShowCreate();
            const toastId = toast.loading('Loading...');
            try{
                const response = await axios.post(`${config.URI}/memberships`, { code: ID, owner, affiliates, plan, startDate, endDate, products, status },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
                if(response.statusText === 'OK'){
                    refreshData();
                    clean();
                    toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
                toast.dismiss(toastId);
            }catch(e){
                console.log(e);
                messageError();
                toast.dismiss(toastId);
            }
        }
    }

    const onSubmitEditMembership = async () => {
        if(validate_field()){
            onShowEdit();
            const toastId = toast.loading('Loading...');
            try{
                const response = await axios.put(`${config.URI}/memberships/${id}`, { code: ID, owner, affiliates, plan, startDate, endDate, products, status },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
                if(response.statusText === 'OK'){
                    refreshData();
                    clean();
                    toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
                toast.dismiss(toastId);
            }catch(e){
                console.log(e);
                messageError();
                toast.dismiss(toastId);
            }
        }
    }

    const onSubmitDelete = async () => {
        onShowDelete();
        const toastId = toast.loading('Loading...');
        try{
            const response = await axios.delete(`${config.URI}/memberships/${id}`, { headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                clean();
                toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }catch(e){
            console.log(e);
            messageError();
        }
        toast.dismiss(toastId);
    }

    const validate_field = () => {
        let mbp = [];
        if(plan !== ''){
            mbp = plans.filter(mb => mb._id === plan);
        }
        if(owner === ''){
            messageErrorMessage(`Invalid owner input`);
        // }else if(beach === ''){
        //     messageErrorMessage(`Don't select beach`);
        }else if(plan === ''){
            messageErrorMessage(`Don't select plan`);
        }else if(mbp.length && mbp[0].type === 'unlimited' && affiliates.length > 0){
            messageErrorMessage(`No membership in the unlimited plan is required`);
        }else if(startDate === ''){
            messageErrorMessage(`Don't select start date`);
        }else if(endDate === ''){
            messageErrorMessage(`Don't select end date`);
        }else{
            return true;
        }
    }

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" value={value ? moment(value).format('MMM DD, YYYY') : ' '} onClick={onClick} ref={ref} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
    ));

    useEffect(() => {
        if(plan !== ''){
            const mbp = plans.filter(mb => mb._id === plan);
            if(mbp.length > 0){
                setPrice(mbp[0].price);
                const added = mbp[0].duration.split(' ');
                setDuration(mbp[0].duration);
                setStartDate(new Date());
                setEndDate(new Date(moment().add((+added[0]), added[1])));
            }
        }
    },[plan]);

    const onChangeStartDate = (date) => {
        if(startDate !== '' && plan !== ''){
            const added = duration.split(' ');
            setStartDate(date);
            setEndDate(new Date(moment(date).add((+added[0]), added[1])));
        }else{
            messageErrorMessage(`Don't select Membership plan`);
        }
    }

    const removeAffiliate = (index) => {
        const reply = affiliates.filter((af, i) => i !== index);
        setAffiliates(reply);
    }

    const download = () => {
        html2canvas(document.getElementById('card-membership')).then(canvas => 
            saveAs(canvas.toDataURL(), 'membership-card.png')
        );
    }

    const saveAs = (uri, filename) => {
        var link = document.createElement('a');
        if(typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }else{
            window.open(uri);
        }
    }

    const getCodeSonsecutive = () => {
        if(memberships.length){
            const later = memberships[memberships.length - 1].code;
            const nv = (+later) + 1;
            setID(code_consecutive(nv));
        }else{
            setID('0001');
        }
    }

    const code_consecutive = (actual) => {
        const code = actual + "";
        let format = '';
        for(let i = code.length; i < 4; i++){
            format += '0';
        }
        return format + code;
    }

    return(
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Memberships</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onShowCreate()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Id'}, {name: 'Owner'}, {name: 'Affiliates'}, {name: 'Start date'}, {name: 'End date'}, {name: 'Plan'}, {name: 'Duration'}, {name: 'Price'}, {name: 'Actions'}]} />
                        {memberships.length > 0 && (
                            <>
                                {currentTableData.map((mb, i) => (
                                    <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                        <Column className="rowBorderStart">{mb.code}</Column>
                                        <Column className="rowBorderMiddle">{mb.owner}</Column>
                                        <Column className="rowBorderMiddle"><Link onClick={(e) => { e.preventDefault(); onDetailView(mb);}} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#1A1A1A' }} >{mb.affiliates.length} <FontAwesomeIcon icon={faEye} /></Link></Column>
                                        {/* <Column className="rowBorderMiddle">{mb.beach.name}</Column> */}
                                        <Column className="rowBorderMiddle">{moment(mb.startDate).format('MMM DD, YYYY')}</Column>
                                        <Column className="rowBorderMiddle">{moment(mb.endDate).format('MMM DD, YYYY')}</Column>
                                        <Column className="rowBorderMiddle">{mb.plan.name}</Column>
                                        <Column className="rowBorderMiddle">{mb.plan.duration}</Column>
                                        <Column className="rowBorderMiddle">{format_currency(mb.plan.price)}</Column>
                                        <Column className="rowBorderEnd">
                                            <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                <TextAction onClick={() => onDetailView(mb)}>Details&nbsp;</TextAction>-<TextAction onClick={() => onModalEdit(mb)}>&nbsp;Edit&nbsp;</TextAction>-<TextAction onClick={() => {onShowDelete();setId(mb._id)}}>&nbsp;Delete</TextAction>
                                            </div>
                                        </Column>
                                    </ContentBody>
                                ))}
                        </>
                        )}
                    </Table>
                    {memberships.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {memberships.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={memberships.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showCreate} onHide={onShowCreate} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowCreate();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Create Membership</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>ID</Form.Label>
                        <Form.Control type="text" onChange={(e) => setID(e.target.value)} defaultValue={ID} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Owner</Form.Label>
                        <Form.Control type="text" onChange={(e) => setOwner(e.target.value)} defaultValue={owner} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Add Affiliates</Form.Label>
                        <Form.Control type="text" onChange={(e) => setAffiliate(e.target.value)} onKeyDown={(e) => { if(e.key === 'Enter'){ const reply = affiliates; if(reply.length < 4){ if(affiliate !== ''){ reply.push(affiliate); setAffiliates(reply); setAffiliate(''); } }else{ messageErrorMessage('Maximum number of affiliates 4.') } } }} value={affiliate} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    {affiliates.length > 0 &&
                        affiliates.map((afl, i) => (
                            <div style={{ border: '1px solid #4FB4E6', backgroundColor: '#4FB4E6', height: 45, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} key={i} className="d-flex justify-content-between">
                                <div className="d-flex" style={{alignItems: 'center'}}>
                                    <TextDetailsCard>{afl}</TextDetailsCard>
                                </div>
                                <div className="d-flex mr-3">
                                    <p style={{color: '#FFF', fontSize: 14, fontFamily: 'Roboto', marginBottom: 0, cursor: 'pointer'}} onClick={() => removeAffiliate(i)}>X</p>
                                </div>
                            </div>
                        ))
                    }
                    {/* <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beach</Form.Label>
                        <Form.Control as="select" onChange={(e) => setBeach(e.target.value)} defaultValue={beach} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {beaches.map((beach) => (
                                <option value={beach._id}>{beach.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Products included</Form.Label>
                        <Select options={productsOptions} onChange={(tags) => setProducts(tags.map(t => t.value))} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Plan</Form.Label>
                        <Form.Control as="select" onChange={(e) => setPlan(e.target.value)} defaultValue={plan} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {plans.map((pln) => (
                                <option value={pln._id}>{pln.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Status</Form.Label>
                        <Form.Control as="select" onChange={(e) => setStatus(e.target.value)} defaultValue={status} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.status ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                        </Form.Control>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Start date</Form.Label>
                                <div>
                                    <DatePicker onChange={(date) => onChangeStartDate(date)} selected={startDate} customInput={<DatePickerInput />} />
                                    <img src={Calendar} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>End date</Form.Label>
                                <div>
                                    <DatePicker onChange={(date) => setEndDate(date)} selected={endDate} minDate={new Date()} customInput={<DatePickerInput />} />
                                    <img src={Calendar} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {price !== '' &&
                    <div style={{marginBottom: 17.5, marginTop: 30}}>
                        <TextPriceTotal>{format_currency(price)}</TextPriceTotal>
                    </div>}
                    <div className={price === '' ? "text-center mt-65 mb-40" : "text-center mb-40"}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowCreate();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitCreateMembership()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showEdit} onHide={onShowEdit} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowEdit();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Edit Membership</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>ID</Form.Label>
                        <Form.Control type="text" onChange={(e) => setID(e.target.value)} defaultValue={ID} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Owner</Form.Label>
                        <Form.Control type="text" onChange={(e) => setOwner(e.target.value)} defaultValue={owner} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Add Affiliates</Form.Label>
                        <Form.Control type="text" onChange={(e) => setAffiliate(e.target.value)} onKeyDown={(e) => { if(e.key === 'Enter'){ const reply = affiliates; if(reply.length < 4){ if(affiliate !== ''){ reply.push(affiliate); setAffiliates(reply); setAffiliate(''); } }else{ messageErrorMessage('Maximum number of affiliates 4.') } } }} value={affiliate} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    {affiliates.length > 0 &&
                        affiliates.map((afl, i) => (
                            <div style={{ border: '1px solid #4FB4E6', backgroundColor: '#4FB4E6', height: 45, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} key={i} className="d-flex justify-content-between">
                                <div className="d-flex" style={{alignItems: 'center'}}>
                                    <TextDetailsCard>{afl}</TextDetailsCard>
                                </div>
                                <div className="d-flex mr-3">
                                    <p style={{color: '#FFF', fontSize: 14, fontFamily: 'Roboto', marginBottom: 0, cursor: 'pointer'}} onClick={() => removeAffiliate(i)}>X</p>
                                </div>
                            </div>
                        ))
                    }
                    {/* <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beach</Form.Label>
                        <Form.Control as="select" onChange={(e) => setBeach(e.target.value)} defaultValue={beach} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {beaches.map((beach) => (
                                <option value={beach._id}>{beach.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Products included</Form.Label>
                        <Select options={productsOptions} defaultValue={productsDefault} onChange={(tags) => setProducts(tags.map(t => t.value))} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Plan</Form.Label>
                        <Form.Control as="select" onChange={(e) => setPlan(e.target.value)} defaultValue={plan} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {plans.map((pln) => (
                                <option value={pln._id}>{pln.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Status</Form.Label>
                        <Form.Control as="select" onChange={(e) => setStatus(e.target.value)} defaultValue={status} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.status ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                        </Form.Control>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Start date</Form.Label>
                                <div>
                                    <DatePicker onChange={(date) => onChangeStartDate(date)} selected={startDate} customInput={<DatePickerInput />} />
                                    <img src={Calendar} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>End date</Form.Label>
                                <div>
                                    <DatePicker onChange={(date) => setEndDate(date)} selected={endDate} minDate={new Date()} customInput={<DatePickerInput />} />
                                    <img src={Calendar} style={{ height: 24, width: 21, position: 'absolute', top: 42, right: 30 }} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{marginBottom: 17.5, marginTop: 30}}>
                        <TextPriceTotal>{format_currency(price)}</TextPriceTotal>
                    </div>
                    <div className="text-center mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowEdit();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitEditMembership()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDelete} onHide={onShowDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete Membership</TitleModal>
                    <TextModal className="mb-40 mt-30">Please confirm membership deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div className="text-center mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => onShowDelete()}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} width={'149px'} onClick={() => onSubmitDelete()} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDetails} onHide={onShowDetails} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDetails()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Membership Details</TitleModal>
                    <div style={{marginTop: 17, position: 'relative'}} id="card-membership">
                        <CardMembership src={Card} />
                        <div style={{position: 'absolute', height: '100%', width: '100%', top: 0, zIndex: 2}}>
                            <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'right', marginTop: 14, marginRight: 18}}>{details.code && details.code}</p>
                            <h1 style={{marginBottom: 0, fontSize: 39, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center'}}>{details.plan && details.plan.name}</h1>
                            <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'center'}}>VALID UNTIL: {details.endDate && moment(details.endDate).format('MMMM DD, YYYY').toUpperCase()}</p>
                            <div className="d-flex justify-content-between" style={{ marginLeft: 30, marginRight: 29, marginTop: 23 }}>
                                <div>
                                    <h3 style={{marginBottom: 0, fontSize: 20, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto'}}>{details.plan && details.plan.type === 'unlimited' ? 'Unlimited' : details.owner}</h3>
                                    {details.affiliates &&
                                        details.affiliates.map((af, i) => (
                                            <h3 style={{marginBottom: 0, fontSize: 16, color: '#000', lineHeight: 1, fontFamily: 'Roboto', marginTop: 5}} key={i}>{af}</h3>
                                        ))
                                    }
                                </div>
                                <QRCode value={details._id && details._id} size={143} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-65 mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowDetails();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => download()} width={'149px'} color={'#FFF'}>Download</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const CardMembership = styled.img`
width: 540px;
`;
const TextPriceTotal = styled.h1`
font-size: 32px;
line-height: 36px;
text-align: center;
font-family: 'Arial';
color: #000;
margin-bottom: 0;
`;

const TextDetailsCard = styled.p`
font-size: 14px;
padding-left: 14px !important;
font-family: 'Roboto';
color: #FFF;
margin-bottom: 0 !important;
`;
const TextDetails = styled.td`
font-size: 14px;
padding-left: 30px !important;
font-family: 'Roboto';
text-transform: uppercase;
color: #1A1A1A;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData:  state.auth.userData
});

export default connect(mapStateToProps)(Memberships)