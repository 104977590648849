export const showProfile = () => {
    return{
        type: 'MODAL_PROFILE_SHOW',
    }
}

export const hidenProfile = () => {
    return{
        type: 'MODAL_PROFILE_HIDEN',
    }
}

export const showSupport = () => {
    return{
        type: 'MODAL_SUPPORT_SHOW',
    }
}

export const hidenSupport = () => {
    return{
        type: 'MODAL_SUPPORT_HIDEN',
    }
}