import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import CloseModal from '../assets/close-modal.png';
import toast from 'react-hot-toast';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { config } from '../config';
import axios from 'axios';
import Calendar from '../assets/calendar-icon.png';
import Pagination from '../components/Pagination/Pagination';
import { format_currency, validate_session } from '../utils';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const Billing = (props) => {
    const module = 'Partnerships';
    const [search, setSearch] = useState('');
    const [billings, setBillings] = useState([]);
    const [reply, setReply] = useState([]);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const hanlderModalDetails = () => setShowModalDetails(!showModalDetails);

    const [showEdit, setShowEdit] = useState(false);
    const showModalEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(billings.length){
                billings.map(billing => {
                    data.push({ DATE_TIME: moment(billing.date).format('MMM DD, YYYY h:mm a').toUpperCase(), BEACH: billing.partner.beach.name, PARTNER: billing.partner.name, UNIT: billing.unit, TOTAL: format_currency(( billing.partner.typeCost === 'month' ? ((+billing.partner.price) / 30): (+billing.partner.price)) * billing.unit)});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Billing PDF";
            const headers = [['DATE TIME', 'BEACH', 'Partner', 'UNIT', 'TOTAL']];
        
            const data = billings.map(billing => [moment(billing.date).format('MMM DD, YYYY h:mm a').toUpperCase(), billing.partner.beach.name, billing.partner.name, billing.unit, format_currency(( billing.partner.typeCost === 'month' ? ((+billing.partner.price) / 30): (+billing.partner.price)) * billing.unit)]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("billing-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `billing-estebans-place` + fileExtension);
    };

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(billings.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, billings, PageSize]);

    const onRefresh = async () => {
        try{
            const response = await axios.get(`${config.URI}/billing-partner`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                console.log(response.data.data)
                setBillings(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        onRefresh();
    },[])

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const billing_search = reply.filter(b => b.partner.beach.name.toLowerCase().search(value.toLowerCase()) !== -1 || b.partner.name.toLowerCase().search(value.toLowerCase()) !== -1);
            setBillings(billing_search);
            setCurrentPage(1);
        }
        if(value === ''){
            onRefresh();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    const [total, setTotal] = useState({ total: 0, unit: 0 })

    useEffect(() => {
        if(billings.length){
            const totals = billings.reduce((prev, billing) => prev +  ((billing.partner.typeCost === 'month' ? ((+billing.partner.price) / 30): (+billing.partner.price)) * billing.unit), 0);
            const units = billings.reduce((prev, billing) => prev + billing.unit, 0);
            setTotal({ total: totals, unit: units });
        }
    },[billings]);

    const [idUpdate, setUpdateId] = useState('');
    const [unit, setUnit] = useState('');
    const [details, setDetails] = useState({});

    const onSubmitEditBilling = async () => {
        if(validation_field()){
            showModalEdit();
            const toastId = toast.loading('Loading...');
            const response = await axios.put(`${config.URI}/billing-partner/${idUpdate}`, { unit }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                onRefresh();
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validation_field = () => {
        if(unit === '' || unit === 0){
            messageErrorMessage(`Invalid unit input.`);
            return false;
        }
        return true;
    }

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" value={value ? moment(value).format('MMM DD, YYYY h:mm a') : ' '} onClick={onClick} ref={ref} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
    ));

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Billing</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Date and time'}, {name: 'Beach'}, {name: 'Partner'}, {name: 'OCCUPIED UNITS'}, {name: 'total'}, {name: 'Billing type'}, {name: 'actions'}]} />
                        {billings.length > 0 &&
                            currentTableData.map((billing, i) =>(
                                <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                    <Column className="rowBorderStart">{moment(billing.date).format('MMM DD, YYYY h:mm a').toUpperCase()}</Column>
                                    <Column className="rowBorderMiddle">{billing.partner.beach.name}</Column>
                                    <Column className="rowBorderMiddle">{billing.partner.name}</Column>
                                    <Column className="rowBorderMiddle">{billing.unit}</Column>
                                    <Column className="rowBorderMiddle">{format_currency((+billing.partner.price) * billing.unit)}</Column>
                                    <Column className="rowBorderMiddle">{billing.partner && billing.partner.typeCost.charAt(0).toUpperCase() + billing.partner.typeCost.slice(1)}</Column>
                                    <Column className="rowBorderEnd">
                                        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                            <TextAction onClick={() => {setDetails({ ...billing }); hanlderModalDetails();}}>Details&nbsp;</TextAction>-<TextAction onClick={() => { setDetails({...billing}); setUnit(billing.unit); setUpdateId(billing._id); showModalEdit();}}>&nbsp;Edit&nbsp;</TextAction>{/*-<TextAction onClick={() => {}}>&nbsp;Delete</TextAction> */}
                                        </div>
                                    </Column>
                                </ContentBody>
                            ))
                        }
                    </Table>
                    {billings.length > 0 &&
                        <>
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {billings.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={billings.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                        <Table className="table-curved">
                            <ContentBody key={1} backgroundColor={'#1A1A1A'} className="text-center">
                                <Column className="rowBorderStart" style={{minWidth: 140}}></Column>
                                <Column className="rowBorderMiddle"></Column>
                                <Column className="rowBorderMiddle"></Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFEB11', fontWeight: 'bold'}}></Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFF'}}>UNIT</Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFF'}}>TOTAL</Column>
                                <Column className="rowBorderEnd" style={{minWidth: 40}}></Column>
                            </ContentBody>
                            <ContentBody key={1} backgroundColor={'#1A1A1A'} className="text-center">
                                <Column className="rowBorderStart" style={{minWidth: 140}}></Column>
                                <Column className="rowBorderMiddle"></Column>
                                <Column className="rowBorderMiddle"></Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFEB11', fontWeight: 'bold'}}>TOTAL</Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{total.unit}</Column>
                                <Column className="rowBorderMiddle" style={{color: '#FFF'}}>{format_currency(total.total)}</Column>
                                <Column className="rowBorderEnd" style={{minWidth: 40}}></Column>
                            </ContentBody>
                        </Table>
                        </>

                    }
                </div>
            </div>
            <Modal show={showEdit} onHide={showModalEdit} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginTop: 16, marginRight: 16, }} onClick={() => showModalEdit()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Edit Billing</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Date</Form.Label>
                        <div style={{position: 'relative'}}>
                            <Form.Control readOnly type="text" value={details.date ? moment(details.date).format('MMM DD, YYYY h:mm a') : ' '} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                            <img src={Calendar} style={{ height: 24, width: 21, position: 'absolute', top: 12, right: 12 }} />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beach</Form.Label>
                        <Form.Control readOnly type="text" value={details.partner ? details.partner.beach.name : ''} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Partner</Form.Label>
                        <Form.Control readOnly type="text" value={details.partner ? details.partner.name : ''} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Unit</Form.Label>
                        <Form.Control type="text" value={unit} onChange={(e) => !/^[0-9]+$/.test(e.target.value) && e.target.value !== '' ? {} : setUnit(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Total</Form.Label>
                        <Form.Control readOnly type="text" value={details.partner ? format_currency(( details.partner.typeCost === 'month' ? ((+details.partner.price) / 30): (+details.partner.price)) * details.unit) : ''} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => {showModalEdit();}} paddingVertical={'0.5rem'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} width={'149px'} onClick={() => onSubmitEditBilling()} paddingVertical={'0.5rem'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showModalDetails} onHide={hanlderModalDetails} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 15, marginTop: 16 }} onClick={() => hanlderModalDetails()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40 }}>
                    <TitleModal>Billing Details</TitleModal>
                </div>
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <tbody>
                        <tr>
                            <TitleDetails>DATE</TitleDetails>
                            <td>{details.date ? moment(details.date).format('MMM DD, YYYY h:mm a') : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>BEACH</TitleDetails>
                            <td>{details.partner ? details.partner.beach.name : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>PARTNER</TitleDetails>
                            <td>{details.partner ? details.partner.name : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>UNIT</TitleDetails>
                            <td>{details.unit ? details.unit : ''}</td>
                        </tr>
                        <tr>
                            <TitleDetails>TOTAL</TitleDetails>
                            <td>{details.partner ? format_currency(( details.partner.typeCost === 'month' ? ((+details.partner.price) / 30): (+details.partner.price)) * details.unit) : ''}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width="149px" onClick={() => hanlderModalDetails()}>Close</Button>
                </div>
            </Modal>
        </Layout>
    )
}

const TextDetails = styled.td`
font-size: 14px;
padding-left: 30px !important;
font-family: 'Roboto';
text-transform: uppercase;
color: #1A1A1A;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
text-align: center;
`;
const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(Billing);