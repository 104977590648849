import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../assets/icon.png';
import { config } from '../../config';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/auth-action';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const LoginWithEmail = (props) => {

    const [forgot, setForgot] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const handlerSubmit = async () => {
        if(email !== '' && password !== ''){
            setErrors({});
            try{
                const response = await axios.post(`${config.URI}/auth`, { username: email, password, type: 'Email', platform: 'Desktop' }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' }});
                if(response.statusText === 'OK'){
                    if(response.data.status === 'VERIFIED'){
                        const data = response.data.data;
                        props.loginSuccess(data, response.data.token);
                    }
                }
            }catch(e){
                console.log(e)
                if(e.response){
                    toast.error((t) => (<span>{e.response.data.message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }
        }else{
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(email === '' || !re.test(email)){
                toast.error((t) => (<span>Invalid email input.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                setErrors({ email: true });
            }else if(password === ''){
                toast.error((t) => (<span>Invalid password input.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                setErrors({ password: true });
            }
        }
    }

    const handlerClickForgot = async () => {
        if(email === ''){
            toast.error((t) => (<span>Invalid email input.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
        }else{
            try{
                const response = await axios.post(`${config.URI}/forgot`, { email }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' }});
                if(response.statusText === 'OK'){
                    toast.success((t) => (<span>Sent password reset email.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }catch(e){
                console.log(e)
                if(e.response){
                    toast.error((t) => (<span>{e.response.data.message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }
        }
    }

    return (
        <div className="limter">
            <div className="container-login">
                <div className="wrap-login">
                    <div className="login-form login-dark-back">
                        <div className="top-right-buttons d-flex">
                            <p className="text-top r-12">Have you lost your email?</p>
                            <Link to="/auth/login" className="btn-transparent-pill">
                                <p>Log In with Phone</p>
                            </Link>
                        </div>
                        {!forgot ?
                        (<div className="container">
                            <h1 className="stebans-title text-white">Sign in to Esteban’s Place.</h1>
                            <p className="stebans-subtitle">Please enter your account details below.</p>
                            <div className="form-group m-t-37">
                                <label htmlFor="phone" className="text-form text-white">Email</label>
                                <input type="text" className={"form-control " + (errors.email ? 'form-control-danger' : 'form-control-dark') } autoComplete={false} name="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group m-t-42">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="phone" className="text-form text-white">Password</label>
                                    <label onClick={() => setForgot(true)} className="text-form text-white text-link">Forgot password?</label>
                                </div>
                                <input type="password" name="password" autoComplete={false} onKeyPress={(e) => { if(e.key === 'Enter'){ handlerSubmit(); } }} onChange={(e) => setPassword(e.target.value)} className={"form-control " + (errors.password ? 'form-control-danger' : 'form-control-dark') } />
                            </div>
                            <div className="text-center">
                                <button className="btn-secondary-login m-t-40" onClick={() => handlerSubmit()}>
                                    <p>Sign In</p>
                                </button>
                            </div>
                        </div>)
                        :
                        (<div className="container">
                            <h1 className="stebans-title text-white">Forgot your password?</h1>
                            <p className="stebans-subtitle">Enter your email address below and we'll get you back on track.</p>
                            <div className="form-group m-t-37">
                                <label htmlFor="phone" className="text-form text-white">Email</label>
                                <input type="text" className="form-control form-control-dark" autoComplete={false} name="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="text-center">
                                <button className="btn-secondary-login m-t-40" onClick={() => handlerClickForgot()}>
                                    <p>Request Reset Link</p>
                                </button>
                            </div>
                            <div className="text-center" style={{ marginTop: 13 }}>
                                <label onClick={() => setForgot(false)} className="text-resend text-link">Back to sign in</label>
                            </div>
                        </div>)
                        }
                    </div>
                    <div className="login-more">
                        <img src={Icon} className="logo" />
                    </div>
                </div>
            </div>
            <Toaster
                 position="top-center"
                 toastOptions={{
                    success: {
                        style: {
                            background: '#61d345',
                            color: '#FFF'
                        },
                    },
                    error: {
                        style: {
                            background: '#ff4b4b',
                            color: '#FFF'
                        },
                    },
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,
	isLoading: state.auth.isLoading,
	error: state.auth.error
})

const mapDispatchToProps = dispatch => ({
	loginSuccess: (data, token) => dispatch(actions.loginSuccess(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithEmail);