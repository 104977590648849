import axios from "axios";
import { config } from "../../config";

export const loginSuccess = (userData, token) => {
    return{
        type:'LOGIN_SUCCESS',
        userData,
        token
    }
}

export const _updateData = (data) => {
  return {
    type: 'UPDATE_DATA',
    userData: data
  }
}

export const loginFailed = (error) => {
    return{
        type:'LOGIN_FAILED',
        error
    }
}

export const logout = ( ) => {
  return {
    type: 'LOGOUT',
  }
}

export const updateData = (id, token) => {
  return async (dispatch) => {
    return await axios.get(`${config.URI}/users/${id}`, { headers: { 'Accept': 'application/json', 'Authorization': token }}).then(response => {
      if(response.statusText === 'OK'){
        dispatch(_updateData(response.data.data));
      }
    }).catch(error => console.log(error));
  }
}