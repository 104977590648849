import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import CloseModal from '../assets/close-modal.png';
import toast from 'react-hot-toast';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import MoneyInput from "@rschpdr/react-money-input";
import { config } from '../config';
import axios from 'axios';
import Pagination from '../components/Pagination/Pagination';
import { format_currency, validate_session } from '../utils';

const MembershipPlans = (props) => {
    const module = 'Settings';
    const [membership, setMembership] = useState([]);

    const [name, setName] = useState('');
    const [duration, setDuration] = useState('');
    const [type, setType] = useState('');
    const [price, setPrice] = useState('');
    const [custom, setCustom] = useState('');
    const [errors, setErrors] = useState({});
    const [id, setId] = useState('');
    const [details, setDetails] = useState({});
    const [showCreate, setShowCreate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const onShowCreate = () => {
        if(permissions() && permissions().actionCreate){
            setShowCreate(!showCreate);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDelete = () => {
        if(permissions() && permissions().actionDelete){
            setShowDelete(!showDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDetails = () => setShowDetails(!showDetails);

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(membership.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, membership, PageSize]);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(membership.length){
                membership.map(mb => {
                    data.push({ ID: mb.code, NAME: mb.name, DURATION: mb.duration, PRICE: mb.price, TYPE: mb.type });
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Membership Plans PDF";
            const headers = [['ID', 'NAME', 'DURATION', 'PRICE', 'TYPE']];
        
            const data = membership.map(mb => [mb.code, mb.name, mb.duration, format_currency(mb.price), mb.type]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("membership-plans-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `membership-plans-estebans-place` + fileExtension);
    };

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const clean = () => {
        setName('');
        setDuration('');
        setType('');
        setPrice('');
    }

    const validate_field = () => {
        if(name === ''){
            setErrors({ name: true });
            messageErrorMessage(`Invalid name input`);
        }else if(duration === ''){
            setErrors({ duration: true });
            messageErrorMessage(`Don't select duration`);
        }else if(price === '' || price == '0'){
            setErrors({ price: true });
            messageErrorMessage('Invalid price input');
        }else if(type === ''){
            setErrors({ type: true });
            messageErrorMessage(`Don't select type`);
        }else{
            setErrors({});
            return true;
        }
    }

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/membership-plans`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setMembership(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        refreshData();
    },[])

    const onSubmitCreateMembership = async () => {
        if(validate_field()){
            onShowCreate();
            const toastId = toast.loading('Loading...');
            try{
                const response = await axios.post(`${config.URI}/membership-plans`, { name, price, type, duration: duration === 'custom' ? custom+' days' : duration },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
                if(response.statusText === 'OK'){
                    refreshData();
                    clean();
                    toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
                toast.dismiss(toastId);
            }catch(e){
                console.log(e);
                messageError();
                toast.dismiss(toastId);
            }
        }
    }

    const onSubmitEditMembership = async () => {
        if(validate_field()){
            onShowEdit();
            const toastId = toast.loading('Loading...');
            try{
                const response = await axios.put(`${config.URI}/membership-plans/${id}`, { name, price, type, duration: duration === 'custom' ? custom+' days' : duration },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
                if(response.statusText === 'OK'){
                    refreshData();
                    clean();
                    toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
                toast.dismiss(toastId);
            }catch(e){
                console.log(e);
                messageError();
                toast.dismiss(toastId);
            }
        }
    }

    const onSubmitDelete = async () => {
        onShowDelete();
        const toastId = toast.loading('Loading...');
        try{
            const response = await axios.delete(`${config.URI}/membership-plans/${id}`, { headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                clean();
                toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }catch(e){
            console.log(e);
            messageError();
        }
        toast.dismiss(toastId);
    }

    const onModalEdit = (item) => {
        setName(item.name);
        setType(item.type);
        setDuration(item.duration);
        if(item.duration.indexOf('days') !== -1){
            setCustom(item.duration.split(' ')[0]);
            setDuration('custom');
        }
        setPrice(item.price);
        setId(item._id);
        onShowEdit();
    }

    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const mb_search = reply.filter(item => item.name.toLowerCase().search(value.toLowerCase()) !== -1 || item.duration.toLowerCase().search(value.toLowerCase()) !== -1);
            setMembership(mb_search);
            setCurrentPage(1);
        }
        if(value === ''){
            refreshData();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    const onDetailView = (item) => {
        setDetails(item);
        onShowDetails();
    }

    const validateInputNumber = (evt) => {
        const match = /^[0-9]+$/;
        if(evt.target.value === ''){
            setCustom('');
        }
        if(evt.target.value.match(match)) {
            setCustom(evt.target.value)
        }
    }

    return(
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Settings - Membership Plans</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onShowCreate()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Code'}, {name: 'Name'}, {name: 'Duration'}, {name: 'Price'}, {name: 'Type'}, { name: 'Actions'}]} />
                        {membership.length > 0 && (
                            <>
                                {currentTableData.map((mb, i) => (
                                    <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                        <Column className="rowBorderStart">{mb.code}</Column>
                                        <Column className="rowBorderMiddle">{mb.name}</Column>
                                        <Column className="rowBorderMiddle">{mb.duration.split(' ')[0] + ' ' + mb.duration.split(' ')[1].substr(0, 1).toUpperCase() + mb.duration.split(' ')[1].substr(1)}</Column>
                                        <Column className="rowBorderMiddle">{format_currency(mb.price)}</Column>
                                        <Column className="rowBorderMiddle">{mb.type === 'with id' ? 'With ID' : mb.type.substr(0, 1).toUpperCase() + mb.type.substr(1)}</Column>
                                        <Column className="rowBorderEnd">
                                            <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                <TextAction onClick={() => onDetailView(mb)}>Details&nbsp;</TextAction>-<TextAction onClick={() => onModalEdit(mb)}>&nbsp;Edit&nbsp;</TextAction>-<TextAction onClick={() => {onShowDelete();setId(mb._id)}}>&nbsp;Delete</TextAction>
                                            </div>
                                        </Column>
                                    </ContentBody>
                                ))}
                        </>
                        )}
                    </Table>
                    {membership.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {membership.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={membership.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showCreate} onHide={onShowCreate} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowCreate();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Create Membership Plan</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Duration</Form.Label>
                        <Form.Control as="select" onChange={(e) => setDuration(e.target.value)} defaultValue={duration} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            <option value="1 week">1 Week</option>
                            <option value="2 weeks">2 Weeks</option>
                            <option value="1 month">1 Month</option>
                            <option value="2 months">2 Months</option>
                            <option value="3 months">3 Months</option>
                            <option value="4 months">4 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="1 year">1 Year</option>
                            <option value="custom">Custom</option>
                        </Form.Control>
                    </Form.Group>
                    {duration === 'custom' &&
                        <Form.Group>
                            <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Custom Duration (Days)</Form.Label>
                            <Form.Control type="text" onChange={(e) => validateInputNumber(e)} value={custom} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Price</Form.Label>
                        <MoneyInput onChange={(e) => setPrice(e.target.value)} value={price} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Type</Form.Label>
                        <Form.Control as="select" onChange={(e) => setType(e.target.value)} defaultValue={type} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            <option value="unlimited">Unlimited</option>
                            <option value="with id">With ID</option>
                        </Form.Control>
                    </Form.Group>
                    <div className="text-center mt-65 mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowCreate();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitCreateMembership()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDelete} onHide={onShowDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete Membership Plan</TitleModal>
                    <TextModal className="mb-40 mt-30">Please confirm membership plan deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div className="text-center mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => onShowDelete()}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} width={'149px'} onClick={() => onSubmitDelete()} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showEdit} onHide={onShowEdit} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowEdit();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Edit Membership Plan</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} value={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Duration</Form.Label>
                        <Form.Control as="select" onChange={(e) => setDuration(e.target.value)} value={duration} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            <option value="1 week">1 Week</option>
                            <option value="2 weeks">2 Weeks</option>
                            <option value="1 month">1 Month</option>
                            <option value="2 months">2 Months</option>
                            <option value="3 months">3 Months</option>
                            <option value="4 months">4 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="1 year">1 Year</option>
                            <option value="custom">Custom</option>
                        </Form.Control>
                    </Form.Group>
                    {duration === 'custom' &&
                        <Form.Group>
                            <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Custom Duration (Days)</Form.Label>
                            <Form.Control type="text" onChange={(e) => validateInputNumber(e)} value={custom} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Price</Form.Label>
                        <MoneyInput onChange={(e) => setPrice(e.target.value)} value={price} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Type</Form.Label>
                        <Form.Control as="select" onChange={(e) => setType(e.target.value)} value={type} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            <option value="unlimited">Unlimited</option>
                            <option value="with id">With ID</option>
                        </Form.Control>
                    </Form.Group>
                    <div className="text-center mt-65 mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowEdit();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitEditMembership()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDetails} onHide={onShowDetails} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDetails()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Memebership Plan Details</TitleModal>
                </div>
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <tbody>
                        <tr>
                            <TitleDetails>ID</TitleDetails>
                            <TextDetails>{details.code ? details.code : ''}</TextDetails>
                        </tr>
                        <tr>
                            <TitleDetails>NAME</TitleDetails>
                            <TextDetails>{details.name ? details.name : ''}</TextDetails>
                        </tr>
                        <tr>
                            <TitleDetails>DURATION</TitleDetails>
                            <TextDetails>{details.duration ? details.duration.split(' ')[0] + ' ' + details.duration.split(' ')[1].substr(0, 1).toUpperCase() + details.duration.split(' ')[1].substr(1) : ''}</TextDetails>
                        </tr>
                        <tr>
                            <TitleDetails>PRICE</TitleDetails>
                            <TextDetails>{details.price ? format_currency(details.price) : ''}</TextDetails>
                        </tr>
                        <tr>
                            <TitleDetails>TYPE</TitleDetails>
                            <TextDetails>{details.type ? details.type.substr(0, 1).toUpperCase() + details.type.substr(1) : ''}</TextDetails>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width={'149px'} onClick={() => onShowDetails()}>Close</Button>
                </div>
            </Modal>
        </Layout>
    )
}
const TextDetails = styled.td`
font-size: 14px;
padding-left: 30px !important;
font-family: 'Roboto';
text-transform: uppercase;
color: #1A1A1A;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(MembershipPlans);