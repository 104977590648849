import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import { Form , Col, Row} from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';
import { countries, getCountry, getIndivative } from '../utils/countries';
import ReactFlagsSelect from 'react-flags-select';
import { config } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Select from 'react-select';
import InputMask from "react-input-mask";
import MoneyInput from "@rschpdr/react-money-input";
import CloseModal from '../assets/close-modal.png';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import toast from 'react-hot-toast';
import Pagination from '../components/Pagination/Pagination';
import { format_currency, validate_session } from '../utils';

const Users = (props) => {
    const module = 'Users';
    const [beaches, setBeaches] = useState([]);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [beach, setBeach] = useState('');
    const [hourly, setHourly] = useState('');
    const [commission, setCommission] = useState('0');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [indicative, setIndicative] = useState('+1');
    const [country, setCountry] = useState('US');
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [id, setId] = useState('');
    const [details, setDetails] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const [visible, setVisible] = useState(true);
    const [beachesArr, setBeachesArr] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    const [showCreate, setShowCreate] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [showDetail, setShowDetail] = useState(false);
    const onModalDetail = () => setShowDetail(!showDetail);

    const [showEdit, setShowEdit] = useState(false);

    const onModalCreate = () => {
        if(permissions() && permissions().actionCreate){
            setShowCreate(!showCreate);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onModalEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onModalDelete = () => {
        if(permissions() && permissions().actionDelete){
            setShowDelete(!showDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(users.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, users, PageSize]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(users.length){
                users.map(user => {
                    data.push({ ID: user.code, NAME: user.name, ROLE: user.role ? user.role.name : '', BEACH: user.beach ? user.beach.address.full_address : '', HOURLY_RATE: '$'+user.hourlyRate, COMMISION: user.beach && user.beach.commissions === 'General' ? `General (${user.beach.percentage}%)` : user.commission + '%', EMAIL: user.email, PHONE: `${user.indicative}${user.phone}`});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Users PDF";
            const headers = [['ID', 'NAME', 'ROLE', 'BEACH', 'HOURLY RATE', 'COMMISSION', 'EMAIL', 'PHONE']];
        
            const data = users.map(user => [user.code, user.name, user.role ? user.role.name : '', user.beach ? user.beach.address.full_address : '', '$'+user.hourlyRate, user.beach && user.beach.commissions === 'General' ? `General (${user.beach.percentage}%)` : user.commission + '%', user.email, `${user.indicative}${user.phone}`]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("users-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Users-Estebans-place` + fileExtension);
    };

    const updateBeachAndRoles = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            const { data } = await axios.get(`${config.URI}/roles`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token }});
            if(data){
                if(data.status === 'SUCCESS'){
                    setRoles(data.data);
                }
            }
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
            }
        }catch(e){
            console.log(e);
        } 
    }

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/users`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setUsers(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        } 
    }

    useEffect(() => {
        updateBeachAndRoles();
    },[]);

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        if(beach !== ''){
            (async () => {
                try{
                    const response = await axios.get(`${config.URI}/beaches/${beach}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
                    if(response.statusText === 'OK'){
                        const { data: { data } } = response;
                        if(data.commissions === 'General'){
                            setDisabled(`General (${data.percentage}%)`);
                        }else{
                            setDisabled(false);
                        }
                    }
                }catch(e){
                    console.log(e);
                } 
            })()
        }else{
            setDisabled(false)
        }
    },[beach])

    useEffect(() => {
        setIndicative(getIndivative(country));
    },[country])

    const onSubmitCreateUser = async () => {
        if(validation_field()){
            onModalCreate();
            const toastId = toast.loading('Loading...');
            const response = await axios.post(`${config.URI}/users`, { name, indicative, role, beach, email, phone: phone.replace('(', '').replace(')', '').replace('-','').replace(' ', ''), password, hourlyRate: hourly.toString(), commission, beaches: beachesArr }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                if(response.data.status === 'SUCCESS'){
                    refreshData();
                    setName('');
                    setEmail('');
                    setPhone('');
                    setRole('');
                    setBeach('');
                    setHourly('');
                    setCommission('');
                    setIndicative('+1');
                    setDisabled(false);
                    setPassword('');
                    setBeachesArr([]);
                    setConfirmPassword('');
                    toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const onSubmitDeleteUser = async () => {
        onModalDelete();
        const toastId = toast.loading('Loading...');
        const response = await axios.delete(`${config.URI}/users/${id}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } })
        if(response.statusText === 'OK'){
            refreshData();
            setId('');
            toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
        }else{
            messageError();
        }
        toast.dismiss(toastId);
    }

    const onDelete = (id) => {
        setId(id);
        onModalDelete();
    }

    const onViewDetails = (item) => {
        setDetails({ ...item });
        onModalDetail();
    }

    const onEditUser = (user) => {
        setName(user.name);
        setEmail(user.email);
        setPassword(user.phone);
        setIndicative(user.indicative);
        setCountry(getCountry(user.indicative));
        setPhone(user.phone);
        if(user.role && user.role.platform === 'Mobile'){
            setHourly(user.hourlyRate);
            setCommission(user.commission ? user.commission : '0');
            setBeach(user.beach ? user.beach._id : '');
            if(user.beach && user.beach.commissions === 'General'){
                setDisabled(`General (${user.beach.percentage}%)`);
            }else{
                setDisabled(false);
            }
            if(user.beaches){
                setBeachesArr(user.beaches.map(b => b._id));
            }
        }
        setRole(user.role ? user.role._id : '');
        setId(user._id);
        setPassword('');
        setConfirmPassword('');
        onModalEdit();
    }

    const onSubmitEditUser = async () => {
        if(validation_field()){
            onModalEdit();
            const toastId = toast.loading('Loading...');
            const response = await axios.put(`${config.URI}/users/${id}`, { name, indicative, role, beach, email, phone: phone.replace('(', '').replace(')', '').replace('-','').replace(' ', ''), hourlyRate: hourly.toString(), commission, password, beaches: beachesArr }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                if(response.data.status === 'SUCCESS'){
                    refreshData();
                    setName('');
                    setEmail('');
                    setPhone('');
                    setRole('');
                    setBeach('');
                    setHourly('');
                    setCommission('0');
                    setIndicative('+1');
                    setPassword('');
                    setConfirmPassword('');
                    setDisabled(false);
                    setBeachesArr([]);
                    setId('');
                    toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validation_field = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(name === ''){
            setErrors({ name: true });
            messageErrorMessage(`Invalid name input`);
        }else if(role === ''){
            setErrors({ role: true });
            messageErrorMessage(`Don't selected role`);
        }else if(beach === '' && visible && (roles.filter(r => r._id === role).length > 0 && roles.filter(r => r._id === role)[0].name !== 'Administrator Mobile' && beachesArr.length === 0)){
            setErrors({ beach: true });
            messageErrorMessage(`Don't selected beach`);
        }else if((hourly === '' || hourly == '0') && visible){
            setErrors({ hourly: true });
            messageErrorMessage(`Invalid hourly rate input`);
        }else if((commission === '' || commission === '0') && !disabled && visible && (roles.filter(r => r._id === role).length > 0 && roles.filter(r => r._id === role)[0].name !== 'Administrator Mobile')){
            setErrors({ commission: true });
            messageErrorMessage(`Invalid commission input`);
        }else if(email === '' || !re.test(email)){
            setErrors({ email: true });
            messageErrorMessage(`Invalid email input`);
        }else if(phone.replace('(', '').replace(')', '').replace('-','').replace(' ', '') === '' || (phone.replace('(', '').replace(')', '').replace('-','').replace(' ', '').replace('_', '')).length < 10){
            setErrors({ phone: true });
            messageErrorMessage(`Invalid phone input`);
        }else if(id === '' && password === ''){
            setErrors({ password: true });
            messageErrorMessage(`Invalid password input`);
        }else if(id === '' && password !== confirmPassword){
            setErrors({ confirmpassword: true });
            messageErrorMessage(`Your password and confirm password doesn’t match`);
        }else{
            setErrors({});
            return true;
        }
    }

    const clean = () => {
        setName('');
        setEmail('');
        setPhone('');
        setRole('');
        setBeach('');
        setHourly('');
        setCommission('');
        setIndicative('+1');
        setPassword('');
        setConfirmPassword('');
        setDisabled(false);
        setId('');
        setBeachesArr([]);
        setVisible(true);
        setErrors({});
    }

    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const users_search = reply.filter(item => item.name.toLowerCase().search(value.toLowerCase()) !== -1 || item.role.name.toLowerCase().search(value.toLowerCase()) !== -1 || (item.beach && item.beach.name.toLowerCase().search(value.toLowerCase()) !== -1));
            setUsers(users_search);
            setCurrentPage(1);
        }
        if(value === ''){
            refreshData();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    useEffect(() => {
        if(role !== ''){
            const rol = roles.filter(r => r._id === role && r.platform === 'Desktop');
            if(rol.length > 0){
                setVisible(false);
            }else{
                setVisible(true);
            }
        }else{
            setVisible(true);
        }
    },[role])

    return(
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Users</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onModalCreate()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Id'}, {name: 'name'}, {name: 'role'}, {name: 'beach'}, {name: 'hourly rate'}, {name: 'commission'}, {name: 'email'}, {name:'phone'}, {name: 'actions'}]} />
                        {users.length > 0 &&
                            currentTableData.map((user, i) =>(
                                <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                    <Column className="rowBorderStart">{user.code}</Column>
                                    <Column className="rowBorderMiddle">{user.name}</Column>
                                    <Column className="rowBorderMiddle">{user.role && user.role.name}</Column>
                                    <Column className="rowBorderMiddle">{user.role && user.role.platform === 'Desktop' ? '----' : user.beach && user.beach.name}</Column>
                                    <Column className="rowBorderMiddle">{user.role && user.role.platform === 'Desktop' ? '----' : format_currency(user.hourlyRate)}</Column>
                                    <Column className="rowBorderMiddle">{user.role && user.role.platform === 'Desktop' ? '----' : user.beach && user.beach.commissions === 'General' ? `General (${user.beach.percentage}%)` : user.commission + '%'}</Column>
                                    <Column className="rowBorderMiddle">{user.email}</Column>
                                    <Column className="rowBorderMiddle">{user.phone}</Column>
                                    <Column className="rowBorderEnd" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        <div className="d-flex text-center">
                                            <TextAction onClick={() => onViewDetails(user)}>Details&nbsp;</TextAction>-<TextAction onClick={() => onEditUser(user)}>&nbsp;Edit&nbsp;</TextAction>-<TextAction onClick={() => onDelete(user._id)}>&nbsp;Delete</TextAction>
                                        </div>
                                    </Column>
                                </ContentBody>
                            ))
                        }
                    </Table>
                    {users.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {users.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={users.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showCreate} onHide={onModalCreate} dialogClassName="modal-620w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onModalCreate(); clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Create User</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Role</Form.Label>
                                <Form.Control as="select" onChange={(e) => setRole(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.role ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option>
                                    {roles.length > 0 &&
                                        roles.map((role, key) => (
                                            <option value={role._id} key={key}>{role.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beaches</Form.Label>
                                <Select options={beaches ? beaches.map(b => { return { value: b._id, label: b.name } }) : []} onChange={(tags) => setBeachesArr(tags.map(t => t.value))} placeholder="Select Beaches" isMulti closeMenuOnSelect={false} isClearable={false} styles={{ control: (styles) => ({ ...styles, minHeight: 50, borderRadius: 10, marginRight: 5, minWidth: 200 }), multiValue: (styles) => ({ ...styles, color: '#fff', background: '#4FB4E6', borderRadius: 10, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }), multiValueLabel: (styles) => ({ ...styles, fontSize: 9, color: '#fff', borderRadius: 10 }) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                   {visible && <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Hourly Rate</Form.Label>
                                <MoneyInput onChange={(e) => setHourly(e.target.value)} value={hourly} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.hourly ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission (%)</Form.Label>
                                <Form.Control type="text" onChange={(e) => setCommission(e.target.value)} disabled={disabled ? true : false} value={disabled ? disabled : commission } style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.commission ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Email</Form.Label>
                                <Form.Control type="text" onChange={(e) => setEmail(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.email ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Phone</Form.Label>
                                <div className="d-flex">
                                    <ReactFlagsSelect
                                        selected={country}
                                        customLabels={{ ...countries }}
                                        onSelect={code => {
                                            setCountry(code);
                                        }}
                                        className={'ReactFlagsSelect-module_flagsSelect__2pfa2_mode'}
                                        placeholder={''}
                                    />
                                    <InputMask mask="(999) 999-9999" className="phone-modal" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.password ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Confirm password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.confirmpassword ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onModalCreate(); clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitCreateUser()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDelete} onHide={onModalDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onModalDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete User</TitleModal>
                    <TextModal>Please confirm user deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => onModalDelete()} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} onClick={() => onSubmitDeleteUser()} width={'149px'} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDetail} onHide={onModalDetail} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onModalDetail()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>User Details</TitleModal>
                </div>
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <tbody>
                        <tr>
                            <TitleDetails>ID</TitleDetails>
                            <td>{details._id ? details._id : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>NAME</TitleDetails>
                            <td>{details.name ? details.name : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>ROLE</TitleDetails>
                            <td>{details.role ? details.role.name : ''}</td>
                            <td></td>
                        </tr>
                        {details.role && details.role.platform === 'Mobile' &&
                        <>
                            <tr>
                                <TitleDetails>BEACH</TitleDetails>
                                <td>{details.beach ? details.beach.address.full_address : '' }</td>
                                <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height="28px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                            </tr>
                            <tr>
                                <TitleDetails>HOURLY RATE</TitleDetails>
                                <td>{details.role ? `${format_currency(details.hourlyRate)}` : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>COMMISSION</TitleDetails>
                                <td>{details.beach ? details.beach.commissions === 'General' ? `General (${details.beach.percentage}%)` : details.commission+ '%' : ''}</td>
                                <td></td>
                            </tr>
                        </>
                        }
                        <tr>
                            <TitleDetails>EMAIL</TitleDetails>
                            <td>{details.email ? details.email : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>PHONE</TitleDetails>
                            <td>{details.phone ? `${details.indicative} ${details.phone}` : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Last Login</TitleDetails>
                            <td>{details.createAt ? moment(details.createAt).format('MMM. DD, YYYY - h:mm a') : ''}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width="149px" onClick={() => onModalDetail()}>Close</Button>
                </div>
            </Modal>
            <Modal show={showEdit} onHide={onModalEdit} dialogClassName="modal-620w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onModalEdit(); clean()}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Edit User</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Role</Form.Label>
                                <Form.Control as="select" onChange={(e) => setRole(e.target.value)} defaultValue={role} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.role ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option>
                                    {roles.length > 0 &&
                                        roles.map((role, key) => (
                                            <option value={role._id} key={key}>{role.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beaches</Form.Label>
                                <Select options={beaches ? beaches.map(b => { return { value: b._id, label: b.name } }) : []} defaultValue={beaches ? beaches.map(b => { if(beachesArr.indexOf(b._id) !== -1) return { value: b._id, label: b.name } }) : []} onChange={(tags) => setBeachesArr(tags.map(t => t.value))} placeholder="Select Beaches" isMulti closeMenuOnSelect={false} isClearable={false} styles={{ control: (styles) => ({ ...styles, minHeight: 50, borderRadius: 10, marginRight: 5, minWidth: 200 }), multiValue: (styles) => ({ ...styles, color: '#fff', background: '#4FB4E6', borderRadius: 10, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }), multiValueLabel: (styles) => ({ ...styles, fontSize: 9, color: '#fff', borderRadius: 10 }) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {visible &&
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Hourly Rate</Form.Label>
                                <MoneyInput onChange={(e) => setHourly(e.target.value)} value={hourly} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.hourly ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Commission (%)</Form.Label>
                                <Form.Control type="text" onChange={(e) => setCommission(e.target.value)} disabled={disabled ? true : false} value={disabled ? disabled : commission} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.commission ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Email</Form.Label>
                                <Form.Control type="text" onChange={(e) => setEmail(e.target.value)} defaultValue={email} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.email ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Phone</Form.Label>
                                <div className="d-flex">
                                    <ReactFlagsSelect
                                        selected={country}
                                        customLabels={{ ...countries }}
                                        onSelect={code => {
                                            setCountry(code);
                                        }}
                                        className={'ReactFlagsSelect-module_flagsSelect__2pfa2_mode'}
                                        placeholder={''}
                                    />
                                    <InputMask mask="(999) 999-9999" className="phone-modal" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} defaultValue='*********' style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.password ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Confirm password</Form.Label>
                                <Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)} defaultValue="********" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.confirmpassword ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onModalEdit(); clean()}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitEditUser()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
&:hover{
    background-color: rgba(255, 235, 17, 0.1)
}
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;

const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;

const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;

const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps, null)(Users);