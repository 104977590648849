import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from './routes';

const Admin = (props) => {
    const getRoutes = (routes) => {
        const views = props.userData.role.modules.filter(module => module.actionView === true);
        return routes.map((prop, key) => {
            const action = views.filter(v => v.name.toUpperCase() === prop.path.split('/')[1].toUpperCase() || (v.name === 'Deleted Elements' && prop.path === '/deleted-elements') || (v.name === 'Partnerships' && prop.path === '/partner-billing') || (v.name === 'Time Tracking' && prop.path === '/timetracking') || (prop.path.split('/')[2] === ':id'));
            if((prop.screen === '/admin' && action.length > 0) || (prop.path === '/index' || prop.path === '/logout')){
                return (
                    <Route 
                        path={prop.screen + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }else{
                return null;
            }
        });
    }

    return (
        <>
            <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to={'/admin/index'} />
            </Switch>
        </>
    )
}

const mapStateToProps = (state) => ({
    userData:  state.auth.userData
});

export default connect(mapStateToProps)(Admin);