import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const GoogleMap = (props) => {
    return(
        <Map
            style={{ width: '100%', height: '100%'}}
            containerStyle={{ height: 234, position: 'relative', margin: 0, padding: 0 }}
            initialCenter={{ lat: props.latitude, lng: props.longitude }}
            zoom={10}
            google={props.google}
        >
            <Marker
                title={props.nameMarket}
                position={{lat: props.latitude, lng: props.longitude}} />
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDA1XAJFVIFxWko2uvjEjNQW2IWbjL9F2E'
})(GoogleMap);