export const Desktop = [
    {name: 'Dashboard', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Beaches', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Products', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Partnerships', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Revenue', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Time Tracking', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Memberships', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Users', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'My Profile', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Deleted Elements', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Support', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Settings', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false}
];

export const Mobile = [
    {name: 'Dashboard', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Transactions', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Commissions', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'My Profile', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false},
    {name: 'Support', actionView: false, actionCreate: false, actionEdit: false, actionDelete: false, actionExport: false}
]