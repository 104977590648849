import React from 'react';
import styled from 'styled-components';


const Content = styled.div`
height: 50px;
width: 50px;
`;


const Img = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
object-position: center;
border-radius: 50%;
`;

const Avatar = (props) => {
    return (
        <Content>
            <Img {...props} />
        </Content>
    )
}

export default Avatar;