import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { config } from '../config';
import axios from 'axios';
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { validate_session } from '../utils';

const Notification = (props) => {
    const module = 'Settings';
    const [actions, setActions] = useState([]);
    const [reply, setReply] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    const refresData = async () => {
        try{
            const response = await axios.get(`${config.URI}/notification`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setActions(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    useEffect(() => {
        refresData();
    },[]);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(actions.length){
                actions.map(action => {
                    data.push({ ACTION: action.name, NOTIFICATION_EMAIL: action.notifyEmail ? 'Yes' : 'No', NOTIFICATION_SMS: action.notifySms ? 'Yes' : 'No'});
                });
            }
            exportToCSV(data);
         }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `notifications-estebans-place` + fileExtension);
    };

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Notifications PDF";
            const headers = [['ACTION', 'EMAIL', 'SMS']];
        
            const data = actions.map(action => [action.name, action.notifyEmail ? 'Yes' : 'No', action.notifySms ? 'Yes' : 'No']);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("notifications-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const updateNotification = async ({ target }, item) => {
        let copy = actions;
        const index = copy.findIndex(m => m.name === item.name);
        copy[index] = { ...copy[index], [target.name]: target.checked };
        const response = await axios.put(`${config.URI}/notification`, { notifications: copy }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token }});
        if(response.statusText === 'OK'){
            if(response.data.status === 'SUCCESS'){
                refresData();
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }
    }

    const [search, setSearch] = useState('');

    useEffect(() => {
        if(search !== ''){
            const sear_noti = reply.filter(ac => ac.name.toLowerCase().search(search.toLowerCase()) !== -1);
            setActions(sear_noti);
        }else{
            refresData();
        }
    },[search])

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Settings - Notification</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => setSearch(e.target.value)} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Action', width: '70%'}, {name: 'email'}, {name: 'sms'}]} />
                        {actions.length > 0 &&
                            actions.map((action, i) => (
                                <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                    <Column className="rowBorderStart" style={{textAlign: 'left'}}>{action.name}</Column>
                                    <Column className="rowBorderMiddle"><label className="container-checkbox"><input type="checkbox" name="notifyEmail" onChange={(e) => updateNotification(e, action)} value={action.name} checked={action.notifyEmail ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                    <Column className="rowBorderEnd"><label className="container-checkbox"><input type="checkbox" name="notifySms" onChange={(e) => updateNotification(e, action)} value={action.name} checked={action.notifySms ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                </ContentBody>
                            ))
                        }
                    </Table>
                </div>
            </div>
        </Layout>
    )
}

const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
&:hover{
    background-color: rgba(255, 235, 17, 0.1)
}
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps, null)(Notification);