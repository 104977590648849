import moment from "moment";

const initState = {
    isLoggedIn: localStorage.getItem('token-stebans-place') ? true : false,
    isLoading: false,
    token: localStorage.getItem('token-stebans-place') ? localStorage.getItem('token-stebans-place') : null,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    error: {},
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            localStorage.setItem('token-stebans-place', action.token)
            localStorage.setItem('userData', JSON.stringify(action.userData));
            localStorage.setItem('dateInit', moment().toISOString());
            return{
                ...state,
                isLoading: false,
                isLoggedIn: true,
                userData: action.userData,
                token: action.token,
                error: undefined
            }
            break;
        case 'UPDATE_DATA':
            localStorage.setItem('userData', JSON.stringify(action.userData));
            return {
                ...state,
                userData: action.userData,
            }
            break;
        case 'LOGOUT':
            localStorage.clear();
            return{
                ...state,
                isLoading:false,
                isLoggedIn:false,
                token: null
            }
            break;
        default:
            return state
    }
}

export default auth;