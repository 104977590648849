import React, { useState } from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import Card from '../assets/card-membership.png';
import { Col, Form, Row } from 'react-bootstrap';
import QRCode from "react-qr-code";
import moment from 'moment';
import axios from 'axios';
import { config } from '../config';
import toast from 'react-hot-toast';
//import '../card.style.css';

const Sales = (props) => {
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState({ code: "0001", plan: { name: "1 Year", type: "unlimited" }, endDate: moment().add(1, 'y'), affiliates: [], _id: 'null' });

    const onSubmitMembership = async () => {
        if(!email || !first_name || !last_name){
            const format = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
            if(!email && !email.match(format))
                toast.error('Please Enter valid email');
            if(!first_name)
                toast.error('Please Enter valid first name');
            if(!last_name)
                toast.error('Please Enter valid last name');
            return;
        }
        const toastId = toast.loading('Loading...');
        try{
            setLoader(true);
            const response = await axios.post(`${config.URI}/create-subscription`, { email, first_name, last_name }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' } });
            if(response.data.status === 'SUCCESS'){
                toast.success((t) => (<span>Successfully save.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                setEmail('');
                setFirstName('');
                setLastName();
                setLoader(false);
            }else{
                setLoader(false);
                toast.error('Server error');
            }
        }catch(e){
            setLoader(false);
            toast.error('Server error');
            console.log(e, 'error');
        }
        toast.dismiss(toastId);
    }

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Membership</Title>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div style={{marginTop: 14, marginBottom: 11}}>
                    <div className='row'>
                        <div className='col-md-12'>
                            <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: 10 }}>
                                <div style={{position: 'relative'}} id="card-membership">
                                    <CardMembership src={Card} />
                                    <div style={{position: 'absolute', height: '100%', width: '100%', top: 0, zIndex: 2}}>
                                        <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'right', marginTop: 14, marginRight: 18}}>{details.code && details.code}</p>
                                        <h1 style={{marginBottom: 0, fontSize: 39, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center'}}>{details.plan && details.plan.name}</h1>
                                        <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'center'}}>VALID UNTIL: {details.endDate && moment(details.endDate).format('MMMM DD, YYYY').toUpperCase()}</p>
                                        <div className="d-flex justify-content-between" style={{ marginLeft: 30, marginRight: 29, marginTop: 23 }}>
                                            <div>
                                                <h3 style={{marginBottom: 0, fontSize: 20, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto'}}>{details.plan && details.plan.type === 'unlimited' ? 'Unlimited' : details.owner}</h3>
                                                {details.affiliates &&
                                                    details.affiliates.map((af, i) => (
                                                        <h3 style={{marginBottom: 0, fontSize: 16, color: '#000', lineHeight: 1, fontFamily: 'Roboto', marginTop: 5}} key={i}>{af}</h3>
                                                    ))
                                                }
                                            </div>
                                            <QRCode value={details._id && details._id} size={143} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-xl-6 col-sm-12'>
                            <div className="card mt-3 p-4" style={{borderRadius: 10}}>
                                <div className="row">
                                    <div className="col-sm-12 border-line pb-3 pt-4">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>First name</Form.Label>
                                                    <Form.Control type="text" value={first_name} onChange={(e) => setFirstName(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Last name</Form.Label>
                                                    <Form.Control type="text" value={last_name} onChange={(e) => setLastName(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={12}>
                                                <Form.Group>
                                                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Email</Form.Label>
                                                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={12}>
                                                <h2 style={{marginBottom: 0, color: '#1A1A1A', fontSize: 28, fontFamily: 'Roboto'}}>Payment information</h2>
                                                <Row className='mt-3'>
                                                    <Col xl={6}>
                                                        <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 20, fontFamily: 'Roboto'}}>Membership Esteban's Place</p>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 18, fontFamily: 'Roboto'}}>Payment recurrently monthly to 12th months</p>
                                                    </Col>
                                                    <Col xl={6}></Col>
                                                    <Col xl={6} className="mt-2">
                                                        <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 18, fontFamily: 'Roboto'}}>$ 54.17 Month X 12</p>
                                                    </Col>
                                                    <Col xl={6}></Col>
                                                    <Col xl={6} className="mt-2">
                                                        <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 18, fontFamily: 'Roboto'}}>$ 650.00 Annually</p>
                                                    </Col>
                                                    <Col xl={12} className="mt-4">
                                                        <Button width="100%" disabled={loader} onClick={() => onSubmitMembership()} backgroundColor="#1A1A1A" color="#FFF" >{loader ? 'Loading...' : 'Create invoice'}</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #000;
`;
const TitleModal = styled.h1`
    font-family: 'Montserrat';
    color: ${({color}) => color ? color: '#1A1A1A'};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;
`;
const CardMembership = styled.img`
    width: 100%;
`;
const Button = styled.button`
    background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
    border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
    color: ${({color}) => color ? color : '#000'};
    border-radius: 10px;
    height: ${({height}) => height ? height : '40px'};
    width: ${({width}) => width ? width : '100px'};
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: ${({bold}) => bold ? bold : '400'};
    font-family: 'Roboto';
    margin-right: 5px;
`;

export default Sales;