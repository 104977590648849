import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import CloseModal from '../assets/close-modal.png';
import Calendar from '../assets/calendar-gray.png';
import styled from 'styled-components';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination/Pagination';
import Layout from '../components/Layout';
import toast from 'react-hot-toast';
import { Col, Form } from 'react-bootstrap';
import axios from 'axios';
import { config } from '../config';
import { format_currency, validate_session } from '../utils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';

const Dashboard = (props) => {
    const module = 'Dashboard';
    const [revenues, setRevenues] = useState([]);
    const [revenuesChart, setRevenuesChart] = useState([]);
    const [details, setDetails] = useState({});
    const [reply, setReply] = useState([]);
    const [productSelect, setProductSelect] = useState([]);
    const [filterBeach, setFilterBeach] = useState('');
    const [beaches, setBeaches] = useState([]);
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(7, 'days')), new Date()]);
    const [startDate, endDate] = dateRange;
    const [dateRange2, setDateRange2] = useState([new Date(moment().subtract(7, 'days')), new Date()]);
    const [startDate2, endDate2] = dateRange2;
    const [total, setTotal] = useState(0);

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const onModalDetails = () => setShowModalDetails(!showModalDetails);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(revenues.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, revenues, PageSize]);

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(revenues.length){
                revenues.map(item => {
                    const t = item.products.reduce((reduce, p) => reduce + p.price, 0);
                    const commissionType = item.beach.commissionPaymentMethod.filter(c => c === item.paymentMethod);
                    let total = 0;
                    let commission = 0;
                    let percentage = 0;
                    if(commissionType.length > 0 && item.beach.commissions === 'General'){
                        percentage = item.beach.percentage / 100;
                        const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                        total = Math.abs(prices * percentage - prices);
                        commission = prices * percentage;
                    }else if(commissionType.length > 0 && item.beach.commissions === 'Individual'){
                        percentage = item.user.commission / 100;
                        const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                        total = Math.abs(prices * percentage - prices);
                        commission = prices * percentage;
                    }else{
                        const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                        total = prices;
                    }
                    data.push({ 'DATE & TIME': moment(item.date).format('MMM DD, YYYY - hh:mm A'), 'SPOT & BEACH': item.beach.name, 'USER': item.user.name, 'ORDER PRODUCT(S)': item.products.length + ' Products', 'INCOME': format_currency(total), 'TIP': format_currency(item.tip), 'COMMISSION': format_currency(commission) + ` (${percentage}%)`, 'REVENUE': t, 'PAYMENT': item.paymentMethod});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Dashboard PDF";
            const headers = [['DATE & TIME', 'SPOT & BEACH', 'USER', 'ORDER PRODUCT(S)', 'INCOME', 'TIP', 'COMMISSION', 'REVENUE', 'PAYMENT']];

            
            const data = revenues.map(item => {
                const t = item.products.reduce((reduce, p) => reduce + p.price, 0);
                const commissionType = item.beach.commissionPaymentMethod.filter(c => c === item.paymentMethod);
                let total = 0;
                let commission = 0;
                let percentage = 0;
                if(commissionType.length > 0 && item.beach.commissions === 'General'){
                    percentage = item.beach.percentage / 100;
                    const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                    total = Math.abs(prices * percentage - prices);
                    commission = prices * percentage;
                }else if(commissionType.length > 0 && item.beach.commissions === 'Individual'){
                    percentage = item.user.commission / 100;
                    const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                    total = Math.abs(prices * percentage - prices);
                    commission = prices * percentage;
                }else{
                    const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
                    total = prices;
                }
                return [moment(item.date).format('MMM DD, YYYY - hh:mm A'), item.beach.name, item.user.name, item.products.length + ' Products', format_currency(total), format_currency(item.tip), format_currency(commission) + ` (${percentage}%)`, t, item.paymentMethod];
            });
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("dashboard-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `dashboard-estebans-place` + fileExtension);
    };

    const refreshBeaches = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    useEffect(() => {
        refreshBeaches();
    },[])

    // useEffect(() => {
    //     if(filterBeach !== 'All Beaches'){
    //         const revenue_filter = reply.filter(item => item.beach._id === filterBeach);
    //         setRevenues(revenue_filter);
    //         setCurrentPage(1);
    //     }else{
    //         refreshData();
    //     }
    // },[filterBeach]);

    useEffect(() => {
        filterBackendSales(dateRange2, filterBeach)
        setCurrentPage(1);
    },[dateRange2, filterBeach])

    const filterBackendSales = async (daterange, filterBeach) => {
        try{
            const response = await axios.get(`${config.URI}/revenue?date1=${daterange[0]}&date2=${daterange[1]}&beach=${filterBeach}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setRevenues(response.data.data);
                setReply(response.data.data);
                //setRevenuesChart(response.data.data);
                // filter_date_range(response.data.data);
                // date_range(response.data.data);
            }
        }catch(e){
            console.log(e);
            //messageError();
        }
    }

    const filter_date_range = (reply) => {
        const start = dateRange2[0];
        const end = dateRange2[1];
        const revenue_filter = reply.filter(item => (new Date(item.date).getTime() >= new Date(start).getTime() &&  new Date(item.date).getTime() <= new Date(end).getTime()) || (moment(item.date, 'YYYY-MM-DD').isSame(moment(start, 'YYYY-MM-DD')) || moment(item.date, 'YYYY-MM-DD').isSame(moment(end, 'YYYY-MM-DD'))));
        setRevenues(revenue_filter);
    }

    const onModalDetailsCarge = (item) => {
        setProductSelect(item.products);
        const t = item.products.reduce((reduce, p) => reduce + p.price, 0);
        setTotal(t);
        const commissionType = item.beach.commissionPaymentMethod.filter(c => c === item.paymentMethod);
        let total = 0;
        let commission = 0;
        let percentage = 0;
        if(commissionType.length > 0 && item.beach.commissions === 'General'){
            percentage = item.beach.percentage / 100;
            const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
            total = Math.abs(prices * percentage - prices);
            commission = prices * percentage;
        }else if(commissionType.length > 0 && item.beach.commissions === 'Individual'){
            percentage = item.user.commission / 100;
            const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
            total = Math.abs(prices * percentage - prices);
            commission = prices * percentage;
        }else{
            const prices = item.products.reduce((reduce, product) => reduce + product.price, 0);
            total = prices;
        }
        setDetails({ ...item, total, commission, percentage });
        onModalDetails();
    }

    const DatePickerInputRange = forwardRef(({ value, onClick }, ref) => (
        <>
            <Form.Control onClick={onClick} value={value ? `${moment(value.split('-')[0].replace(' ', '')).format('DD MMM')} - ${moment(value.split('-')[1].replace(' ', '')).format('DD MMM YYYY')}` : ''} ref={ref} style={{fontSize: 14.4, fontFamily: 'Roboto', color: '#1A1A1A', borderColor: '#BDBDBD', borderRadius: 10, height: 40}} />
            <img src={Calendar} style={{ width: 18, height: 20, position: 'absolute', right: 12, top: 9 }} />
        </>
    ));
      
    const options = {
        legend: { display: false },
        scales: {
            y: {
                ticks: {
                    callback: function(value, index, values) {
                        return '$' + value;
                    }
                }
            }
        }
    };

    const [lineChart, setLineChart] = useState({});

    useEffect(() => {
        loadLineChart();
    },[revenuesChart]);

    useEffect(() => {
        filterBackendChart(dateRange)
    },[dateRange]);

    const filterBackendChart = async (daterange) => {
        try{
            const response = await axios.get(`${config.URI}/revenue?date1=${daterange[0]}&date2=${daterange[1]}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setRevenuesChart(response.data.data);
                // filter_date_range(response.data.data);
                // date_range(response.data.data);
            }
        }catch(e){
            console.log(e);
            //messageError();
        }
    }

    // const date_range = (reply) => {
    //     const start = dateRange[0];
    //     const end = dateRange[1];
    //     const revenue_filter = reply.filter(item => (new Date(item.date).getTime() >= new Date(start).getTime() &&  new Date(item.date).getTime() <= new Date(end).getTime()) || (moment(item.date, 'YYYY-MM-DD').isSame(moment(start, 'YYYY-MM-DD')) || moment(item.date, 'YYYY-MM-DD').isSame(moment(end, 'YYYY-MM-DD'))));
    //     setRevenuesChart(revenue_filter);
    // }

    const loadLineChart = () => {
        const start = dateRange2[0];
        const end = dateRange2[1];
        let months = [];
        for(let i = 0; i < 5; i++){
            months.push(moment().subtract(i, 'month').format('MMM YYYY'));
        }
        months = months.reverse();
        const revenue_months = [];
        for(let i = 0; i < months.length; i++){
            let total = 0;
            for(let k = 0; k < revenuesChart.length; k++){
                if(moment(revenuesChart[k].date).format('MMM YYYY') === months[i]){
                    total += revenuesChart[k].products.reduce((reduce, product) => reduce + product.price, 0);
                }
            }
            revenue_months.push(total);
        }
        setLineChart({
            labels: months,
            datasets: [
                {
                    label: '',
                    data: revenue_months,
                    backgroundColor: '#4FB4E6',
                },
            ],
        });
    }

    const loadBarChart = () => {
        const beachs = [];
        for(let i = 0; i < beaches.length; i++){
            beachs.push({name: beaches[i].name, id: beaches[i]._id});
        }
        const revenue_beach = [];
        for(let i = 0; i < beachs.length; i++){
            let total = 0;
            for(let k = 0; k < revenuesChart.length; k++){
                if(revenuesChart[k].beach._id === beachs[i].id){
                    total += revenuesChart[k].products.reduce((reduce, product) => reduce + product.price, 0);
                }
            }
            revenue_beach.push(`${total}`);
        }
        return  {
            labels: beachs.map(b => b.name),
            datasets: [
                {
                    label: '',
                    data: revenue_beach,
                    backgroundColor: '#4FB4E6',
                },
            ],
            
        };
    }

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Dashboard</Title>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div style={{marginTop: 14, marginBottom: 11}} className="d-flex align-items-center">
                    <Subtitle>Metric</Subtitle>
                    <div style={{width: 200}}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            customInput={<DatePickerInputRange />}
                        />
                    </div>
                </div>
                <div className="d-flex" style={{marginBottom: 19}}>
                    <Col style={{borderColor: '#1A1A1A', borderWidth: 1, borderStyle: 'solid', marginRight: 10, borderRadius: 5}}>
                        <Bar data={loadBarChart} options={options} />
                    </Col>
                    <Col style={{borderColor: '#1A1A1A', borderWidth: 1, borderStyle: 'solid', marginLeft: 10, borderRadius: 5}}>
                        <Line data={lineChart} options={options} />
                    </Col>
                </div>
                <div className="d-flex align-items-center" style={{marginBottom: 11}}>
                    <Subtitle>Sales</Subtitle>
                    <div style={{width: 200}}>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate2}
                            endDate={endDate2}
                            onChange={(update) => {
                                setDateRange2(update);
                            }}
                            customInput={<DatePickerInputRange />}
                        />
                    </div>
                    <div>
                    <select className="custom-select" value={filterBeach} onChange={(e) => setFilterBeach(e.target.value)} style={{ fontSize: 14.4, fontFamily: 'Roboto', color: '#1A1A1A', marginLeft: 10, borderColor: '#BDBDBD', borderRadius: 10, height: 40 }}>
                        <option value="">All Beaches</option>
                        {beaches.map((beach) => (
                            <option value={beach._id}>{beach.name}</option>
                        ))}
                    </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'Date & Time'}, {name: 'Spot & Beach'}, {name: 'User'}, {name: 'Order Product(s)'}, {name: 'Income'}, {name: 'Tip'}, {name: 'Commission'},  {name: 'Discount'}, {name: 'Revenue'}, {name: 'Payment'}, { name: 'Actions'}]} />
                        {revenues.length > 0 && (
                            <>
                                {currentTableData.map((revenue, i) => {
                                    const commissionType = revenue.beach.commissionPaymentMethod.filter(c => c === revenue.paymentMethod);
                                    let total = 0;
                                    let commission = 0;
                                    let percentage = 0;
                                    if(commissionType.length > 0 && revenue.beach.commissions === 'General'){
                                        percentage = revenue.beach.percentage / 100;
                                        const prices = revenue.products.reduce((reduce, product) => reduce + product.price, 0);
                                        total = Math.abs(prices * percentage - prices);
                                        commission = prices * percentage;
                                    }else if(commissionType.length > 0 && revenue.beach.commissions === 'Individual'){
                                        percentage = (revenue.user ? revenue.user.commission : 0) / 100;
                                        const prices = revenue.products.reduce((reduce, product) => reduce + product.price, 0);
                                        total = Math.abs(prices * percentage - prices);
                                        commission = prices * percentage;
                                    }else{
                                        const prices = revenue.products.reduce((reduce, product) => reduce + product.price, 0);
                                        total = prices;
                                    }
                                    return (
                                        <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                            <Column className="rowBorderStart">{moment(revenue.date).format('MMM DD, YYYY - hh:mm A')}</Column>
                                            <Column className="rowBorderMiddle">{revenue.beach.name}</Column>
                                            <Column className="rowBorderMiddle">{revenue.user && revenue.user.name}</Column>
                                            <Column className="rowBorderMiddle"><Link onClick={(e) => {e.preventDefault(); onModalDetailsCarge(revenue); }} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#1A1A1A' }}>{revenue.products.length} Products <FontAwesomeIcon icon={faEye} size="sm" /></Link></Column>
                                            <Column className="rowBorderMiddle">{format_currency(total)}</Column>
                                            <Column className="rowBorderMiddle">{format_currency(revenue.tip)}</Column>
                                            <Column className="rowBorderMiddle">{format_currency(commission)} ({percentage * 100})%</Column>
                                            <Column className="rowBorderMiddle" style={{color: 'red'}}>{format_currency(Math.abs(revenue.discount ? revenue.discount : 0))}</Column>
                                            <Column className="rowBorderMiddle">{format_currency(Math.abs((total + commission) - (revenue.discount ? revenue.discount : 0)))}</Column>
                                            <Column className="rowBorderMiddle">{revenue.paymentMethod.substr(0, 1).toUpperCase() + revenue.paymentMethod.substr(1)}</Column>
                                            <Column className="rowBorderEnd">
                                                <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                    <TextAction onClick={() => onModalDetailsCarge(revenue)}>Details&nbsp;</TextAction>
                                                </div>
                                            </Column>
                                        </ContentBody>
                                    )
                                })}
                            </>
                        )}
                    </Table>
                    {revenues.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {revenues.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={revenues.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showModalDetails} onHide={onModalDetails} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 15, marginTop: 16 }} onClick={() => {onModalDetails(); }}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40 }}>
                    <TitleModal>Order {details.code ? details.code : ''} - Total: {format_currency((total - (details.discount ? details.discount : 0)))}</TitleModal>
                </div>
                {productSelect.length > 0 &&
                    <div className="mt-30" style={{paddingRight: 20, paddingLeft: 20}}>
                        <TitleDetails style={{borderTop: 'none' }} paddingLeft="10px !important">Products</TitleDetails>
                        {productSelect.map((product, index) => (
                            <div style={{ border: '1px solid rgba(159, 159, 159, .2)', height: 45, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} className="d-flex justify-content-between">
                                <div className="d-flex" style={{alignItems: 'center'}}>
                                    <Content>
                                        <Img src={product.file} alt="product" />
                                    </Content>
                                    <TextDetailsCard>{product.name}</TextDetailsCard>
                                </div>
                                <div className="d-flex mr-3">
                                    <TextDetailsCard style={{paddingRight: 16, fontWeight: '700'}}>{format_currency(product.price)}</TextDetailsCard>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <TitleDetails style={{borderTop: 'none'}}>Details</TitleDetails>
                    <tbody>
                        <tr>
                            <TitleDetails>Arrival</TitleDetails>
                            <td>{details.arrival ? moment(details.arrival).format('MMM DD, YYYY - hh:mm A') : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Departure</TitleDetails>
                            <td>{details.departure ? moment(details.departure).format('MMM DD, YYYY - hh:mm A') : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>BEACH</TitleDetails>
                            <td>{details.beach ? details.beach.address.full_address : '' }</td>
                            <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height="28px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                        </tr>
                        <tr>
                            <TitleDetails>User</TitleDetails>
                            <td>{details.user && details.user.name ? details.user.name : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Commission</TitleDetails>
                            <td>{typeof details.commission !== 'undefined' ? format_currency(details.commission) + " " + `(${details.percentage * 100}%)` : ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Tip</TitleDetails>
                            <td>{details.tip ? format_currency(details.tip) : '$ 0'}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Discount</TitleDetails>
                            <td style={{color: 'red'}}>{details.discount ? format_currency(details.discount) : '$ 0'}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>Payment Method</TitleDetails>
                            <td>{details.paymentMethod ? details.paymentMethod : ''}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ maxHeight: 234 }}>
                </div>
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width="149px" onClick={() => {onModalDetails();}}>Close</Button>
                </div>
            </Modal>
        </Layout>
    );
}

const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
&:hover{
    background-color: rgba(255, 235, 17, 0.1)
}
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 0px;
margin-bottom: 18px;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const TitleDetails = styled.td`
font-size: 14px;
min-width: 210px;
padding-left: ${({paddingLeft}) => paddingLeft ? paddingLeft :'30px !important'};
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TextDetailsCard = styled.p`
font-size: 14px;
padding-left: 14px !important;
font-family: 'Roboto';
color: #1A1A1A;
margin-bottom: 0 !important;
`;
const Content = styled.div`
height: 29px;
width: 29px;
margin-left: 16px;
`;
const Img = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
object-position: center;
border-radius: 50%;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const Subtitle = styled.h3`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#000'};
font-size: 22px;
margin-bottom: 0;
line-height: 27px;
font-weight: 700;
margin-right: 13px;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(Dashboard);