import React from 'react';
import Logo from '../../assets/logo.png';

const SidebarLogo = (props) => {
    return (
        <div style={{ padding: 20 }}>
            <img src={Logo} height={55} width={257} />
        </div>
    )
}

export default SidebarLogo;