import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Link } from 'react-router-dom';
import Icon from '../../assets/icon.png';
import ReactFlagsSelect from 'react-flags-select';
import { config } from '../../config';
import axios from 'axios';
import { countries, getIndivative } from '../../utils/countries';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/auth-action';
import InputMask from "react-input-mask";
import toast, { Toaster } from 'react-hot-toast';

const componentStyle = {
    inputStyle: {
        fontFamily: 'Roboto',
        marginRight: '35px',
        width: '50px',
        borderRadius: '10px',
        fontSize: '14px',
        height: '50px',
        paddingLeft: '20px',
        color: '#000',
        border: '1px solid #BDBDBD'
    }
}

const Login = (props) => {

    const [sendCode, setSendCode] = useState(false);
    const [phone, setPhone] = useState('');
    const [indicative, setIndicative] = useState('+1');
    const [code, setCode] = useState('');
    const [userDetail, setUserDetail] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [country, setCountry] = useState('US');

    useEffect(() => {
        if(code.length === 6){
            setDisabled(false);
            submitCodeVerify();
        }
    },[code]);

    useEffect(() => {
        setIndicative(getIndivative(country));
    },[country])

    const submitCodeVerify = async () => {
        try{
            const response = await axios.post(`${config.URI}/verifysms`, { code, id: userDetail._id, indicative }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' }});
            if(response.statusText === 'OK'){
                if(response.data.status === 'VERIFIED'){
                    const data = response.data.data;
                    props.loginSuccess(data, response.data.token);
                }
            }
        }catch(e){
            console.log(e)
            toast.error((t) => (<span>{e.response.data.message}</span>));
        }
    }

    const handlerSubmit = async () => {
        const phon = phone.replace('(', '').replace(')', '').replace('-','').replace(' ', '').replace('_', '');
        if(phon === ''){
            toast.error((t) => (<span>Invalid input phone.</span>));
            return;
        }
        try{
            const response = await axios.post(`${config.URI}/auth`, { username: phon, indicative, type: 'phone', platform: 'Desktop' }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' }});
            if(response.statusText === 'OK'){
                if(response.data.status === 'SEND_SMS'){
                    setSendCode(true);
                    setUserDetail(response.data.data);
                }
            }
        }catch(e){
            console.log(e)
            toast.error((t) => (<span>{e.response.data.message}</span>));
        }
    }

    const onSubmitResendSMS = async () => {
        try{
            const response = await axios.post(`${config.URI}/resendsms`, { phone, indicative }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' } });
            if(response.statusText === 'OK'){
            }
        }catch(e){
            console.log(e);
            toast.error((t) => (<span>{e.response.data.message}</span>));
        }
    }

    return (
        <div className="limter">
            <div className="container-login">
                <div className="wrap-login">
                    <div className="login-form login-white-back">
                        <div className="top-right-buttons d-flex">
                            <p className="text-top r-12">Have you lost your phone?</p>
                            <Link to="/auth/loginwithemail" className="btn-transparent-pill">
                                <p>Log In with Email</p>
                            </Link>
                        </div>
                        {!sendCode ?
                        (<div className="container">
                            <h1 className="stebans-title">Sign in to Esteban’s Place.</h1>
                            <p className="stebans-subtitle">Enter your phone below and we'll send an SMS with your access code.</p>
                            <div className="form-group m-t-37">
                                <label htmlFor="phone" className="text-form">Phone</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <ReactFlagsSelect
                                            selected={country}
                                            customLabels={{ ...countries }}
                                            onSelect={code => {
                                                setCountry(code);
                                            }}
                                            placeholder={''}
                                        />
                                    </div>
                                    <InputMask mask="(999) 999-9999" className="phone" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn-primary-login m-t-40" onClick={() => handlerSubmit()}>
                                    <p>Request Access Code</p>
                                </button>
                            </div>
                        </div>)
                        :
                        (<div className="container">
                            <h1 className="stebans-title">Sign in to Esteban’s Place.</h1>
                            <p className="stebans-subtitle">Your SMS code was sent to: {indicative} {phone}</p>
                            <div className="form-group m-t-37">
                                <label className="text-form" style={{marginLeft: 33}}>6-digit Access Code</label>
                                <div className="text-center">
                                    <ReactCodeInput type="text" fields={6} className="react-code-input-mod" onChange={(text) => setCode(text)} inputStyle={componentStyle.inputStyle} />
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn-primary-login m-t-40" disabled={disabled} onClick={() => submitCodeVerify()}>
                                    <p>Sign In</p>
                                </button>
                            </div>
                            <div className="text-center" style={{ marginTop: 13 }}>
                                <Link to="" onClick={(e) =>{e.preventDefault(); onSubmitResendSMS()}} className="text-resend">Resend access code</Link>
                            </div>
                            <div className="text-center">
                                <label onClick={() => setSendCode(false)} className="text-resend text-link">Go Back</label>
                            </div>
                        </div>)
                        }
                    </div>
                    <div className="login-more">
                        <img src={Icon} className="logo" />
                    </div>
                </div>
            </div>
            <Toaster 
                position="top-center"
                toastOptions={{
                    success: {
                    style: {
                        background: '#61d345',
                        color: '#FFF'
                    },
                    },
                    error: {
                    style: {
                        background: '#ff4b4b',
                        color: '#FFF'
                    },
                    },
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,
	isLoading: state.auth.isLoading,
	error: state.auth.error
})

const mapDispatchToProps = dispatch => ({
	loginSuccess: (data, token) => dispatch(actions.loginSuccess(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);