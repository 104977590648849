import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Layout from '../components/Layout';
import axios from 'axios';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { config } from '../config';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { validate_session } from '../utils';

const Partnerships = (props) => {
    const module = 'Partnerships';
    const [partners, setPartners] = useState([]);
    const [reply, setReply] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/partners`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setPartners(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    useEffect(() => {
        refreshData();
    }, []);

    const [search, setSearch] = useState('');

    useEffect(() => {
        if(search !== ''){
            const search_partners = reply.filter(par => par.name.toLowerCase().search(search.toLowerCase()) !== -1);
            setPartners(search_partners);
        }else{
            refreshData();
        }
    },[search])

    const history = useHistory();

    return(
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Partnerships</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => { if(permissions() && permissions().actionCreate){history.push('/admin/settings/partners', { onModal: true })}else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); } }} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div className="cards mt-24" style={{ paddingRight: 21 }}>
                {partners.map(partner => (
                    <Link to={`/admin/partnerships-upload/${partner._id}`}>
                        <Card className="card-flex">
                            <div style={{ minHeight: 42 }}>
                                <TitleCard className="line-clamp">{partner.name}</TitleCard>
                            </div>
                            <TextBody className="mt-20">{partner.guests ? partner.guests : '0'} Active Guests</TextBody>
                            <TextBody className="mt-20" color="#828282">Last update: {moment(partner.updateAt).format('MMM DD, YYYY')}</TextBody>
                        </Card>
                    </Link>
                ))}
            </div>
        </Layout>
    )
}

const Card = styled.div`
margin-right: 20px !important;
border: 1px solid #BDBDBD;
border-radius: 10px;
padding: 35px 21px;
height: 184px;
&:hover{
    background-color: #FFEB11;
}
`;
const TitleCard = styled.h3`
font-family 'Roboto';
font-size: 18px;
color: #1A1A1A;
font-weight: 700;
line-height: 21px;
text-align: center;
margin-bottom: 0 !important;
`;
const TextBody = styled.p`
font-family 'Roboto';
font-size: 14px;
color: ${({color}) => color ? color: '#1A1A1A'};
line-height: 16px;
text-align: center;
margin-bottom: 0 !important;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(Partnerships);