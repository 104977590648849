import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import styled from 'styled-components';
import Modal from '../components/Modal';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { config } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Desktop, Mobile } from '../utils/modules';
import toast from 'react-hot-toast';
import CloseModal from '../assets/close-modal.png';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { validate_session } from '../utils';

const Roles = (props) => {
    const module = 'Settings';
    const token = props.token;

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [platform, setPlatform] = useState('');
    const [list, setList] = useState([]);
    const [id, setId] = useState('');
    const [errors, setErrors] = useState({});
    const [modalDelete, setModalDelete] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);

    useEffect(() => {
        validate_session(props.token);
    },[])

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if(permissions() && permissions().actionCreate){
            setShow(true);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    useEffect(() => {
        onRefreshData()
    },[])

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const onRefreshData = async () => {
        try{
            const { data } = await axios.get(`${config.URI}/roles`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': token }});
            if(data){
                if(data.status === 'SUCCESS'){
                    setList(data.data);
                    setReply(data.data);
                }
            }
        }catch(e){
            console.log(e.response)
        }
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            const header = [];
            for(let i = 0; i < list.length; i++){
                header.push(`${list[i].name} - ${list[i].platform}`);
                const encode = [];
                for(let k = 0; k < list[i].modules.length; k++){
                    encode.push({MODULE: list[i].modules[k].name, ACTION_VIEW: list[i].modules[k].actionView, ACTION_CREATE: list[i].modules[k].actionCreate, ACTION_EDIT: list[i].modules[k].actionEdit, ACTION_DELETE: list[i].modules[k].actionDelete, ACTION_EXPORT: list[i].modules[k].actionExport});
                }
                data.push(encode);
            }
            exportToCSV(data, header);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const doc = new jsPDF(orientation, unit, size);
            doc.setFontSize(15);
            const heads = [['MODULE', 'VIEW', 'CREATE', 'EDIT', 'DELETE', 'EXPORT']];
            for(let i = 0; i < list.length; i++){
                const body = [];
                for(let k = 0; k < list[i].modules.length; k++){
                    body.push([list[i].modules[k].name, list[i].modules[k].actionView, list[i].modules[k].actionCreate, list[i].modules[k].actionEdit, list[i].modules[k].actionDelete, list[i].modules[k].actionExport]);
                }
                let content = {
                    startY: i === 0 ? 50 : doc.lastAutoTable.finalY + 50,
                    head: heads,
                    body: body,
                    headerStyles: {
                        textColor: [0, 0, 0],
                        fillColor: [252,249,210]
                    }
                };
                doc.text(list[i].name, 40, doc.autoTableEndPosY() + 40);
                doc.autoTable(content);
            }
            doc.save("roles-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding, headers) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.book_new();
        for (var i = 0; i < headers.length; i++) {
            XLSX.utils.sheet_add_aoa(ws, [[headers[i]]]);
            XLSX.utils.sheet_add_json(ws, dataencoding[i], { origin: 'A2', skipHeader: true });
        }
        // const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `roles-estebans-place` + fileExtension);
    };

    const onSubmitCreateRole = async () => {
        if(name !== '' && platform !== ''){
            handleClose();
            const toastId = toast.loading('Loading...');
            const modules = platform === 'Desktop' ? Desktop : Mobile;
            const response = await axios.post(`${config.URI}/roles`, { platform, name, modules }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': token }});
            if(response.statusText === 'OK'){
                if(response.data.status === 'SUCCESS'){
                    onRefreshData();
                    toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }else{
            if(name === ''){
                setErrors({ name: true });
                messageErrorMessage(`Invalid name input`);
            }else if(platform === ''){
                setErrors({ platform: true });
                messageErrorMessage(`Don't selected platform`);
            }else{
                setErrors({});
            }
        }
    }

    const onSubmitDeleteRole = async () => {
        onModalDelete();
        const toastId = toast.loading('Loading...');
        const response = await axios.delete(`${config.URI}/roles/${id}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': token }});
        if(response.statusText === 'OK'){
            if(response.data.status === 'SUCCESS'){
                onRefreshData();
                setId('');
                toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }else{
            messageError();
        }
        toast.dismiss(toastId);
    }

    const confirmDelete = (id) => {
        setId(id);
        onModalDelete();
    }

    const onModalDelete = () => {
        if(permissions() && permissions().actionDelete){
            setModalDelete(!modalDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onModalUpdate = () => {
        if(permissions() && permissions().actionEdit){
            setModalUpdate(!modalUpdate);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    const onClickCheckedActions = async (item, { target }, items) => {
        let copy = items;
        const index = copy.modules.findIndex(m => m.name === item.name);
        copy.modules[index] = { ...copy.modules[index], [target.name]: target.checked };
        const response = await axios.put(`${config.URI}/roles/${copy._id}`, { name: copy.name, modules: copy.modules, platform: copy.platform }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token }});
        if(response.statusText === 'OK'){
            if(response.data.status === 'SUCCESS'){
                onRefreshData();
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }else{
            messageError();
        }
        
    }

    const onCargeModallUpdate = (item) => {
        setName(item.name);
        setPlatform(item.platform);
        setId(item._id);
        onModalUpdate();
    }

    const onSubmitUpdateRole = async () => {
        if(name !== '' && platform !== ''){
            onModalUpdate();
            const toastId = toast.loading('Loading...');
            const response = await axios.put(`${config.URI}/roles/${id}`, { name, platform }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token }});
            if(response.statusText === 'OK'){
                if(response.data.status === 'SUCCESS'){
                    setName('');
                    setPlatform('');
                    setId('');
                    onRefreshData();
                    toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                }
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }else{
            if(name === ''){
                setErrors({ name: true });
                messageErrorMessage(`Invalid name input`);
            }else if(platform === ''){
                setErrors({ platform: true });
                messageErrorMessage(`Don't selected platform`);
            }else{
                setErrors({});
            }
        }
    }

    const clean = () => {
        setName('');
        setPlatform('');
        setId('');
    }

    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const roles_search = reply.filter(item => item.name.toLowerCase().search(value.toLowerCase()) !== -1 || item.platform.toLowerCase().search(value.toLowerCase()) !== -1);
            setList(roles_search);
        }
        if(value === ''){
            onRefreshData();
        }
    }

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Settings - Roles</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => handleShow()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            {list.length > 0 && (
                list.map((item, i) => {
                    return (
                        <div key={i}>
                            <div style={{ marginTop: 21 }} className="d-md-flex align-items-center">
                                <Subtitle style={{ marginRight: 10, marginBottom: 0 }}>{item.name} - {item.platform}</Subtitle>
                                {((item.name !== 'Administrator Mobile' && item.platform !== 'Mobile') || (item.name !== 'Administrator Mobile' && item.platform === 'Mobile')) &&
                                <>
                                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onCargeModallUpdate(item)} paddingVertical={'0.4rem'} color={'#FFF'}>Edit</Button>
                                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => confirmDelete(item._id)} paddingVertical={'0.4rem'} color={'#FFF'}>Delete</Button>
                                </>
                                }
                            </div>
                            <div style={{ marginRight: 21 }}>
                                <div className="table-responsive">
                                    <Table className="table-curved">
                                        <HeaderTable header={[{name: 'Module', width: '50%'}, {name: 'View'}, {name: 'Create'}, {name: 'Edit'}, {name: 'Delete'}, {name: 'Export'}]} />
                                        {item.modules.map((b, i) => (
                                            <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                                <Column className="rowBorderStart" style={{ width: '50%' }}>{b.name}</Column>
                                                <Column className="rowBorderMiddle" style={{textAlign: 'center'}}><label className="container-checkbox"><input type="checkbox" name="actionView" value={b.name} onChange={(e) => onClickCheckedActions(b, e, item)} checked={b.actionView ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                                <Column className="rowBorderMiddle" style={{textAlign: 'center'}}><label className="container-checkbox"><input type="checkbox" name="actionCreate" value={b.name}  onChange={(e) => onClickCheckedActions(b, e, item)} checked={b.actionCreate ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                                <Column className="rowBorderMiddle" style={{textAlign: 'center'}}><label className="container-checkbox"><input type="checkbox" name="actionEdit" value={b.name}  onChange={(e) => onClickCheckedActions(b, e, item)} checked={b.actionEdit ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                                <Column className="rowBorderMiddle" style={{textAlign: 'center'}}><label className="container-checkbox"><input type="checkbox" name="actionDelete" value={b.name}  onChange={(e) => onClickCheckedActions(b, e, item)} checked={b.actionDelete ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                                <Column className="rowBorderEnd" style={{textAlign: 'center'}}><label className="container-checkbox"><input type="checkbox" name="actionExport" value={b.name}  onChange={(e) => onClickCheckedActions(b, e, item)} checked={b.actionExport ? 'checked' : ''} /><span className="checkmark"></span></label></Column>
                                            </ContentBody>
                                        ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    )
                })
            )}
            <Modal show={show} onHide={handleClose} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {handleClose(); clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Create Role</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Nombre</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Platform</Form.Label>
                        <Form.Control as="select" defaultValue={platform} onChange={(e) => setPlatform(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.platform ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            <option value={'Mobile'}>Mobile</option>
                            <option value={'Desktop'}>Desktop</option>
                        </Form.Control>
                    </Form.Group>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {handleClose(); clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitCreateRole()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalDelete} onHide={onModalDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onModalDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete Role</TitleModal>
                    <TextModal>Please confirm role deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => onModalDelete()} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} onClick={() => onSubmitDeleteRole()} width={'149px'} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalUpdate} onHide={onModalUpdate} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onModalUpdate();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Edit Role</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Platform</Form.Label>
                        <Form.Control as="select" defaultValue={platform} onChange={(e) => setPlatform(e.target.value)} disabled={id !== ''} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.platform ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}}>
                            <option value={'Mobile'}>Mobile</option>
                            <option value={'Desktop'}>Desktop</option>
                        </Form.Control>
                    </Form.Group>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onModalUpdate();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitUpdateRole()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const Subtitle = styled.h4`
font-family: 'Roboto';
font-size: 22px;
line-height: 26px;
color: #000;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 10px;
margin-bottom: 18px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(Roles);