import React, { useState, useMemo, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from './Modal';
import styled from 'styled-components';
import { config } from '../config';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/modal-action';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Dropzone from '../assets/dropzone.png';
import { upload } from '../utils';
import CloseModal from '../assets/close-modal.png';
import toast from 'react-hot-toast';

const ModalSupport = (props) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(false);
    const [errors, setErrors] = useState({});

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: '.pdf, .docx, .doc, .xls, .xlsx, .jpg, .png, jpeg, .mov, .mp4',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const onSubmitSupport = async () => {
        if(validate_field()){
            props.onHide();
            const toastId = toast.loading('Loading...');
            const filename = await upload(file, props.token);
            const response = await axios.post(`${config.URI}/support`, { subject, message, file: filename }, { headers: { 'Accept': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setSubject('');
                setMessage('');
                setFile(false);
                toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validate_field = () => {
        if(subject === ''){
            setErrors({ subject: true });
            messageErrorMessage(`Invalid subject input`);
        }else if(message === ''){
            setErrors({ message: true });
            messageErrorMessage(`Invalid message input`);
        }else if(!file){
            setErrors({ file: true });
            messageErrorMessage(`Don't selected attachment`);
        }else{
            setErrors({});
            return true;
        }
    }

    const validate_field_success = () => {
        if(subject !== ''){
            setErrors({ subject: false });
        }else if(message !== ''){
            setErrors({ message: false });
        }else if(file){
            setErrors({ file: false });
        }
    }

    useEffect(() => {
        validate_field_success();
    },[subject, message, file])

    return (
        <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-600w" centered>
            <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => props.onHide()}>
                <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
            </div>
            <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                <TitleModal>Create Support Case</TitleModal>
                <Form.Group>
                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Subject</Form.Label>
                    <Form.Control type="text" onChange={(e) => setSubject(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.subject ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                </Form.Group>
                <Form.Group>
                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Message</Form.Label>
                    <textarea className="form-control" rows={6} onChange={(e) => setMessage(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.message ? '#FF0F00' : '#BDBDBD', borderRadius: 10}} />
                </Form.Group>
                <Form.Group>
                    <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Attach File</Form.Label>
                    <div>
                        <div {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            <img src={Dropzone} height="80px" alt=""/>
                            <h5 style={{ fontSize: '18px', fontFamily: 'Arial', color: '#000', marginTop: '15px', marginBottom: 0 }}>Click or Drag-n-Drop a file here</h5>
                            <p style={{ fontSize: '16px', color: '#000', fontFamily: 'Arial' }}>PNG, JPG, PDF, Word, Excel, Mov, MP4 file format</p>
                            <aside>
                                {file &&
                                    <p style={{color: '#000', fontSize: 14}}>{file.name}</p>
                                }
                            </aside>
                        </div>
                    </div>
                </Form.Group>
                <div className="text-center m-3">
                    <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => props.onHide()} width="149px">Cancel</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitSupport()} width="149px" color={'#FFF'}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 3,
    borderRadius: 10,
    borderStyle: 'dotted',
    borderColor: '#BDBDBD',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
const activeStyle = {
    borderColor: '#2196f3'
};
const acceptStyle = {
    borderColor: '#00e676'
};
const rejectStyle = {
    borderColor: '#ff1744'
};

const mapStateToProps = state => ({
	show: state.modal.showsupport,
    token: state.auth.token,
    userData: state.auth.userData
})

const mapDispatchToProps = dispatch => ({
    onHide: () => dispatch(actions.hidenSupport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSupport);