
import Dashboard from '../screens/Dashboard';
import Login from '../screens/auth/Login';
import LoginWithEmail from '../screens/auth/LoginWithEmail';
import Roles from '../screens/Roles';
import Users from '../screens/Users';
import Products from '../screens/Products';
import Beaches from '../screens/Beaches';
import Logout from '../screens/Logout';
import ForgotPassword from '../screens/auth/ForgotPassword';
import Partners from '../screens/Partners';
import Partnerships from '../screens/Partnerships';
import PartnershipsUpload from '../screens/PartnershipsUpload';
import Notification from '../screens/Notification';
import Revenue from '../screens/Revenue';
import MembershipPlans from '../screens/MembershipPlans';
import TimeTracking from '../screens/TimeTracking';
import Memberships from '../screens/Memberships';
import DeletedElements from '../screens/DeletedElements';
import Billing from '../screens/Billing';
import Sales from '../screens/Sales';
import MembershipSale from '../screens/Membership';

const routes = [
    {
        path: "/index",
        name: "Dashboard",
        component: Dashboard,
        screen: '/admin'
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        screen: '/admin'
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        screen: '/admin'
    },
    {
        path: '/beaches',
        name: 'Beaches',
        component: Beaches,
        screen: '/admin'
    },
    {
        path: '/partnerships',
        name: 'Partnerships',
        component: Partnerships,
        screen: '/admin'
    },
    {
        path: '/partner-billing',
        name: "Billing",
        component: Billing,
        screen: '/admin'
    },
    {
        path: '/memberships',
        name: 'Memberships',
        component: Memberships,
        screen: '/admin'
    },
    {
        path: '/timetracking',
        name: 'Time Tracking',
        component: TimeTracking,
        screen: '/admin'
    },
    {
        path: '/partnerships-upload/:id',
        name: 'Partnerships',
        component: PartnershipsUpload,
        screen: '/admin'
    },
    { 
        path: '/revenue',
        name: 'Revenue',
        component: Revenue,
        screen: '/admin'
    },
    {
        path: '/settings/notification',
        name: 'Notification',
        component: Notification,
        screen: '/admin'
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        screen: '/admin'
    },
    {
        path: "/settings/roles",
        name: "Roles",
        component: Roles,
        screen: '/admin'
    },
    {
        path: '/settings/partners',
        name: "Partners",
        component: Partners,
        screen: '/admin'
    },
    {
        path: '/settings/membership-plans',
        name: 'Membership PLans',
        component: MembershipPlans,
        screen: '/admin'
    },
    {
        path: '/deleted-elements',
        name: 'Deleted Elements',
        component: DeletedElements,
        screen: '/admin'
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        screen: '/auth'
    },
    {
        path: '/loginwithemail',
        name: 'Login',
        component: LoginWithEmail,
        screen: '/auth'
    },
    {
        path: '/forgot/:id',
        name: 'Forgot Password',
        component: ForgotPassword,
        screen: '/auth'
    },
    {
        path: '/index',
        name: 'Sales',
        component: Sales,
        screen: '/sales'
    },
    {
        path: '/membership/:id',
        name: 'Membership sales',
        component: MembershipSale,
        screen: '/sales'
    }
];

export default routes;