
export const countries = {
    "AF": { primary: "AF", secondary: "+93"},
    "AL": { primary: "AL", secondary: "+355"},
    "DZ": { primary: "SZ", secondary: "+213"},
    "AS": { primary: "AS", secondary: "+1684"},
    "AD": { primary: "AD", secondary: "+376"},
    "AO": { primary: "AO", secondary: "+244"},
    "AI": { primary: "AI", secondary: "1264"},
    "AG": { primary: "AG", secondary: "+1268"},
    "AR": { primary: "AR", secondary: "+54"},
    "AM": { primary: "AM", secondary: "+374"},
    "AW": { primary: "AW", secondary: "+297"},
    "AU": { primary: "AU", secondary: "+61"},
    "AT": { primary: "AT", secondary: "+43"},
    "AZ": { primary: "AZ", secondary: "+994"},
    "BS": { primary: "BS", secondary: "+1242"},
    "BH": { primary: "BH", secondary: "+973"},
    "BD": { primary: "BD", secondary: "+880"},
    "BB": { primary: "BB", secondary: "+1246"},
    "BY": { primary: "BY", secondary: "+375"},
    "BE": { primary: "BE", secondary: "+32"},
    "BZ": { primary: "BZ", secondary: "+501"},
    "BJ": { primary: "BJ", secondary: "+229"},
    "BM": { primary: "BM", secondary: "+1441"},
    "BT": { primary: "BT", secondary: "+975"},
    "BO": { primary: "BO", secondary: "+591"},
    "BA": { primary: "BA", secondary: "+387"},
    "BW": { primary: "BW", secondary: "+267"},
    "BR": { primary: "BR", secondary: "+55"},
    "IO": { primary: "IO", secondary: "+246"},
    "BG": { primary: "BG", secondary: "+359"},
    "BF": { primary: "BF", secondary: "+226"},
    "BI": { primary: "BI", secondary: "+257"},
    "KH": { primary: "KH", secondary: "+855"},
    "CM": { primary: "CM", secondary: "+237"},
    "CA": { primary: "CA", secondary: "+1"},
    "CV": { primary: "CV", secondary: "+238"},
    "KY": { primary: "KY", secondary: "+345"},
    "CF": { primary: "CF", secondary: "+236"},
    "TD": { primary: "TD", secondary: "+235"},
    "CL": { primary: "CL", secondary: "+56"},
    "CN": { primary: "CN", secondary: "+86"},
    "CO": { primary: "CO", secondary: "+57"},
    "KM": { primary: "KM", secondary: "+269"},
    "CG": { primary: "CG", secondary: "+242"},
    "CD": { primary: "CD", secondary: "+243"},
    "CK": { primary: "CK", secondary: "+682"},
    "CR": { primary: "CR", secondary: "+506"},
    "CI": { primary: "CI", secondary: "+225"},
    "HR": { primary: "HR", secondary: "+385"},
    "CU": { primary: "CU", secondary: "+53"},
    "CY": { primary: "CY", secondary: "+357"},
    "CZ": { primary: "CZ", secondary: "+420"},
    "DK": { primary: "DK", secondary: "+45"},
    "DJ": { primary: "DJ", secondary: "+253"},
    "DM": { primary: "DM", secondary: "+1767"},
    "DO": { primary: "DO", secondary: "+1849"},
    "EC": { primary: "EC", secondary: "+593"},
    "EG": { primary: "EG", secondary: "+20"},
    "SV": { primary: "SV", secondary: "+503"},
    "GQ": { primary: "GQ", secondary: "+240"},
    "ER": { primary: "ER", secondary: "+291"},
    "EE": { primary: "EE", secondary: "+372"},
    "ET": { primary: "ET", secondary: "+251"},
    "FK": { primary: "FK", secondary: "+500"},
    "FO": { primary: "FO", secondary: "+298"},
    "FJ": { primary: "FJ", secondary: "+679"},
    "FI": { primary: "FI", secondary: "+358"},
    "FR": { primary: "FR", secondary: "+33"},
    "PF": { primary: "PF", secondary: "+594"},
    "GA": { primary: "GA", secondary: "+241"},
    "GM": { primary: "GM", secondary: "+220"},
    "GE": { primary: "GE", secondary: "+995"},
    "DE": { primary: "DE", secondary: "+49"},
    "GH": { primary: "GH", secondary: "+233"},
    "GI": { primary: "GI", secondary: "+350"},
    "GR": { primary: "GR", secondary: "+30"},
    "GL": { primary: "GL", secondary: "+299"},
    "GD": { primary: "GD", secondary: "+1473"},
    "GU": { primary: "GU", secondary: "+1671"},
    "GT": { primary: "GT", secondary: "+502"},
    "GG": { primary: "GG", secondary: "+44"},
    "GN": { primary: "GN", secondary: "+224"},
    "GW": { primary: "GW", secondary: "+245"},
    "HT": { primary: "HT", secondary: "+509"},
    "HN": { primary: "HN", secondary: "+504"},
    "HK": { primary: "HK", secondary: "+852"},
    "HU": { primary: "HU", secondary: "+36"},
    "IS": { primary: "IS", secondary: "+354"},
    "IN": { primary: "IN", secondary: "+91"},
    "ID": { primary: "ID", secondary: "+62"},
    "IR": { primary: "IR", secondary: "+98"},
    "IQ": { primary: "IQ", secondary: "+964"},
    "IE": { primary: "IE", secondary: "+353"},
    "IM": { primary: "IM", secondary: "+44"},
    "IL": { primary: "IL", secondary: "+972"},
    "IT": { primary: "IT", secondary: "+39"},
    "JM": { primary: "JM", secondary: "+1876"},
    "JP": { primary: "JP", secondary: "+81"},
    "JE": { primary: "JE", secondary: "+44"},
    "JO": { primary: "JO", secondary: "+962"},
    "KZ": { primary: "KZ", secondary: "+77"},
    "KE": { primary: "KE", secondary: "+254"},
    "KI": { primary: "KI", secondary: "+686"},
    "KP": { primary: "KP", secondary: "+850"},
    "KR": { primary: "KR", secondary: "+82"},
    "KW": { primary: "KW", secondary: "+965"},
    "KG": { primary: "KG", secondary: "+996"},
    "LA": { primary: "LA", secondary: "+856"},
    "LV": { primary: "LV", secondary: "+371"},
    "LB": { primary: "LB", secondary: "+961"},
    "LS": { primary: "LS", secondary: "+266"},
    "LR": { primary: "LR", secondary: "+231"},
    "LY": { primary: "LY", secondary: "+218"},
    "LI": { primary: "LI", secondary: "+423"},
    "LT": { primary: "LT", secondary: "+370"},
    "LU": { primary: "LU", secondary: "+352"},
    "MO": { primary: "MO", secondary: "+853"},
    "MK": { primary: "MK", secondary: "+389"},
    "MG": { primary: "MG", secondary: "+261"},
    "MW": { primary: "MW", secondary: "+265"},
    "MY": { primary: "MY", secondary: "+60"},
    "MV": { primary: "MV", secondary: "+960"},
    "ML": { primary: "ML", secondary: "+223"},
    "MT": { primary: "MT", secondary: "+356"},
    "MH": { primary: "MH", secondary: "+692"},
    "MQ": { primary: "MQ", secondary: "+596"},
    "MR": { primary: "MR", secondary: "+222"},
    "MU": { primary: "MU", secondary: "+230"},
    "MX": { primary: "MX", secondary: "+52"},
    "FM": { primary: "FM", secondary: "+691"},
    "MD": { primary: "MD", secondary: "+373"},
    "MC": { primary: "MC", secondary: "+377"},
    "MN": { primary: "MN", secondary: "+976"},
    "ME": { primary: "ME", secondary: "+382"},
    "MS": { primary: "MS", secondary: "+1664"},
    "MA": { primary: "MA", secondary: "+212"},
    "MZ": { primary: "MZ", secondary: "+258"},
    "MM": { primary: "MM", secondary: "+95"},
    "NA": { primary: "NA", secondary: "+264"},
    "NR": { primary: "NR", secondary: "+674"},
    "NP": { primary: "NP", secondary: "+977"},
    "NL": { primary: "NL", secondary: "+31"},
    "NZ": { primary: "NZ", secondary: "+64"},
    "NI": { primary: "NI", secondary: "+505"},
    "NE": { primary: "NE", secondary: "+227"},
    "NG": { primary: "NG", secondary: "+234"},
    "NU": { primary: "NU", secondary: "+683"},
    "NF": { primary: "NF", secondary: "+672"},
    "MP": { primary: "MP", secondary: "+1670"},
    "NO": { primary: "NO", secondary: "+47"},
    "OM": { primary: "OM", secondary: "+968"},
    "PK": { primary: "PK", secondary: "+92"},
    "PW": { primary: "PW", secondary: "+680"},
    "PS": { primary: "PS", secondary: "+970"},
    "PA": { primary: "PA", secondary: "+507"},
    "PG": { primary: "PG", secondary: "+675"},
    "PY": { primary: "PY", secondary: "+595"},
    "PE": { primary: "PE", secondary: "+51"},
    "PH": { primary: "PH", secondary: "+63"},
    "PN": { primary: "PN", secondary: "+872"},
    "PL": { primary: "PL", secondary: "+48"},
    "PT": { primary: "PT", secondary: "+351"},
    "PR": { primary: "PR", secondary: "+1939"},
    "QA": { primary: "QA", secondary: "+974"},
    "RO": { primary: "RO", secondary: "+40"},
    "RU": { primary: "RU", secondary: "+7"},
    "RW": { primary: "RW", secondary: "+250"},
    "KN": { primary: "KN", secondary: "+1869"},
    "LC": { primary: "LC", secondary: "+1758"},
    "WS": { primary: "WS", secondary: "+685"},
    "SM": { primary: "SM", secondary: "+378"},
    "ST": { primary: "ST", secondary: "+239"},
    "SA": { primary: "SA", secondary: "+966"},
    "SN": { primary: "SN", secondary: "+221"},
    "RS": { primary: "RS", secondary: "+381"},
    "SC": { primary: "SC", secondary: "+248"},
    "SL": { primary: "SL", secondary: "+232"},
    "SG": { primary: "SG", secondary: "+65"},
    "SK": { primary: "SK", secondary: "+421"},
    "SI": { primary: "SI", secondary: "+386"},
    "SB": { primary: "SB", secondary: "+677"},
    "SO": { primary: "SO", secondary: "+252"},
    "ZA": { primary: "ZA", secondary: "+27"},
    "SS": { primary: "SS", secondary: "+211"},
    "ES": { primary: "ES", secondary: "+34"},
    "LK": { primary: "LK", secondary: "+94"},
    "SD": { primary: "SD", secondary: "+249"},
    "SR": { primary: "SR", secondary: "+597"},
    "SZ": { primary: "SZ", secondary: "+268"},
    "SE": { primary: "SE", secondary: "+46"},
    "CH": { primary: "CH", secondary: "+41"},
    "SY": { primary: "SY", secondary: "+963"},
    "TW": { primary: "TW", secondary: "+886"},
    "TJ": { primary: "TJ", secondary: "+992"},
    "TZ": { primary: "TZ", secondary: "+255"},
    "TH": { primary: "TH", secondary: "+66"},
    "TG": { primary: "TG", secondary: "+228"},
    "TK": { primary: "TK", secondary: "+690"},
    "TO": { primary: "TO", secondary: "+676"},
    "TT": { primary: "TT", secondary: "+1868"},
    "TN": { primary: "TN", secondary: "+216"},
    "TR": { primary: "TR", secondary: "+90"},
    "TM": { primary: "TM", secondary: "+993"},
    "TC": { primary: "TC", secondary: "+1649"},
    "TV": { primary: "TV", secondary: "+688"},
    "UG": { primary: "UG", secondary: "+256"},
    "UA": { primary: "UA", secondary: "+380"},
    "AE": { primary: "AE", secondary: "+971"},
    "GB": { primary: "GB", secondary: "+44"},
    "US": { primary: "US", secondary: "+1"},
    "UY": { primary: "UY", secondary: "+598"},
    "UZ": { primary: "UZ", secondary: "+998"},
    "VU": { primary: "VU", secondary: "+678"},
    "VE": { primary: "VE", secondary: "+58"},
    "VN": { primary: "VN", secondary: "+84"},
    "VI": { primary: "VI", secondary: "+1340"},
    "YE": { primary: "YE", secondary: "+967"},
    "ZM": { primary: "ZM", secondary: "+260"},
    "ZW": { primary: "ZW", secondary: "+263"}
}

export const getIndivative = (country) => {
    if(country){
        const result = Object.values(countries).filter(c => c.primary === country);
        return result[result.length - 1].secondary;
    }
}

export const getCountry = (indicative) => {
    const result = Object.values(countries).filter(c => c.secondary === indicative);
    return result[result.length - 1].primary;
}