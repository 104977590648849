import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/auth-action';
import Loading from './Loading';

const Logout = (props) => {

    useEffect(() => {
        props.logout();
    },[]);

    return <Loading />
}

const mapStateToProps = state => ({
	token: state.auth.token
})

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);