import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import Card from '../assets/card-membership.png';
import QRCode from "react-qr-code";
import moment from 'moment';
import axios from 'axios';
import { config } from '../config';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
//import '../card.style.css';
import toast from 'react-hot-toast';

const Membership = (props) => {
    const { id } = useParams();
    const [details, setDetails] = useState({ _id: null });

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/membership/${id}`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' } });
            if(response.data.status === 'SUCCESS'){
                setDetails(response.data.data);
            }
        }catch(e){
            console.log(e);
            toast.error('Page not found');
        }
    }

    useEffect(() => {
        refreshData();
    },[id])

    const download = () => {
        html2canvas(document.getElementById('card-membership')).then(canvas => 
            saveAs(canvas.toDataURL(), 'membership-card.png')
        );
    }

    const saveAs = (uri, filename) => {
        var link = document.createElement('a');
        if(typeof link.download === 'string') {
            link.href = uri;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }else{
            window.open(uri);
        }
    }


    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Membership</Title>
                </div>
            </div>
            {(details && details._id) &&
            <div style={{ marginRight: 21 }}>
                <div style={{marginTop: 14, marginBottom: 11}}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <p style={{marginBottom: 0, fontSize: 18, color: '#000', fontFamily: 'Roboto'}}>Hi {`${(details && details._id) ? `${details.customer.first_name} ${details.customer.last_name}` : ''}`}, download the linked file since the link can only be seen for this time</p>
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: 10 }}>
                                {(details && details._id) &&
                                    <div style={{position: 'relative'}} id="card-membership">
                                        <CardMembership src={Card} />
                                        <div style={{position: 'absolute', height: '100%', width: '100%', top: 0, zIndex: 2}}>
                                            <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'right', marginTop: 14, marginRight: 18}}>{details.code && details.code}</p>
                                            <h1 style={{marginBottom: 0, fontSize: 39, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center'}}>{details.plan && details.plan.name}</h1>
                                            <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'center'}}>VALID UNTIL: {details.endDate && moment(details.endDate).format('MMMM DD, YYYY').toUpperCase()}</p>
                                            <div className="d-flex justify-content-between" style={{ marginLeft: 30, marginRight: 29, marginTop: 23 }}>
                                                <div>
                                                    <h3 style={{marginBottom: 0, fontSize: 20, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto'}}>{details.plan && details.plan.type === 'unlimited' ? 'Unlimited' : details.owner}</h3>
                                                    {details.affiliates &&
                                                        details.affiliates.map((af, i) => (
                                                            <h3 style={{marginBottom: 0, fontSize: 16, color: '#000', lineHeight: 1, fontFamily: 'Roboto', marginTop: 5}} key={i}>{af}</h3>
                                                        ))
                                                    }
                                                </div>
                                                <QRCode value={details._id && details._id} size={143} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="text-center mt-65 mb-40">
                                <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => download()} width={'149px'} color={'#FFF'}>Download</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </Layout>
    )
}

const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #000;
`;
const TitleModal = styled.h1`
    font-family: 'Montserrat';
    color: ${({color}) => color ? color: '#1A1A1A'};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;
`;
const CardMembership = styled.img`
    width: 100%;
`;
const Button = styled.button`
    background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
    border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
    color: ${({color}) => color ? color : '#000'};
    border-radius: 10px;
    height: ${({height}) => height ? height : '40px'};
    width: ${({width}) => width ? width : '100px'};
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: ${({bold}) => bold ? bold : '400'};
    font-family: 'Roboto';
    margin-right: 5px;
`;

export default Membership;