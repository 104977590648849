import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminRoutes from '../routes/Admin';
import AuthRoutes from '../routes/Auth';
import SalesRoutes from '../routes/Sales';
import { AuthContext } from '../provider/auth';
import Loading from '../screens/Loading';

const Navigation = () => {
    const auth = useContext(AuthContext);
	const user = auth.user;

    return(
        <BrowserRouter>
            {user === null && <Loading />}
            {user === false && (
                <Switch>
                    <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
                    <Route path="/sales" render={(props) => <SalesRoutes {...props} />} />
                    <Redirect from="/" to="/auth/login" />
                </Switch>
            )}
            {user === true && (
                <Switch>
                    <Route path="/admin" render={(props) => <AdminRoutes {...props} />} />
                    <Route path="/sales" render={(props) => <SalesRoutes {...props} />} />
                    <Redirect from="/" to="/admin/index" />
                </Switch>
            )}
        </BrowserRouter>
    )
}

export default Navigation;