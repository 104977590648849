import React from 'react';
import { Modal } from 'react-bootstrap';

const ComponentModal = (props) => {
    return (
        <>
        <Modal show={props.show} onHide={props.onHide} backdrop={props.backdrop ? 'static' : true} dialogClassName={props.dialogClassName} centered={props.centered}>
            <Modal.Body style={props.noPadding ? { paddingLeft: 0, paddingRight: 0 }: {}}>
                {props.children}
            </Modal.Body>
        </Modal>
        </>
    );
}

export default ComponentModal;