import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeaderTable from '../components/TableRounded/Header';
import Modal from '../components/Modal';
import Select from 'react-select';
import CloseModal from '../assets/close-modal.png';
import toast from 'react-hot-toast';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MoneyInput from "@rschpdr/react-money-input";
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { config } from '../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from '../components/Pagination/Pagination';
import { useLocation } from "react-router-dom";
import { format_currency, validate_session } from '../utils';

const Partners = (props) => {
    const module = 'Settings';
    const location = useLocation();
    const isNumber = /^[0-9]+$/;
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [partners, setPartners] = useState([]);
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [beach, setBeach] = useState('');
    const [beaches, setBeaches] = useState([]);
    const [rooms, setRooms] = useState('');
    const [products, setProducts] = useState([]);
    const [signature, setSignature] = useState(null);
    const [id, setId] = useState('');
    const [details, setDetails] = useState({});
    const [typeCost, setTypeCost] = useState('');
    const [price, setPrice] = useState('0');

    const [productsOptions, setProductsOptions] = useState([]);

    useEffect(() => {
        validate_session(props.token);
    },[])

    const onShowCreate = () => {
        if(permissions() && permissions().actionCreate){
            setShowCreate(!showCreate);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowEdit = () => {
        if(permissions() && permissions().actionEdit){
            setShowEdit(!showEdit);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDelete = () => {
        if(permissions() && permissions().actionDelete){
            setShowDelete(!showDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }
    const onShowDetail = () => setShowDetail(!showDetail);

    const permissions = () => {
        const data = props.userData;
        if(data.role){
           const action = data.role.modules.filter(m => m.name === module);
           return action[0];
        }else{
            return null;
        }
    }

    useEffect(() => {
        if(location.state){
            if(location.state.onModal){
                onShowCreate();
            }
        }
    }, [location])

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(partners.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, partners, PageSize]);

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(partners.length){
                partners.map(partner => {
                    data.push({ CODE: partner.code, NAME: partner.name, BEACH: partner.beach.name, ROOMS: partner.numberOfRoom, USERS: 0, PRODUCTS: partner.products ? partner.products.map(p => p.name).join(', ') : '', LAST_UPLOAD: moment(partner.updateAt).format('MMM DD, YYYY - hh:mm A')});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Partners PDF";
            const headers = [['ID', 'NAME', 'BEACH', 'N. OF ROOMS', 'N. OF USERS', 'PRODUCTS', 'LAST UPLOAD']];
        
            const data = partners.map(partner => [partner.code, partner.name, partner.beach.name, partner.numberOfRoom, 0, partner.products ? partner.products.map(p => p.name).join(', ') : '', moment(partner.updateAt).format('MMM DD, YYYY - hh:mm A')]);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("partners-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `partners-estebans-place` + fileExtension);
    };

    const refreshData = async () => {
        try{
            const response = await axios.get(`${config.URI}/partners`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setPartners(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }
    
    const refreshBeaches = async () => {
        try{
            const response = await axios.get(`${config.URI}/beaches`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setBeaches(response.data.data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    // useEffect(() => {
    //     if(beaches.length > 0 && partners.length > 0){
    //         const bs = beaches.filter(b => partners.filter(p => p.beach._id === b._id).length === 0);
    //         setBeaches(bs);
    //     }
    // }, [partners])

    const [productData, setProductData] = useState([]);

    const refreshProducts = async () => {
        try{
            const response = await axios.get(`${config.URI}/products`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                const data = response.data.data;
                setProductData(data);
            }
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    useEffect(() => {
        if(beach !== ''){
            const options = [];
            for(let i = 0; i < productData.length; i++){
                if(productData[i].type === 'Rental' && productData[i].beach._id === beach)
                    options.push({ value: productData[i]._id, label: productData[i].name });
            }
            setProductsOptions(options);
        }
    },[beach]);

    useEffect(() => {
        refreshData();
        refreshProducts();
        refreshBeaches();
    }, []);

    const onSubmitCreatePartner = async () => {
        if(validate_field()){
            onShowCreate();
            const toastId = toast.loading('Loading...');
            const response = await axios.post(`${config.URI}/partners`, { name, numberOfRoom: rooms, signature, beach, products, typeCost, price: price.toString() },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                clean();
                toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const validate_field = () => {
        if(name === ''){
            messageErrorMessage('Invalid name input.');
            setErrors({ name: true });
        }else if(beach === ''){
            messageErrorMessage("Don't select beach.");
            setErrors({ beach: true });
        }else if(rooms === ''){
            messageErrorMessage('Invalid number of rooms input.');
            setErrors({ rooms: true });
        }else if(typeCost === ''){
            messageErrorMessage("Don't select billing type.");
            setErrors({ billing: true });
        }else if(products.length === 0){
            messageErrorMessage("Don't select products.");
            setErrors({ products: true });
        }else if(signature === null){
            messageErrorMessage("Don't select signature required.");
            setErrors({ signature: true });
        }else{
            setErrors({});
            return true;
        }
    }

    const clean = () => {
        setName('');
        setBeach('');
        setRooms('');
        setProducts([]);
        setSignature(null);
        setTypeCost('');
        setPrice('');
    }

    const onValuesShowEdit = (partner) => {
        setName(partner.name);
        setBeach(partner.beach._id);
        setRooms(partner.numberOfRoom);
        setProducts(partner.products.map(pt => { return { value: pt._id, label: pt.name } }));
        setSignature(partner.signature);
        setId(partner._id);
        if(partner.price){
            setTypeCost(partner.typeCost);
            setPrice(partner.price);
        }
        onShowEdit();
    }

    const onSubmitEditPartner = async () => {
        if(validate_field()){
            onShowEdit();
            const toastId = toast.loading('Loading...');
            console.log(price)
            const response = await axios.put(`${config.URI}/partners/${id}`, { name, numberOfRoom: rooms, signature, beach, products: products.map(p => p.value), typeCost, price: price.toString() },{ headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                refreshData();
                clean();
                toast.success((t) => (<span>Successfully edited.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }else{
                messageError();
            }
            toast.dismiss(toastId);
        }
    }

    const onValueDeletePartner = async (id) => {
        setId(id);
        onShowDelete();
    }

    const onSubmitDelete = async () => {
        onShowDelete();
        const toastId = toast.loading('Loading...');
        const response = await axios.delete(`${config.URI}/partners/${id}`, { headers: { 'Accept': 'application/json', 'Content-type': 'application/json', 'Authorization': props.token } });
        if(response.statusText === 'OK'){
            refreshData();
            clean();
            toast.success((t) => (<span>Successfully deleted.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
        }else{
            messageError();
        }
        toast.dismiss(toastId);
    }

    const onValueDetailsPartner = (partner) => {
        setDetails({...partner});
        onShowDetail();
    }

    const [search, setSearch] = useState('');
    const [reply, setReply] = useState([]);

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const partners_search = reply.filter(item => item.name.toLowerCase().search(value.toLowerCase()) !== -1 || item.beach.name.toLowerCase().search(value.toLowerCase()) !== -1);
            setPartners(partners_search);
            setCurrentPage(1);
        }
        if(value === ''){
            refreshData();
        }
    }

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])
    
    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Settings - Partners</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                    <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onShowCreate()} color={'#FFF'} bold={'700'}>CREATE</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'ID'}, {name: 'Name'}, {name: 'Beach'}, {name: '# OF Rooms'}, {name: '# OF Users'}, {name: 'Products'}, {name: 'Cost'}, {name: 'Last Upload'}, { name: 'Actions'}]} />
                        {partners.length > 0 && (
                            <>
                                {currentTableData.map((partner, i) => (
                                    <ContentBody key={i} backgroundColor={((i + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'} className="text-center">
                                        <Column className="rowBorderStart">{partner.code}</Column>
                                        <Column className="rowBorderMiddle">{partner.name}</Column>
                                        <Column className="rowBorderMiddle">{partner.beach.name}</Column>
                                        <Column className="rowBorderMiddle">{partner.numberOfRoom}</Column>
                                        <Column className="rowBorderMiddle"><Link onClick={(e) => { e.preventDefault();}}  style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#1A1A1A' }}>{partner.guests} <FontAwesomeIcon icon={faEye} size="sm" /></Link></Column>
                                        <Column className="rowBorderMiddle"><Link onClick={(e) => { e.preventDefault(); onValueDetailsPartner(partner); }} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#1A1A1A' }}>{partner.products.length} Products <FontAwesomeIcon icon={faEye} size="sm" /></Link></Column>
                                        <Column className="rowBorderMiddle">{partner.price ?  format_currency(partner.price) + ' ' + partner.typeCost.charAt(0).toUpperCase() +  partner.typeCost.slice(1): ''}</Column>
                                        <Column className="rowBorderMiddle">{moment(partner.updateAt).format('MMM DD, YYYY - hh:mm A')}</Column>
                                        <Column className="rowBorderEnd">
                                            <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                <TextAction onClick={() => onValueDetailsPartner(partner)}>Details&nbsp;</TextAction>-<TextAction onClick={() => onValuesShowEdit(partner)}>&nbsp;Edit&nbsp;</TextAction>-<TextAction onClick={() => onValueDeletePartner(partner._id)}>&nbsp;Delete</TextAction>
                                            </div>
                                        </Column>
                                    </ContentBody>
                                ))}
                            </>
                        )}
                    </Table>
                    {partners.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {partners.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={partners.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showCreate} onHide={onShowCreate} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowCreate();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Create Partner</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Business name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beach</Form.Label>
                        <Form.Control as="select" onChange={(e) => setBeach(e.target.value)} defaultValue={beach} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {beaches.map((beach) => (
                                <option value={beach._id}>{beach.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Number of rooms</Form.Label>
                        <Form.Control type="text" onChange={(e) => (isNumber.test(e.target.value) || e.target.value.length === 0) && setRooms(e.target.value)} defaultValue={rooms} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.rooms ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Products included</Form.Label>
                        <Select options={productsOptions} onChange={(tags) => setProducts(tags.map(t => t.value))} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Billing type</Form.Label>
                                <Form.Control as="select" onChange={(e) => setTypeCost(e.target.value)} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option>
                                    <option value="month">Per month</option>
                                    <option value="day">Per day</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Cost</Form.Label>
                                <MoneyInput onChange={(e) => setPrice(e.target.value)} value={price} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Is signature required?</Form.Label>
                        <div className="d-flex">
                            <label className="container-radio">Yes
                                <input type="radio" name="signature" onChange={(e) => setSignature(e.target.checked ? true : null)} />
                                <span className="radiomark"></span>
                            </label>

                            <label className="container-radio ml-48">No
                                <input type="radio" name="signature" onChange={(e) => setSignature(e.target.checked ? false : null)} />
                                <span className="radiomark"></span>
                            </label>
                        </div>
                    </Form.Group>
                    <div className="text-center mt-65 mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowCreate();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitCreatePartner()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDelete} onHide={onShowDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Delete Partner</TitleModal>
                    <TextModal className="mb-40 mt-30">Please confirm partner deletion. Deleted items can be found and recovered from the main menu option.</TextModal>
                    <div className="text-center mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" width={'149px'} onClick={() => onShowDelete()}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} width={'149px'} onClick={() => onSubmitDelete()} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showEdit} onHide={onShowEdit} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => {onShowEdit();clean();}}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingLeft: 30, paddingRight: 30 }}>
                    <TitleModal>Edit Partner</TitleModal>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Business name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.name ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Beach</Form.Label>
                        <Form.Control as="select" onChange={(e) => setBeach(e.target.value)} defaultValue={beach} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.beach ? '#FF0F00' :'#BDBDBD', height: 50, borderRadius: 10}}>
                            <option></option>
                            {beaches.map((beach) => (
                                <option value={beach._id}>{beach.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Number of rooms</Form.Label>
                        <Form.Control type="text" onChange={(e) => (isNumber.test(e.target.value) || e.target.value.length === 0) && setRooms(e.target.value)} value={rooms} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: errors.rooms ? '#FF0F00' : '#BDBDBD', height: 50, borderRadius: 10}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Products included</Form.Label>
                        <Select options={productsOptions} defaultValue={products} onChange={(tags) => setProducts(tags)} placeholder=" " isMulti closeMenuOnSelect={false} isClearable={false} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Billing type</Form.Label>
                                <Form.Control as="select" onChange={(e) => setTypeCost(e.target.value)} defaultValue={typeCost} style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}}>
                                    <option></option>
                                    <option value="month">Per month</option>
                                    <option value="day">Per day</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Cost</Form.Label>
                                <MoneyInput onChange={(e) => setPrice(e.target.value)} value={price} currencyConfig={{ locate: "en-US", currencyCode: 'USD' }} className="form-control" style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto', borderColor: '#BDBDBD', height: 50, borderRadius: 10}} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label style={{color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>Is signature required?</Form.Label>
                        <div className="d-flex">
                            <label className="container-radio">Yes
                                <input type="radio" name="signature" onChange={(e) => setSignature(e.target.checked ? true : null)} checked={signature ? 'checked' : ''} />
                                <span className="radiomark"></span>
                            </label>

                            <label className="container-radio ml-48">No
                                <input type="radio" name="signature" onChange={(e) => setSignature(e.target.checked ? false : null)} checked={!signature ? 'checked' : ''} />
                                <span className="radiomark"></span>
                            </label>
                        </div>
                    </Form.Group>
                    <div className="text-center mt-65 mb-40">
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => {onShowEdit();clean();}} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} onClick={() => onSubmitEditPartner()} width={'149px'} color={'#FFF'}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDetail} onHide={onShowDetail} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onShowDetail()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal>Partner Details</TitleModal>
                </div>
                <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                    <tbody>
                        <tr>
                            <TitleDetails>ID</TitleDetails>
                            <TextDetails>{details.code ? details.code : ''}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>BUSINESS NAME</TitleDetails>
                            <TextDetails>{details.name ? details.name : ''}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>BEACH</TitleDetails>
                            <TextDetails>{details.beach ? details.beach.name : '' }</TextDetails>
                            <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height={'28px'} width="103px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                        </tr>
                        <tr>
                            <TitleDetails>NUMBER OF ROOMS</TitleDetails>
                            <TextDetails>{details.numberOfRoom ? `${details.numberOfRoom}` : ''}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>NUMBER OF USERS</TitleDetails>
                            <TextDetails>{details.guests ? details.guests : '0'}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>COST</TitleDetails>
                            <TextDetails>{details.price ?  format_currency(details.price) + ' ' + details.typeCost.charAt(0).toUpperCase() +  details.typeCost.slice(1): '0'}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>LAST FILE UPLOAD</TitleDetails>
                            <TextDetails>{details.updateAt ? moment(details.updateAt).format('MMM DD, YYYY - hh:mm A') : ''}</TextDetails>
                            <td></td>
                        </tr>
                        <tr>
                            <TitleDetails>SIGNATURE REQUIRED?</TitleDetails>
                            <TextDetails>{details.signature !== null ? details.signature ? 'Yes' : 'No' : ''}</TextDetails>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <TitleDetails>PRODUCTS INCLUDED</TitleDetails>
                {details.products &&
                    <div style={{ marginTop: 10 }}>
                        {details.products.map(product => (
                            <div style={{ borderColor: '#9F9F9F', borderWidth: 1, borderStyle: 'solid', height: 45, marginRight: 20, marginLeft: 20, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} className="d-flex justify-content-between">
                                <div className="d-flex" style={{alignItems: 'center'}}>
                                    <Content>
                                        <Img src={product.file} alt="product" />
                                    </Content>
                                    <TextDetailsCard>{product.name}</TextDetailsCard>
                                </div>
                                <TextDetailsCard style={{paddingRight: 16, fontWeight: '700'}}>${product.price}</TextDetailsCard>
                            </div>
                        ))}
                    </div>
                }
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} onClick={() => onShowDetail()}>Close</Button>
                </div>
            </Modal>
        </Layout>
    )
}
const TextDetails = styled.td`
font-size: 14px;
padding-left: 30px !important;
font-family: 'Roboto';
text-transform: uppercase;
color: #1A1A1A;
`;
const TextDetailsCard = styled.p`
font-size: 14px;
padding-left: 14px !important;
font-family: 'Roboto';
color: #1A1A1A;
margin-bottom: 0 !important;
`;
const Content = styled.div`
height: 29px;
width: 29px;
margin-left: 16px;
`;
const Img = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
object-position: center;
border-radius: 50%;
`;
const TitleDetails = styled.td`
font-size: 14px;
min-width: 210px;
padding-left: 30px !important;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
text-align: center;
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
`;
const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;

const mapStateToProps = state => ({
	token: state.auth.token,
    userData:  state.auth.userData
})

export default connect(mapStateToProps)(Partners);