import React from 'react';
import Avatar from './Avatar';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/modal-action';

const CardUser = (props) => {
    return(
        <div style={{ margin: 10, marginLeft: 20, marginRight: 20, padding: 10, display: 'flex', border: '1px solid #FFEB11', borderRadius: 10, cursor: 'pointer' }} onClick={() => props.onShow()} className="d-flex">
            <Avatar src={props.userData.profile_photo ? props.userData.profile_photo : 'https://c0.klipartz.com/pngpicture/11/510/sticker-png-computer-icons-colorado-state-university-user-profile-miscellaneous-service.png'} />
            <div style={{ marginLeft: 10, marginTop: 10 }}>
                <h4 style={{ marginBottom: 0, lineHeight: 1, color: '#FFF', fontSize: 16, fontWeight: '700', fontFamily: 'Montserrat' }}>{props.userData.name ? props.userData.name : ''}</h4>
                <p style={{ marginBottom: 0, color: '#FFF', fontSize: 13, fontFamily: 'Montserrat', fontWeight: '300' }}>{props.userData.role ? props.userData.role.name : ''}</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	show: state.modal.showprofile,
    token: state.auth.token,
    userData: state.auth.userData
})

const mapDispatchToProps = dispatch => ({
    onHide: () => dispatch(actions.hidenProfile()),
    onShow: () => dispatch(actions.showProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(CardUser);