import React from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import styled from "styled-components";

const StyledTimePicker = styled(TimePicker)`
  .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe !important;
  }
`;

const ComponentTimePicker = ({ value, onChange, ...others }) => {
  return (
    <StyledTimePicker
        showSecond={false}
        onChange={onChange}
        hideDisabledOptions
        minuteStep={1}
        {...others}
        value={value}
        use12Hours
    />
  );
};

export default ComponentTimePicker;