import axios from 'axios';
import moment from 'moment';
import { config } from '../config';
import { logout } from '../redux/actions/auth-action';
import { store } from '../redux/store';

export const upload = async (file, token) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${config.URI}/upload`, formData, { headers: { 'Accept': 'multipart/form-data', 'Content-type': 'multipart/form-data', 'Authorization': token } });
    if(response.statusText === 'OK'){
        return response.data.data.location;
    }
    return null;
}

export const format_currency = (price = 0) => {
    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    return dollarUS.format(price)
}

export const validate_session = (jwtToken) => {
    if (jwtToken) {
        try{
            const [, payload] = jwtToken.split('.');
            const { exp: expires } = JSON.parse(window.atob(payload));
            if (typeof expires === 'number') {
                if(moment(new Date(), 'MMM DD, YYYY').isSameOrAfter(moment(new Date(expires * 1000), 'MMM DD, YYYY'))){
                    store.dispatch(logout());
                }
            }
        }catch{}
    }
}