import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/modal-action';

const SidebarLink = styled(Link)`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  color: ${(({ open }) => open ? '#000' : '#FFF')};
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 5px !important;
  list-style: none;
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  background: ${(({ open }) => open ? '#FFEB11' : 'transparent')};
  &:hover {
    color: ${(({ open }) => open ? '#000' : '#FFF')};
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
`;

const DropdownLink = styled(Link)`
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  height: 30px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({color}) => color ? color : '#f5f5f5'};
  font-size: 16px;
  &:hover {
    color: #FFEB11;
    background: transparent;
    cursor: pointer;
  }
`;

const SubMenu = (props) => {
  const [subnav, setSubnav] = useState(false);
  const [visible, setVisible] = useState(true);

  const showSubnav = () => setSubnav(!subnav);

  useEffect(() => {
    if(props.item.subNav && props.item.subNav.findIndex(sub => sub.path === window.location.pathname) !== -1){
      setSubnav(true);
    }
    const view = props.userData.role.modules.filter(mod => mod.name.toLowerCase() === props.item.title.toLowerCase());
    if(view.length && !view[0].actionView){
      setVisible(false);
    }else{
      setVisible(true);
    }
  },[]);

    return (
      <>
      {visible ?
        <>
          <SidebarLink onClick={(e) => {
            if(props.item.subNav){
              e.preventDefault();
              showSubnav()
            }
            if(props.item.action){
              e.preventDefault();
              if(props.item.action === 'profile')
                props.onShowProfile();
              else if(props.item.action == 'support')
                props.onShowSupport();
            }
          }} to={props.item.path} open={subnav || window.location.pathname === props.item.path || (props.item.subNav && props.item.subNav.findIndex(sub => sub.path === window.location.pathname) !== -1)}>
              <div>
                  {props.item.icon}
                  <SidebarLabel>{props.item.title}</SidebarLabel>
              </div>
          </SidebarLink>
          {subnav &&
              props.item.subNav.map((item, index) => (
                  <DropdownLink to={item.path} key={index} color={item.path === window.location.pathname ? '#FFEB11' : '#f5f5f5'}>
                      <SidebarLabel>{item.title}</SidebarLabel>
                  </DropdownLink>
              ))
          }
          </>
        : null}
      </>
    );
};

const mapStateToProps = (state) => ({
  userData:  state.auth.userData
})

const mapDispatchToProps = dispatch => ({
    onShowProfile: () => dispatch(actions.showProfile()),
    onShowSupport: () => dispatch(actions.showSupport())
});

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);