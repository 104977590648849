import React from 'react';
import styled from 'styled-components';

const HeaderTable = (props) => {
    return(
        <>
            <Content>
                {props.header.map((h, i) => (
                    <Column key={i} style={h.width ? { width: h.width } : {}}>{h.name}</Column>
                ))}
            </Content>
        </>
    )
}

const Content = styled.tr`
background-color: rgba(255, 235, 17, 0.2);
border: none;
text-align: center;
`;

const Column = styled.th`
color: #1A1A1A;
padding: .7rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
`;

export default HeaderTable;