import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import HeaderTable from '../components/TableRounded/Header';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../components/Pagination/Pagination';
import moment from 'moment';
import axios from 'axios';
import { config } from '../config';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import CloseModal from '../assets/close-modal.png';
import GoogleMap from '../components/Maps';
import Modal from '../components/Modal';
import Card from '../assets/card-membership.png';
import { format_currency, validate_session } from '../utils';
import QRCode from "react-qr-code";
import Select from 'react-select';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const modules = ['Beaches', 'Users', 'Membership plan', 'Memberships', 'Partners', 'Partnerships', 'Products', 'Roles', 'Revenue'];

const DeletedElements = (props) => {
    const [deletedElements, setDeletedElements] = useState([]);
    const [search, setSearch] = useState('');

    const [PageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([]);
    const [reply, setReply] = useState([]);
    const [details, setDetails] = useState({});
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [typeDetail, setTypeDetails] = useState('');

    const [showModalDetails, setShowModalDetails] = useState(false);
    const [showModalRecovery, setShowModalRecovery] = useState(false);
    const onModalRecovery = () => setShowModalRecovery(!showModalRecovery);
    const hanlderModalDetails = () => setShowModalDetails(!showModalDetails);

    useEffect(() => {
        validate_session(props.token);
    },[])

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setCurrentTableData(deletedElements.slice(firstPageIndex, lastPageIndex));
    }, [currentPage, deletedElements, PageSize]);

    const onRefresh = async () => {
        try{
            const response = await axios.get(`${config.URI}/deleted-elements`, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                setDeletedElements(response.data.data);
                setReply(response.data.data);
            }
        }catch(e){
            console.log(e);
        }
    }

    const permissions = () => {
        const data = props.userData;
        console.log(data)
        if(data.role){
           const action = data.role.modules.filter(m => m.name === 'Deleted Elements');
           return action[0];
        }else{
            return null;
        }
    }

    const onModalDelete = () => {
        if(permissions() && permissions().actionDelete){
            setShowModalDelete(!showModalDelete);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const [dataDeleted, setDataDeleted] = useState({});

    const decodingArray = (array) => {
        let final = [];
        for(let i = 0; i < array.length; i++){
            const item1 = array[i];
            for(let k = 0; k < item1.data.length; k++){
                final.push({ moduleName: item1.name, ...item1.data[k] });
            }
        }
        return final;
    }

    const encodingData = () => {
        if(permissions() && permissions().actionExport){
            const data = [];
            if(deletedElements.length){
                decodingArray(deletedElements).map(item => {
                    data.push({ ID: item.code, MODULE: item.moduleName, NAME: item.moduleName === 'Revenue' ? `${item.spot ? item.spot + ' - ' : ''}`+ item.beach.name : item.moduleName === 'Memberships' ? item.owner : item.name, DELETEON: moment(item.deletedOn).format('MMM DD, YYYY h:mm a').toUpperCase(), DELETEBY: item.deletedBy ? item.deletedBy.name : ''});
                });
            }
            exportToCSV(data);
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportPDF = () => {
        if(permissions() && permissions().actionExport){
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = "Esteban's Place Deleted Elements PDF";
            const headers = [['ID', 'MODULE', 'NAME', 'DELETE ON', 'DELETE BY']];
        
            const data = decodingArray(deletedElements).map(item => [item.code, item.moduleName, item.moduleName === 'Revenue' ? `${item.spot ? item.spot + ' - ' : ''}`+ item.beach.name : item.moduleName === 'Memberships' ? item.owner : item.name, moment(item.deletedOn).format('MMM DD, YYYY h:mm a').toUpperCase(), item.deletedBy ? item.deletedBy.name : '']);
        
            let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                textColor: [0, 0, 0],
                fillColor: [252,249,210]
            }
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("deleted-elements-estebans-place.pdf");
        }else{ messageErrorMessage(`This user doesn't have privileges to perform this action.`); }
    }

    const exportToCSV = (dataencoding) => {
        const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(dataencoding);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `deleted-elements-estebans-place` + fileExtension);
    };

    const messageError = () => toast.error((t) => (<span>An unexpected error occurred.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
    const messageErrorMessage = (message) => toast.error((t) => (<span>{message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));

    const onAction = async (status, module, id) => {
        try{
            const toastId = toast.loading('Loading...');
            const response = await axios.put(`${config.URI}/deleted-elements/${id}`, { module, status }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'Authorization': props.token } });
            if(response.statusText === 'OK'){
                onRefresh();
                setShowModalDelete(false);
                setShowModalRecovery(false);
                toast.success((t) => (<span>Successfully {status === 'ACTIVE' ? 'recover' : 'deleted'}.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
            toast.dismiss(toastId);
        }catch(e){
            console.log(e);
            messageError();
        }
    }

    useEffect(() => {
        onRefresh();
    },[]);

    const filter_matriz = (matriz, search) => {
        console.log(matriz)
        let final = [];
        for(let i = 0; i < matriz.length; i++){
            let ok = [];
            const item1 = matriz[i];
            for(let k = 0; k < item1.data.length; k++){
                if((item1.data[k].name && item1.data[k].name.toLowerCase().search(search.toLowerCase()) !== -1) || item1.data[k].deletedBy.name.toLowerCase().search(search.toLowerCase()) !== -1 || (item1.data[k].owner && item1.data[k].owner.toLowerCase().search(search.toLowerCase()) !== -1) || (item1.name === 'Revenue' && item1.data[k] && item1.data[k].beach.name.toLowerCase().search(search.toLowerCase()) !== -1 )){
                    ok.push(item1.data[k]);
                }
            }
            final.push({ name: item1.name, data: ok });
        }
        return final;
    }

    const onSearch = (value) => {
        setSearch(value);
        if(value.length > 0){
            const deleted_search = filter_matriz(reply, value);
            setDeletedElements(deleted_search);
        }
        if(value === ''){
            onRefresh();
        }
    }

    useEffect(() => {
        if(details && typeDetail === 'Revenue'){
            const commissionType = details.beach.commissionPaymentMethod.filter(c => c === details.paymentMethod);
            let total = 0;
            let commission = 0;
            let percentage = 0;
            if(commissionType.length > 0 && details.beach.commissions === 'General'){
                percentage = details.beach.percentage / 100;
                const prices = details.products.reduce((reduce, product) => reduce + product.price, 0);
                total = Math.abs(prices * percentage - prices);
                commission = prices * percentage;
            }else if(commissionType.length > 0 && details.beach.commissions === 'Individual'){
                percentage = details.user.commission / 100;
                const prices = details.products.reduce((reduce, product) => reduce + product.price, 0);
                total = Math.abs(prices * percentage - prices);
                commission = prices * percentage;
            }else{
                const prices = details.products.reduce((reduce, product) => reduce + product.price, 0);
                total = prices;
            }
            setDetails({ ...details, total, commission, percentage });
        }
    },[details])

    const [module, setModule] = useState([]);

    useEffect(() => {
        if(module.length > 0){
            const filter = reply.filter(item => module.indexOf(item.name) !== -1);
            setDeletedElements(filter);
            setCurrentPage(1);
        }else{
            onRefresh();
        }
    },[module]);

    useEffect(() => {
        if(PageSize){
            setCurrentPage(1);
        }
    }, [PageSize])

    return (
        <Layout>
            <div className="d-md-flex justify-content-between">
                <div className="d-md-flex mb-sm-10">
                    <Title>Deleted Elements</Title>
                    <div>
                        <FontAwesomeIcon icon={faSearch} size="sm" style={{ position: 'absolute', marginLeft: 22, marginTop: 14 }} />
                        <SearchInput type="text" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} value={search} className="search-sm" />
                    </div>
                    <div className="d-flex justify-content-between" style={{marginLeft: 10}}>
                        <div style={{width: 200, marginRight: 10}}>
                            <Select options={modules ? modules.map(b => { return { value: b, label: b } }) : []} onChange={(tags) => setModule(tags.map(t => t.value))} placeholder="All Modules" isMulti closeMenuOnSelect={false} isClearable={false} styles={{ control: (styles) => ({ ...styles, minHeight: 40, borderRadius: 10, marginRight: 5, width: 200 }), multiValue: (styles) => ({ ...styles, color: '#fff', background: '#4FB4E6', borderRadius: 10, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }), multiValueLabel: (styles) => ({ ...styles, fontSize: 9, color: '#fff', borderRadius: 10 }) }} />
                        </div>
                    </div>
                </div>
                <div className="button-right-md-sm">
                    <Button bold={'700'} onClick={() => encodingData()}>EXCEL</Button>
                    <Button bold={'700'} onClick={() => exportPDF()}>PDF</Button>
                </div>
            </div>
            <div style={{ marginRight: 21 }}>
                <div className="table-responsive">
                    <Table className="table-curved">
                        <HeaderTable header={[{name: 'id'}, {name: 'module'}, {name: 'name'}, {name: 'deleted on'}, {name: 'deleted by'}, {name: 'actions'}]} />
                        {deletedElements.length > 0 &&
                            currentTableData.map((item, i) => {
                                return(
                                    item.data.map((sub, k) => (
                                        <ContentBody key={k} backgroundColor={((k + 1) % 2) === 0 ? 'rgba(223, 223, 223, 0.2)' : '#FFF'}>
                                            <Column className="rowBorderStart">{sub.code}</Column>
                                            <Column className="rowBorderMiddle">{item.name}</Column>
                                            <Column className="rowBorderMiddle"><Link onClick={(e) => {e.preventDefault(); setDetails(sub); setTypeDetails(item.name); hanlderModalDetails(); }} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#1A1A1A' }}> {item.name === 'Revenue' ? sub.beach.name : item.name === 'Memberships' ? sub.owner : sub.name} <FontAwesomeIcon icon={faEye} size="sm" /></Link></Column>
                                            <Column className="rowBorderMiddle">{moment(sub.deletedOn).format('MMM DD, YYYY h:mm a').toUpperCase()}</Column>
                                            <Column className="rowBorderMiddle">{sub.deletedBy ? sub.deletedBy.name : ''}</Column>
                                            <Column className="rowBorderEnd">
                                                <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                                    <TextAction onClick={() => {setDataDeleted({name: item.name, id: sub._id});onModalRecovery()}}>Recover&nbsp;</TextAction>-<TextAction onClick={() => {setDataDeleted({name: item.name, id: sub._id});onModalDelete();}}>&nbsp;Fully Delete&nbsp;</TextAction>
                                                </div>
                                            </Column>
                                        </ContentBody>
                                    ))
                                )
                            })
                        }
                    </Table>
                    {deletedElements.length > 0 &&
                        <div className="d-flex w-100" style={{marginBottom: 30}}>
                            <div className="d-flex justify-content-start align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>{(currentPage - 1) * PageSize === 0 ? 1 : (currentPage - 1) * PageSize} - {((currentPage - 1) * PageSize) + PageSize} out of {deletedElements.length} items</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{flex: 1}}>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>View</p>
                                <select className="custom-select custom-select-sm ml-1 mr-1" value={PageSize + ""} onChange={(e) => setPageSize(+e.target.value)} style={{maxWidth: 67, minHeight: 30}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p style={{marginBottom: 0, color: '#1A1A1A', fontSize: 14, fontFamily: 'Roboto'}}>items per page</p>
                            </div>
                            <div className="d-flex justify-content-end align-items-center" style={{flex: 1}}>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={deletedElements.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={showModalDetails} onHide={hanlderModalDetails} dialogClassName="modal-600w" noPadding={true} centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 15, marginTop: 16 }} onClick={() => hanlderModalDetails()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                {typeDetail === 'Beaches' &&
                <>
                    <div style={{ marginTop: 40 }}>
                        <TitleModal>Beach Details</TitleModal>
                    </div>
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <tbody>
                            <tr>
                                <TitleDetails>ID</TitleDetails>
                                <td>{details.code ? details.code : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>NAME</TitleDetails>
                                <td>{details.name ? details.name : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>ADDRESS</TitleDetails>
                                <td>{details.address ? details.address.full_address : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>number of users</TitleDetails>
                                <td>{details.users ? details.users : '0'}</td>
                            </tr>
                            <tr>
                                <TitleDetails>commissions</TitleDetails>
                                <td>{details.commissions ? details.commissions === 'General' ? `${details.commissions} (${details.percentage}%)` : details.commissions : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>opens at</TitleDetails>
                                <td>{details.opensAt ? moment(details.opensAt).format('h:mm a').toUpperCase() : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>closes at</TitleDetails>
                                <td>{details.closesAt ? moment(details.closesAt).format('h:mm a').toUpperCase() : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>index letters</TitleDetails>
                                <td>From A to {details.indexLetter ? details.indexLetter : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>cabanas</TitleDetails>
                                <td>{details.cabanas ? details.cabanas.join(', ') : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ maxHeight: 234 }}>
                        <GoogleMap nameMarket={details.address ? details.address.full_address : ''} latitude={details.address ? details.address.latitude : 0} longitude={details.address ? details.address.longitude : 0} />
                    </div>
                </>
                }
                {typeDetail === 'Products' &&
                <>
                    <div style={{ marginTop: 40 }}>
                        <TitleModal>Product Details</TitleModal>
                    </div>
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <tbody>
                            <tr>
                                <TitleDetails>ID</TitleDetails>
                                <td>{details.code ? details.code : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>NAME</TitleDetails>
                                <td>{details.name ? details.name : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>Beach</TitleDetails>
                                <td>{details.beach ? details.beach.name : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>Total Sales</TitleDetails>
                                <td>$ 0</td>
                            </tr>
                            <tr>
                                <TitleDetails>TYPE</TitleDetails>
                                <td>{details.type ? details.type : ''}</td>
                            </tr>
                            <tr>
                                <TitleDetails>Price</TitleDetails>
                                <td>$ {details.price ? details.price : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'center' }}>
                        <img src={details.file ? details.file : ''} style={{ height: 500, width: 500 }} alt="product" />
                </div>
                </>
                }
                {typeDetail === 'Revenue' &&
                <>
                    <div style={{ marginTop: 40 }}>
                        <TitleModal>Order {details.code ? details.code : ''} - Total: {format_currency(details ? details.products.reduce((reduce, p) => reduce + p.price, 0) : 0)}</TitleModal>
                    </div>
                    {details && details.products.length > 0 &&
                        <div className="mt-30" style={{paddingRight: 20, paddingLeft: 20}}>
                            <TitleDetails style={{borderTop: 'none' }} paddingLeft="10px !important">Products</TitleDetails>
                            {details.products.map((product, index) => (
                                <div style={{ border: '1px solid rgba(159, 159, 159, .2)', height: 45, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} className="d-flex justify-content-between">
                                    <div className="d-flex" style={{alignItems: 'center'}}>
                                        <Content>
                                            <Img src={product.file} alt="product" />
                                        </Content>
                                        <TextDetailsCard>{product.name}</TextDetailsCard>
                                    </div>
                                    <div className="d-flex mr-3">
                                        <TextDetailsCard style={{paddingRight: 16, fontWeight: '700'}}>{format_currency(product.price)}</TextDetailsCard>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <TitleDetails style={{borderTop: 'none'}}>Details</TitleDetails>
                        <tbody>
                            <tr>
                                <TitleDetails>Arrival</TitleDetails>
                                <td>{details.arrival ? moment(details.arrival).format('MMM DD, YYYY - hh:mm A') : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>Departure</TitleDetails>
                                <td>{details.departure ? moment(details.departure).format('MMM DD, YYYY - hh:mm A') : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>BEACH</TitleDetails>
                                <td>{details.beach ? details.beach.address.full_address : '' }</td>
                                <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height="28px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                            </tr>
                            <tr>
                                <TitleDetails>User</TitleDetails>
                                <td>{details.user && details.user.name ? details.user.name : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>Commission</TitleDetails>
                                <td>{typeof details.commission !== 'undefined' ? format_currency(details.commission) + " " + `(${details.percentage * 100}%)` : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>Tip</TitleDetails>
                                <td>{details.tip ? format_currency(details.tip) : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>Payment Method</TitleDetails>
                                <td>{details.paymentMethod ? details.paymentMethod : ''}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </>
                }
                {typeDetail === 'Memberships' &&
                <>
                    <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                        <TitleModal>Membership Details</TitleModal>
                        <div style={{marginTop: 17, position: 'relative'}} id="card-membership">
                            <CardMembership src={Card} />
                            <div style={{position: 'absolute', height: '100%', width: '100%', top: 0, zIndex: 2}}>
                                <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'right', marginTop: 14, marginRight: 18}}>{details.code && details.code}</p>
                                <h1 style={{marginBottom: 0, fontSize: 39, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center'}}>{details.plan && details.plan.name}</h1>
                                <p style={{marginBottom: 0, fontSize: 16, color: '#000', fontFamily: 'Roboto', textAlign: 'center'}}>VALID UNTIL: {details.endDate && moment(details.endDate).format('MMMM DD, YYYY').toUpperCase()}</p>
                                <div className="d-flex justify-content-between" style={{ marginLeft: 30, marginRight: 29, marginTop: 23 }}>
                                    <div>
                                        <h3 style={{marginBottom: 0, fontSize: 20, color: '#000', lineHeight: 1, fontWeight: 'bold', fontFamily: 'Roboto'}}>{details.plan && details.plan.type === 'unlimited' ? 'Unlimited' : details.owner}</h3>
                                        {details.affiliates &&
                                            details.affiliates.map((af, i) => (
                                                <h3 style={{marginBottom: 0, fontSize: 16, color: '#000', lineHeight: 1, fontFamily: 'Roboto', marginTop: 5}} key={i}>{af}</h3>
                                            ))
                                        }
                                    </div>
                                    <QRCode value={details._id && details._id} size={143} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
                {typeDetail === 'Users' &&
                <>
                    <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                        <TitleModal>User Details</TitleModal>
                    </div>
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <tbody>
                            <tr>
                                <TitleDetails>ID</TitleDetails>
                                <td>{details.code ? details.code : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>NAME</TitleDetails>
                                <td>{details.name ? details.name : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>ROLE</TitleDetails>
                                <td>{details.role ? details.role.name : ''}</td>
                                <td></td>
                            </tr>
                            {details.role && details.role.platform === 'Mobile' &&
                            <>
                                <tr>
                                    <TitleDetails>BEACH</TitleDetails>
                                    <td>{details.beach ? details.beach.address.full_address : '' }</td>
                                    <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height="28px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                                </tr>
                                <tr>
                                    <TitleDetails>HOURLY RATE</TitleDetails>
                                    <td>{details.role ? `${format_currency(details.hourlyRate)}` : ''}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <TitleDetails>COMMISSION</TitleDetails>
                                    <td>{details.beach ? details.beach.commissions === 'General' ? `General (${details.beach.percentage}%)` : details.commission+ '%' : ''}</td>
                                    <td></td>
                                </tr>
                            </>
                            }
                            <tr>
                                <TitleDetails>EMAIL</TitleDetails>
                                <td>{details.email ? details.email : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>PHONE</TitleDetails>
                                <td>{details.phone ? `${details.indicative} ${details.phone}` : ''}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>Last Login</TitleDetails>
                                <td>{details.createAt ? moment(details.createAt).format('MMM. DD, YYYY - h:mm a') : ''}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </>
                }
                {typeDetail === 'Membership plan' &&
                <>
                    <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                        <TitleModal>Memebership Plan Details</TitleModal>
                    </div>
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <tbody>
                            <tr>
                                <TitleDetails>ID</TitleDetails>
                                <TextDetails>{details.code ? details.code : ''}</TextDetails>
                            </tr>
                            <tr>
                                <TitleDetails>NAME</TitleDetails>
                                <TextDetails>{details.name ? details.name : ''}</TextDetails>
                            </tr>
                            <tr>
                                <TitleDetails>DURATION</TitleDetails>
                                <TextDetails>{details.duration ? details.duration.split(' ')[0] + ' ' + details.duration.split(' ')[1].substr(0, 1).toUpperCase() + details.duration.split(' ')[1].substr(1) : ''}</TextDetails>
                            </tr>
                            <tr>
                                <TitleDetails>PRICE</TitleDetails>
                                <TextDetails>{details.price ? format_currency(details.price) : ''}</TextDetails>
                            </tr>
                            <tr>
                                <TitleDetails>TYPE</TitleDetails>
                                <TextDetails>{details.type ? details.type.substr(0, 1).toUpperCase() + details.type.substr(1) : ''}</TextDetails>
                            </tr>
                        </tbody>
                    </table>
                </>
                }
                {typeDetail === 'Partners' &&
                <>
                    <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                        <TitleModal>Partner Details</TitleModal>
                    </div>
                    <table className="table table-striped table-collapse padding-horizontal-1rem" style={{marginTop: 35}}>
                        <tbody>
                            <tr>
                                <TitleDetails>ID</TitleDetails>
                                <TextDetails>{details.code ? details.code : ''}</TextDetails>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>BUSINESS NAME</TitleDetails>
                                <TextDetails>{details.name ? details.name : ''}</TextDetails>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>BEACH</TitleDetails>
                                <TextDetails>{details.beach ? details.beach.name : '' }</TextDetails>
                                <td><Button backgroundColor={'#4FB4E6'} borderColor={'#4FB4E6'} height={'28px'} width="103px" color={'#FFF'} onClick={() => window.open(`https://maps.google.com/?q=${details.beach.address.latitude},${details.beach.address.longitude}`)}>View in Map</Button></td>
                            </tr>
                            <tr>
                                <TitleDetails>NUMBER OF ROOMS</TitleDetails>
                                <TextDetails>{details.numberOfRoom ? `${details.numberOfRoom}` : ''}</TextDetails>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>NUMBER OF USERS</TitleDetails>
                                <TextDetails>0</TextDetails>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>LAST FILE UPLOAD</TitleDetails>
                                <TextDetails>{details.updateAt ? moment(details.updateAt).format('MMM DD, YYYY - hh:mm A') : ''}</TextDetails>
                                <td></td>
                            </tr>
                            <tr>
                                <TitleDetails>SIGNATURE REQUIRED?</TitleDetails>
                                <TextDetails>{details.signature !== null ? details.signature ? 'Yes' : 'No' : ''}</TextDetails>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <TitleDetails>PRODUCTS INCLUDED</TitleDetails>
                    {details.products &&
                        <div style={{ marginTop: 10 }}>
                            {details.products.map(product => (
                                <div style={{ borderColor: '#9F9F9F', borderWidth: 1, borderStyle: 'solid', height: 45, marginRight: 20, marginLeft: 20, borderRadius: 10, marginBottom: 5, alignItems: 'center' }} className="d-flex justify-content-between">
                                    <div className="d-flex" style={{alignItems: 'center'}}>
                                        <Content>
                                            <Img src={product.file} alt="product" />
                                        </Content>
                                        <TextDetailsCard>{product.name}</TextDetailsCard>
                                    </div>
                                    <TextDetailsCard style={{paddingRight: 16, fontWeight: '700'}}>${product.price}</TextDetailsCard>
                                </div>
                            ))}
                        </div>
                    }
                </>
                }
                <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                    <Button backgroundColor={'#ECECEC'} borderColor={'#ECECEC'} width="149px" onClick={() => hanlderModalDetails()}>Close</Button>
                </div>
            </Modal>
            <Modal show={showModalDelete} onHide={onModalDelete} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onModalDelete()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#FF0F00">Fully Deleted</TitleModal>
                    <TextModal>Please confirm deletion. This action can not be undone.</TextModal>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => onModalDelete()} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#FF0F00'} borderColor={'#FF0F00'} onClick={() => onAction('FULLY_DELETED', dataDeleted.name, dataDeleted.id)} width={'149px'} color={'#FFF'}>Delete</Button>
                    </div>
                </div>
            </Modal>
            <Modal show={showModalRecovery} onHide={onModalRecovery} dialogClassName="modal-600w" centered>
                <div style={{ height: 32, width: 32, float: 'right', cursor: 'pointer', marginRight: 16, marginTop: 16 }} onClick={() => onModalRecovery()}>
                    <img src={CloseModal} style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ marginTop: 40, paddingRight: 30, paddingLeft: 30 }}>
                    <TitleModal color="#28a745">Item Recovery</TitleModal>
                    <TextModal>Please confirm recovery.</TextModal>
                    <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
                        <Button backgroundColor="#ECECEC" borderColor="#ECECEC" onClick={() => onModalRecovery()} width={'149px'}>Cancel</Button>
                        <Button backgroundColor={'#28a745'} borderColor={'#28a745'} onClick={() => onAction('ACTIVE', dataDeleted.name, dataDeleted.id)} width={'149px'} color={'#FFF'}>Confirm</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

const CardMembership = styled.img`
width: 540px;
`;
const TextDetails = styled.td`
font-size: 14px;
padding-left: 30px !important;
font-family: 'Roboto';
text-transform: uppercase;
color: #1A1A1A;
`;
const Button = styled.button`
background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
border: 1px solid ${({borderColor}) => borderColor ? borderColor : '#1A1A1A'};
color: ${({color}) => color ? color : '#000'};
border-radius: 10px;
height: ${({height}) => height ? height : '40px'};
width: ${({width}) => width ? width : '100px'};
font-size: 14px;
line-height: 16px;
font-weight: ${({bold}) => bold ? bold : '400'};
font-family: 'Roboto';
margin-right: 5px;
`;
const SearchInput = styled.input`
border: 1px solid #BDBDBD;
color: #1A1A1A;
border-radius: 10px;
height: 40px;
width: 316px;
margin-left: 10px;
padding-left: 35px;
`;
const Title = styled.h1`
font-family: 'Montserrat';
font-weight: 700;
font-size: 28px;
line-height: 34px;
color: #000;
`;
const Table = styled.table`
border: none !important;
display: table;
margin-bottom: 1rem;
width: 100%;
margin-top: 18px;
margin-bottom: 18px;
`;
const TextAction = styled.p`
font-size: 14px;
font-family: 'Roboto';
text-decoration-line: underline;
cursor: pointer;
color: #1A1A1A;
align-items: center;
margin-bottom: 0;
margin-top: none !important;
line-height: none !important;
`;

const ContentBody = styled.tr`
background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#FFF'}; 
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
&:hover{
    background-color: rgba(255, 235, 17, 0.1)
}
`;

const Column = styled.td`
color: #1A1A1A;
padding: .8rem;
font-size: 14px;
line-height: 16px;
font-family: 'Roboto';
`;
const TextModal = styled.p`
font-family: 'Roboto';
color: #1A1A1A;
font-size: 16px;
text-align: center;
`;
const TitleDetails = styled.td`
font-size: 14px;
font-family: 'Roboto';
font-weight: 700;
text-transform: uppercase;
color: #1A1A1A;
`;
const TitleModal = styled.h1`
font-family: 'Montserrat';
color: ${({color}) => color ? color: '#1A1A1A'};
font-size: 24px;
line-height: 29px;
font-weight: 700;
text-align: center;
`;
const TextDetailsCard = styled.p`
font-size: 14px;
padding-left: 14px !important;
font-family: 'Roboto';
color: #1A1A1A;
margin-bottom: 0 !important;
`;
const Content = styled.div`
height: 29px;
width: 29px;
margin-left: 16px;
`;
const Img = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
object-position: center;
border-radius: 50%;
`;

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData:  state.auth.userData,
})

export default connect(mapStateToProps, null)(DeletedElements);