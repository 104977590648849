import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../assets/icon.png';
import { config } from '../../config';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/auth-action';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";

const ForgotPassword = (props) => {

    const params = useParams();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlerSubmit = async () => {
        if(password === confirmPassword){
            try{
                const response = await axios.post(`${config.URI}/reset-password/${params.id}`, { password }, { headers: { 'Accept': 'application/json', 'content-type': 'application/json' }});
                if(response.statusText === 'OK'){
                    toast.success((t) => (<span>Successfully saved.<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
                    setTimeout(() => {
                        window.location.href = '/auth/loginwithemail';
                    },3000)
                }
            }catch(e){
                console.log(e)
                toast.error((t) => (<span>{e.response.data.message}<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
            }
        }else{
            toast.error((t) => (<span>Your password don'ts mismatch<a style={{ cursor: 'pointer', marginLeft: 40 }} onClick={() => toast.dismiss(t.id)}>x</a></span>));
        }
    }

    return (
        <div className="limter">
            <div className="container-login">
                <div className="wrap-login">
                    <div className="login-form login-dark-back">
                        <div className="top-right-buttons d-flex">
                            <p className="text-top r-12">Have you lost your email?</p>
                            <Link to="/auth/login" className="btn-transparent-pill">
                                <p>Log In with Phone</p>
                            </Link>
                        </div>
                        <div className="container">
                            <h1 className="stebans-title text-white">Please enter your new password.</h1>
                            <p className="stebans-subtitle">Please use 8 characters at least, one uppercase letter and one number.</p>
                            <div className="form-group m-t-37">
                                <label htmlFor="phone" className="text-form text-white">Password</label>
                                <input type="password" className="form-control form-control-dark" autoComplete={false} name="email" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="form-group m-t-42">
                                <label htmlFor="phone" className="text-form text-white">Confirm Password</label>
                                <input type="password" name="password" autoComplete={false} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control form-control-dark" />
                            </div>
                            <div className="text-center">
                                <button className="btn-secondary-login m-t-40" onClick={() => handlerSubmit()}>
                                    <p>Set New Password</p>
                                </button>
                            </div>
                            <div className="text-center" style={{ marginTop: 13 }}>
                                <Link to="/auth/loginwithemail" className="text-resend text-link">Back to sign in</Link>
                            </div>
                        </div>
                    </div>
                    <div className="login-more">
                        <img src={Icon} className="logo" />
                    </div>
                </div>
            </div>
            <Toaster
                 position="top-center"
                 toastOptions={{
                    success: {
                        style: {
                            background: '#61d345',
                            color: '#FFF'
                        },
                    },
                    error: {
                        style: {
                            background: '#ff4b4b',
                            color: '#FFF'
                        },
                    },
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,
	isLoading: state.auth.isLoading,
	error: state.auth.error
})

const mapDispatchToProps = dispatch => ({
	loginSuccess: (data, token) => dispatch(actions.loginSuccess(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);